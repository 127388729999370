import { useReducer, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'

import linkPartsToCar from '../../../modules/redux/partList/LinkPartsToCar'
import { useDispatch } from '../../../modules/store/customDispatch'
import { partReducer } from '../../../pages/NewPart/partReducer'
import { Part } from '../../../types/part/partTypes'
import Loader from '../../_atoms/Loader/Loader'
import OverlayWithText from '../../_atoms/OverlayWithText/OverlayWithText'
import PartCreateUpdateTable from '../PartCreateUpdateTable/PartCreateUpdateTable'

import { initialPartState } from './initialPartState'
import styles from './styles.module.scss'

interface IProps {
    parts: Part[]
    isOpen: boolean
    closeModal: () => void
    confirmChanges: () => void
}

const ModalLinkingToCar = ({ parts, isOpen, closeModal, confirmChanges }: IProps) => {
    const dispatchRedux = useDispatch()
    const [state, dispatch] = useReducer(partReducer, initialPartState)
    const [isLoading, setIsLoading] = useState(false)

    const onCloseModal = () => {
        closeModal()
    }

    const confirmChange = async () => {
        setIsLoading(true)
        const partsId = parts.map(part => part.id)

        await dispatchRedux(linkPartsToCar(partsId, state.fields[0].value))
        setIsLoading(false)
        closeModal()
        confirmChanges()
    }

    return (
        <>
            <Modal show={isOpen} onHide={onCloseModal} centered dialogClassName={styles.modal}>
                {isLoading && (
                    <OverlayWithText backgroundBootstrapColor={'bg-secondary'}>
                        <Loader diameterInPx={100} thicknessInPx={10} />
                    </OverlayWithText>
                )}
                <Modal.Header closeButton>
                    <Modal.Title>
                        <b>Привязать к автомобилю</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <PartCreateUpdateTable dispatch={dispatch} fields={state.fields} />
                        <h4>
                            При привязке выбранных запчастей к новому автомобилю, информация о старом автомобиле
                            удалится
                        </h4>
                        <div className="d-flex justify-content-end">
                            <Button onClick={confirmChange} disabled={!state.fields[0].value}>
                                Сохранить
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalLinkingToCar
