import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { UploadImageToStoreApiArg, UploadImageToStoreApiResponse } from './imagesTypes'

export const BASE_URL = 'https://api.stock-pro.net/images'
// export const baseURL = 'http://localhost:3001/images'

export const imagesApi = createApi({
    reducerPath: 'imagesApi',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    endpoints: builder => ({
        uploadImageToStore: builder.mutation<UploadImageToStoreApiResponse, UploadImageToStoreApiArg>({
            query: ({ file, userId, imageType }) => {
                const formData = new FormData()
                formData.append('file', file)
                formData.append('userId', userId)
                formData.append('imageType', imageType)

                return {
                    url: '/uploadImageToStoreV2',
                    method: 'POST',
                    body: formData,
                }
            },
        }),
    }),
})

export const { useUploadImageToStoreMutation } = imagesApi
