import { useCallback, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { ReactSortable } from 'react-sortablejs'

import { allCustomizableColumns } from '../../../constants/defaultCustomizableColumns'
import { borderStyles, colors } from '../../../constants/salesStyles'
import { resetFilters } from '../../../hooks/partList/useResetFiltersOnUnmount'
import setUserActiveColumnsPartList from '../../../modules/redux/userSettings/SetUserActiveColumnsPartList'
import { useDispatch } from '../../../modules/store/customDispatch'
import { useSelector } from '../../../modules/store/customSelector'
import { Column } from '../../../modules/store/partList/partListReducer'
import Loader from '../../_atoms/Loader/Loader'
import OverlayWithText from '../../_atoms/OverlayWithText/OverlayWithText'
import SalesButton from '../../_atoms/SalesButton/SalesButton'

import styles from './styles.module.scss'

interface IProps {
    isOpen: boolean
    closeModal: () => void
}

const ModalCustomizableColumns = ({ isOpen, closeModal }: IProps) => {
    const dispatch = useDispatch()
    const customizableColumns = useSelector(state => state.partList.customizableColumns)
    const isLoading = useSelector(state => state.partList.isLoading.customizableColumns)
    const [activeButtons, setActiveButtons] = useState<Column[]>([])
    const [additionalButtons, setAdditionalButtons] = useState<Column[]>([])

    const onCloseModal = useCallback(() => {
        closeModal()
    }, [closeModal])

    const confirm = useCallback(async () => {
        await dispatch(setUserActiveColumnsPartList(activeButtons.map(column => column.id)))
        resetFilters(dispatch)
        onCloseModal()
    }, [activeButtons, dispatch, onCloseModal])

    useEffect(() => {
        setActiveButtons(customizableColumns)
        setAdditionalButtons(
            allCustomizableColumns.filter(
                column => !customizableColumns.some(defaultCol => defaultCol.id === column.id),
            ),
        )
    }, [customizableColumns])

    const getButton = (button: { id: string; label: string }, isActive: boolean) => (
        <div key={button.id}>
            <SalesButton
                className={styles.button}
                paddingHorizontalPixels={20}
                paddingVerticalPixels={5}
                marginBottomPixels={10}
                backgroundColor={colors.white}
                hoverBackgroundColor={colors.grayLighter}
                borderColor={isActive ? colors.greenLight : colors.grayBorder}
                borderWidth={1}
                borderStyle={borderStyles.solid}
                borderRadius={4}
            >
                <>{button.label}</>
            </SalesButton>
        </div>
    )

    return (
        <Modal show={isOpen} onHide={onCloseModal} centered dialogClassName={styles.modal}>
            {isLoading && (
                <OverlayWithText backgroundBootstrapColor={'bg-secondary'}>
                    <Loader diameterInPx={100} thicknessInPx={10} />
                </OverlayWithText>
            )}
            <Modal.Header closeButton>
                <Modal.Title>
                    <b>Настроить столбцы или изменить порядок</b>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={'row'}>
                    <div className={'col'}>
                        <h5>Активные</h5>
                    </div>
                    <div className={'col'}>
                        <h5>Дополнительные</h5>
                    </div>
                </div>
                <div className={styles.rowReactSortable}>
                    <ReactSortable group="shared" list={activeButtons} setList={setActiveButtons} animation={200}>
                        {activeButtons.map(button => getButton(button, true))}
                    </ReactSortable>
                    <ReactSortable
                        group="shared"
                        list={additionalButtons}
                        setList={setAdditionalButtons}
                        animation={200}
                    >
                        {additionalButtons.map(button => getButton(button, false))}
                    </ReactSortable>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={confirm}>
                    Сохранить
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalCustomizableColumns
