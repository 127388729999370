import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { colors } from '../../../constants/salesStyles'
import getHighlights from '../../../modules/redux/sales/getHighlights'
import { useDispatch } from '../../../modules/store/customDispatch'
import { useSelector } from '../../../modules/store/customSelector'
import { adminRouteAlias, getAdminNavigationPath } from '../../../router/adminRouteAlias'
import { getCurrencySymbol } from '../../../utils/getCurrencySymbol'
import HoverOverlay from '../../_atoms/HoverOverlay/HoverOverlay'
import Loader from '../../_atoms/Loader/Loader'
import SalesText from '../../_atoms/SalesText/SalesText'
import SalesButtonStandard from '../SalesButtonStandard/SalesButtonStandard'
import SalesCard from '../SalesCard/SalesCard'

import styles from './styles.module.scss'

const SalesCardHighlights = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isLoadingHighlights = useSelector(state => state.sales.loaders.highlights)
    const highlights = useSelector(state => state.sales.highlights)
    const currentCurrency = useSelector(state => state.partList.currentCurrency)
    const isLoadingCurrentCurrency = useSelector(state => state.partList.loaders.currentCurrency)

    const getHighlightsTotalCount = () => {
        const counts = highlights.map(h => h.totalCount)
        return Math.max(...counts) || undefined
    }

    const goToHighlight = (params?: string) => {
        navigate(getAdminNavigationPath(adminRouteAlias.parts.location) + (params && `?${params}`))
    }

    useEffect(() => {
        dispatch(getHighlights(false))
    }, [])

    return (
        <SalesCard
            widthInPixels={1180}
            // heightInPixels={168}
            innerTitle={'Требуют внимания'}
            innerTitleCount={isLoadingHighlights ? undefined : getHighlightsTotalCount()}
            marginBottomPixels={60}
        >
            {isLoadingHighlights || isLoadingCurrentCurrency ? (
                <Loader />
            ) : (
                <div className={styles.cardContentWrap}>
                    {highlights.map(item => (
                        <HoverOverlay
                            tooltipText={item.call2action}
                            key={item.alias}
                            placement={'bottom'}
                            preventWrapFullWidth
                        >
                            <div>
                                <SalesButtonStandard
                                    text={item.label}
                                    suffixElement={
                                        <SalesText
                                            text={String(item.count)}
                                            color={colors.purple}
                                            marginLeftPixels={10}
                                        />
                                    }
                                    marginTopPixels={18}
                                    onClick={() => goToHighlight(item.params)}
                                    addButtonText={`на ${item.price.toLocaleString('RU-ru')} ${getCurrencySymbol(currentCurrency)}`}
                                />
                            </div>
                        </HoverOverlay>
                    ))}
                </div>
            )}
        </SalesCard>
    )
}

export default SalesCardHighlights
