import { ReactComponent as ActionLog } from '../../../assets/icons/actionLog.svg'
import { ReactComponent as CarsIcon } from '../../../assets/icons/cars.svg'
import { ReactComponent as ClientsIcon } from '../../../assets/icons/customers.svg'
import { ReactComponent as DealsIcon } from '../../../assets/icons/deal.svg'
import { ReactComponent as FileLinesSolid } from '../../../assets/icons/fileLinesSolid.svg'
import { ReactComponent as PartsIcon } from '../../../assets/icons/part.svg'
import { ReactComponent as SalesIcon } from '../../../assets/icons/sale.svg'
import { ReactComponent as WarehouseIcon } from '../../../assets/icons/warehouse.svg'
import { useSelector } from '../../../modules/store/customSelector'
import { adminRouteAlias } from '../../../router/adminRouteAlias'
import SidebarNavLinks from '../../_atoms/SidebarNavLinks/SidebarNavLinks'

import styles from './styles.module.scss'

const navLinks = [
    {
        location: adminRouteAlias.parts.location,
        name: 'Запчасти',
        icon: PartsIcon,
        slice: 'partList',
    },
    {
        location: adminRouteAlias.deals.list,
        name: 'Сделки',
        icon: DealsIcon,
        slice: 'deal',
    },
    {
        location: adminRouteAlias.clients.location,
        name: 'Клиенты',
        icon: ClientsIcon,
        slice: 'client',
    },
    {
        location: adminRouteAlias.cars.location,
        name: 'Автомобили',
        icon: CarsIcon,
        slice: 'carList',
    },
    {
        location: adminRouteAlias.sales.location,
        name: 'Продажи',
        icon: SalesIcon,
        slice: 'sales',
    },
    {
        location: adminRouteAlias.warehouses.location,
        name: 'Cклады',
        icon: WarehouseIcon,
        slice: 'warehousesList',
    },
    {
        location: adminRouteAlias.documents.location,
        name: 'Документы',
        icon: FileLinesSolid,
        slice: 'documentsList',
    },
    {
        location: adminRouteAlias.actionLog.location,
        name: 'Журнал действий',
        icon: ActionLog,
        slice: 'actionLog',
    },
]

const Sidebar = () => {
    const canShowFinanceInfo = useSelector(state => state.userData.perms?.canShowFinanceInfo)

    const filteredNavLinks = canShowFinanceInfo ? navLinks : navLinks.filter(link => link.location !== 'documents')

    return (
        <div className={styles.wrap}>
            <SidebarNavLinks navLinks={filteredNavLinks} />
        </div>
    )
}

export default Sidebar
