import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import TagManager from 'react-gtm-module'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import ScrollToTop from './components/_hocs/ScrollToTop'
import SmsAuthHoc from './components/Auth/SmsAuthHoc'
import { store } from './modules/store/store'
import AppRoutes from './router/AppRoutes'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'react-toastify/dist/ReactToastify.css'

const tagManagerArgs = {
    gtmId: 'GTM-PG9R5ZLM',
}
TagManager.initialize(tagManagerArgs)

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <SmsAuthHoc>
                <BrowserRouter>
                    <ScrollToTop />
                    <AppRoutes />
                </BrowserRouter>
            </SmsAuthHoc>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
