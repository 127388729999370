import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { RootState } from '../rootReducer'

import { PrintApiArg, PrintApiResponse, StatusApiResponse } from './atolTypes'

export const atolApi = createApi({
    reducerPath: 'atolApi',
    baseQuery: async (args, api, extraOptions) => {
        const state = api.getState() as RootState
        const hostPort = state.userData.cashierInfo?.cashier?.hostPort
        const baseQuery = fetchBaseQuery({ baseUrl: hostPort + '/api/v1/atol' })
        return baseQuery(args, api, extraOptions)
    },
    endpoints: builder => ({
        printFiscalReceipt: builder.mutation<PrintApiResponse, PrintApiArg>({
            query: queryArg => ({
                url: `/print`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        status: builder.query<StatusApiResponse, void>({
            query: () => ({
                url: `/status`,
                method: 'GET',
            }),
        }),
    }),
})

export const { usePrintFiscalReceiptMutation, useLazyStatusQuery } = atolApi
