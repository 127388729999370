import React from 'react'

import { useGetSmsUserId } from '../../../hooks/user/useAuth'
import { adminRouteAlias } from '../../../router/adminRouteAlias'
import LogoWithText from '../../_atoms/LogoWithText/LogoWithText'
import HeaderLinks from '../../_molecules/HeaderLinks/HeaderLinks'
import HeaderProfile from '../../_molecules/HeaderProfile/HeaderProfile'

import styles from './styles.module.scss'

const signInLinks = [
    { text: 'Начало работы', href: adminRouteAlias.onboarding.location },
    { text: 'Запчасти', href: 'https://partsauto.market', target: '_blank' },
    { text: 'О проекте', href: 'https://partsauto.expert', target: '_blank' },
    { text: 'Поддержка', href: 'https://partsauto.expert/faq-support', target: '_blank' },
]

const Header = () => {
    const userId = useGetSmsUserId()

    return (
        <div className={styles.wrap}>
            <div className={styles.wrap__container}>
                <div className={styles.logoAndLinks}>
                    <LogoWithText />
                    <HeaderLinks links={signInLinks} />
                </div>
                {userId ? <HeaderProfile /> : null}
            </div>
        </div>
    )
}

export default Header
