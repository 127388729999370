import { carFields, carState } from '../../../types/car/carTypes'
import { fieldTypes, valueTypes } from '../../../types/common/commonTypes'

export const initialCarState: carState = {
    fields: [
        {
            field: carFields.markId,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.number,
            value: 0,
            title: 'Марка',
            specialProps: {
                isRequired: true,
                placeholder: 'выберите марку',
                column: 1,
                renderValue: '',
            },
        },
        {
            field: carFields.modelId,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.number,
            value: 0,
            title: 'Модель',
            specialProps: {
                isRequired: true,
                placeholder: 'выберите модель',
                fieldParent: carFields.markId,
                column: 1,
            },
        },
        {
            field: carFields.modificationId,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.number,
            value: 0,
            title: 'Модификация',
            specialProps: {
                isRequired: true,
                placeholder: 'выберите модификацию',
                fieldParent: carFields.modelId,
                column: 1,
            },
        },
        {
            field: carFields.bodyTypeId,
            fieldType: fieldTypes.readOnly,
            valueType: valueTypes.number,
            title: 'Тип кузова',
            specialProps: {
                placeholder: 'определяется автоматически',
                fieldParent: carFields.modificationId,
                column: 1,
            },
        },
        {
            field: carFields.year,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.number,
            value: 0,
            title: 'Год',
            specialProps: {
                isRequired: true,
                placeholder: 'выберите год',
                fieldParent: carFields.modificationId,
                column: 1,
            },
        },
    ],
}
