import React from 'react'
import { XCircleFill } from 'react-bootstrap-icons'

import {
    SortFieldActionLogs,
    SortFieldCars,
    SortFieldParts,
    SortIconColor,
    SortIconType,
    SortOrder,
} from '../../../types/common/commonTypes'
import SortIcon from '../../_atoms/SortIcon/SortIcon'

import styles from './styles.module.scss'

interface ColumnSortRTKProps {
    iconType: SortIconType
    sortField: SortFieldActionLogs | SortFieldCars | SortFieldParts
    reverseSort?: boolean
    isResetButton?: boolean
    currentSortOrder: SortOrder
    onSortChange: (sortField: string, newSortOrder: SortOrder) => void
}

const sortOrderNext = (sortOrderCurrent: SortOrder): SortOrder => {
    switch (sortOrderCurrent) {
        case SortOrder.NONE:
            return SortOrder.DESC
        case SortOrder.DESC:
            return SortOrder.ASC
        case SortOrder.ASC:
            return SortOrder.NONE
        default:
            return SortOrder.NONE
    }
}

const iconColor = (sortOrderCurrent: SortOrder): SortIconColor =>
    sortOrderCurrent === SortOrder.NONE ? SortIconColor.INACTIVE : SortIconColor.ACTIVE

const ColumnSortRTK: React.FC<ColumnSortRTKProps> = ({
    iconType,
    sortField,
    reverseSort,
    isResetButton = true,
    currentSortOrder,
    onSortChange,
}) => {
    const newSortOrder = sortOrderNext(currentSortOrder)

    const changeSort = () => {
        onSortChange(sortField, newSortOrder)
    }

    const resetSort = () => {
        onSortChange(sortField, SortOrder.NONE)
    }

    return (
        <span className={styles.wrap}>
            <span onClick={changeSort} className={styles.sortIcon}>
                <SortIcon
                    sortOrder={currentSortOrder}
                    iconType={iconType}
                    color={iconColor(currentSortOrder)}
                    size={18}
                    reverseSort={reverseSort}
                />
            </span>
            {currentSortOrder !== SortOrder.NONE && isResetButton && (
                <>
                    <span className={styles.clearIcon} onClick={resetSort}>
                        <XCircleFill color="grey" size={14} />
                    </span>
                    {/* Можно добавить отображение индекса сортировки, если нужно */}
                </>
            )}
        </span>
    )
}

export default ColumnSortRTK
