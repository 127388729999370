import React from 'react'

import AppStore from '../../../assets/img/app-store.png'
import GooglePlay from '../../../assets/img/google-play.png'
import { appUrl } from '../../../constants/appUrl'
import { colors, fontSizes, fontWeights } from '../../../constants/salesStyles'
import CustomLink from '../CustomLink/CustomLink'
import SalesText from '../SalesText/SalesText'

import styles from './styles.module.scss'

const Footer = () => (
    <div className={styles.wrap}>
        <div className={styles.content}>
            <div className={styles.leftContainer}>
                <div className={styles.leftSubContainer}>
                    <div className={styles.textWrap}>
                        <SalesText
                            text={'Parts Auto Expert'}
                            fontWeight={fontWeights.semiBold}
                            // marginRightPixels={40}
                        />
                    </div>
                    <CustomLink text={'О компании'} href={'https://partsauto.expert'} target={'_blank'} />

                    <CustomLink
                        text={'Контактная информация'}
                        href={'https://partsauto.expert/#kontacty'}
                        target={'_blank'}
                    />
                </div>
                <div className={styles.leftSubContainer}>
                    <SalesText text={'Все права защищены'} fontSize={fontSizes.s} color={colors.grayLight} />
                </div>
            </div>
            <div className={styles.rightContainer}>
                <div className={styles.rightSubContainer}>
                    <a href={appUrl.googlePlay} target="_blank" className={styles.storeWrap}>
                        <img src={GooglePlay} alt="google play icon" className={styles.store} />
                    </a>
                    <a href={appUrl.appStore} target="_blank" className={styles.storeWrap}>
                        <img src={AppStore} alt="appstore icon" className={styles.store} />
                    </a>
                </div>
                {/*<div className={styles.rightSubContainer}>*/}
                {/*    <img src={Mir} alt="mir icon" className={styles.paySystem} style={{ height: '17px' }} />*/}
                {/*    <img*/}
                {/*        src={MasterCard}*/}
                {/*        alt="mastercard icon"*/}
                {/*        className={styles.paySystem}*/}
                {/*        style={{ height: '22px' }}*/}
                {/*    />*/}
                {/*    <img src={Sbp} alt="spb icon" className={styles.paySystem} style={{ height: '27px' }} />*/}
                {/*</div>*/}
            </div>
        </div>
    </div>
)

export default Footer
