import { useCallback, useState } from 'react'
import { Button, Modal, Table } from 'react-bootstrap'
import { toast } from 'react-toastify'

import printFiscalReceipt, { TAX_RATES } from '../../../modules/redux/documentList/PrintFiscalReceipt'
import { useDispatch } from '../../../modules/store/customDispatch'
import { useSelector } from '../../../modules/store/customSelector'
import { Cashier } from '../../../modules/store/user/userReducer'
import { Document } from '../../../types/document/documentTypes'
import { Part } from '../../../types/part/partTypes'
import Loader from '../../_atoms/Loader/Loader'
import OverlayWithText from '../../_atoms/OverlayWithText/OverlayWithText'
import { ShiftStatus } from '../../_orgamisms/CashierStatus/CashierStatus'

import styles from './styles.module.scss'

interface IProps {
    isOpen: boolean
    closeModal: () => void
    parts: Part[]
    cashier: Cashier
    document: Document
}

enum ETax {
    VAT0 = '0%',
    VAT10 = '10%',
    VAT20 = '20%',
}

const ModalReceiptPreview = ({ isOpen, closeModal, parts, cashier, document }: IProps) => {
    const dispatch = useDispatch()
    const [isLoadingReceipt, setIsLoadingReceipt] = useState(false)
    const shiftStatus = useSelector(state => state.documentList.shiftStatus)

    const onCloseModal = useCallback(() => closeModal(), [closeModal])

    const totalAmount = parts.reduce((sum, part) => sum + (part.price || 0), 0)
    const taxRate = TAX_RATES[cashier.tax] ?? 0
    const taxAmount = totalAmount * taxRate

    const handleIssueReceipt = useCallback(async () => {
        setIsLoadingReceipt(true)
        try {
            const response = await dispatch(printFiscalReceipt(document, cashier))
            response && toast.info(response)
        } catch {
            toast.error(`Ошибка печатания чека`)
        }
        setIsLoadingReceipt(false)
        onCloseModal()
    }, [cashier, dispatch, document, onCloseModal])

    return (
        <Modal show={isOpen} onHide={onCloseModal} centered dialogClassName={styles.modal}>
            {isLoadingReceipt && (
                <OverlayWithText backgroundBootstrapColor={'bg-secondary'}>
                    <Loader diameterInPx={100} thicknessInPx={10} />
                </OverlayWithText>
            )}
            <Modal.Header closeButton>
                <Modal.Title>
                    <b>Предварительный просмотр чека</b>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <h5>Покупатель: {document.extra_data.buyerName}</h5>
                </div>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Название</th>
                            <th>Цена</th>
                        </tr>
                    </thead>
                    <tbody>
                        {parts.map((part, index) => (
                            <tr key={part.id}>
                                <td>{index + 1}</td>
                                <td>{part.customTitle || part.title}</td>
                                <td>{(part.price || 0).toFixed(2)} ₽</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <div>
                    <p>
                        <b>Итог:</b> {totalAmount.toFixed(2)} ₽
                    </p>
                    <p>
                        <b>Сумма НДС {ETax[cashier?.tax as keyof typeof ETax]}:</b> {taxAmount.toFixed(2)} ₽
                    </p>
                </div>
                {cashier && (
                    <div>
                        <p>
                            <b>Кассир:</b> {cashier.familyName} {cashier.name} {cashier.surname}
                        </p>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <div className={styles.buttonGroup}>
                    <Button
                        variant="success"
                        onClick={handleIssueReceipt}
                        disabled={shiftStatus !== ShiftStatus.opened}
                    >
                        Выбить чек
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalReceiptPreview
