import React, { Dispatch, useCallback, useMemo, useState } from 'react'
import { Accordion, AccordionButton } from 'react-bootstrap'
import { PlusLg, Printer, XLg } from 'react-bootstrap-icons'

import { AnyAction } from 'redux'

import { partConditionOptions, wheelsConditionOptions } from '../../../constants/conditions'
import mileage from '../../../constants/mileage'
import regions from '../../../constants/regions'
import rimTypes from '../../../constants/rimTypes'
import { borderStyles, colors, fontSizes, fontWeights } from '../../../constants/salesStyles'
import tyreTypes from '../../../constants/tyreTypes'
import getCarsSelectSearchCarTitle from '../../../modules/redux/carList/getCarsSelectSearchCarTitle'
import getAllAmplifierBrands from '../../../modules/redux/catalogs/getAllAmplifierBrands'
import getAllAudioBrands from '../../../modules/redux/catalogs/getAllAudioBrands'
import getAllAudioSizes from '../../../modules/redux/catalogs/getAllAudioSizes'
import getAllBrakeDOT from '../../../modules/redux/catalogs/getAllBrakeDOT'
import getAllCarMarks from '../../../modules/redux/catalogs/getAllCarMarks'
import getAllCarModels from '../../../modules/redux/catalogs/getAllCarModels'
import getAllCarModifications from '../../../modules/redux/catalogs/getAllCarModifications'
import getAllCoolantColors from '../../../modules/redux/catalogs/getAllCoolantColors'
import getAllOilsACEA from '../../../modules/redux/catalogs/getAllOilsACEA'
import getAllOilsAPI from '../../../modules/redux/catalogs/getAllOilsAPI'
import getAllOilsASTM from '../../../modules/redux/catalogs/getAllOilsASTM'
import getAllOilsATF from '../../../modules/redux/catalogs/getAllOilsATF'
import getAllOilsBrands from '../../../modules/redux/catalogs/getAllOilsBrands'
import getAllOilsOEMOil from '../../../modules/redux/catalogs/getAllOilsOEMOil'
import getAllOilsSAE from '../../../modules/redux/catalogs/getAllOilsSAE'
import getAllOilsVolumes from '../../../modules/redux/catalogs/getAllOilsVolumes'
import getAllPartBrands from '../../../modules/redux/catalogs/getAllPartBrands'
import getAllPartTypes from '../../../modules/redux/catalogs/getAllPartTypes'
import getAllRadioBrands from '../../../modules/redux/catalogs/getAllRadioBrands'
import getAllTiresBrands from '../../../modules/redux/catalogs/getAllTiresBrands'
import getAllTiresModels from '../../../modules/redux/catalogs/getAllTiresModels'
import getModificationYears from '../../../modules/redux/catalogs/getModificationYears'
import searchPartListPartNumbers from '../../../modules/redux/partList/SearchPartListPartNumbers'
import SearchPartListPartNumbersWithDescription from '../../../modules/redux/partList/SearchPartListPartNumbersWithDescription'
import warehousesSelectSearch from '../../../modules/redux/warehouseList/WarehousesSelectSearch'
import { useSelector } from '../../../modules/store/customSelector'
import { carFields } from '../../../types/car/carTypes'
import { fieldTypes, SelectOptionLocal, valueTypes } from '../../../types/common/commonTypes'
import {
    MarketplacesKeys,
    partCreateUpdateActionTypes,
    partFields,
    partInput,
    partSpecialPropsFields,
} from '../../../types/part/partTypes'
import CopyButton from '../../_atoms/CopyButton/CopyButton'
import CustomLink from '../../_atoms/CustomLink/CustomLink'
import SalesButton from '../../_atoms/SalesButton/SalesButton'
import SalesCheckboxMarketplace from '../../_atoms/SalesCheckboxMarketplace/SalesCheckboxMarketplace'
import SalesInput from '../../_atoms/SalesInput/SalesInput'
import SalesRow from '../../_atoms/SalesRow/SalesRow'
import SalesTableData from '../../_atoms/SalesTableData/SalesTableData'
import SalesText from '../../_atoms/SalesText/SalesText'
import SalesTextArea from '../../_atoms/SalesTextArea/SalesTextArea'
import StarRating from '../../_atoms/StarRating/StarRating'
import PartSwitch from '../PartSwitch/PartSwitch'
import SalesDescriptionInput from '../SalesDescriptionInput'
import SalesSelect from '../SalesSelect/SalesSelect'
import SalesSelectSearch from '../SalesSelectSearch/SalesSelectSearch'
import SalesTableDataInfo from '../SalesTableDataInfo/SalesTableDataInfo'
import SalesUploadImages from '../SalesUploadImages/SalesUploadImages'
import SingleAccordion from '../SingleAccordion/SingleAccordion'

import styles from './styles.module.scss'

interface IProps {
    input: partInput
    dispatch: Dispatch<AnyAction>
    isLast?: boolean
}

const SalesCardRowPart = ({ input, dispatch, isLast }: IProps) => {
    const { title, field, value, fieldType, specialProps, valueType } = input
    const [isEditableLocal, setIsEditableLocal] = useState(true)
    const cars = useSelector(state => state.carList.cars)
    const marks = useSelector(state => state.carList.marks)
    const models = useSelector(state => state.carList.models)
    const modifications = useSelector(state => state.carList.modifications)
    const modificationYears = useSelector(state => state.carList.modificationYears)
    const partTypes = useSelector(state => state.partList.partTypes)
    const tiresBrands = useSelector(state => state.partList.tiresBrands)
    const partBrands = useSelector(state => state.partList.partBrands)
    const warehousesSS = useSelector(state => state.warehouseList.warehousesSS)
    const tiresModels = useSelector(state => state.partList.tiresModels)
    const partNumbers = useSelector(state => state.partList.partNumbers)
    const partNumbersWithDescription = useSelector(state => state.partList.partNumbersWithDescription)
    const oilsBrands = useSelector(state => state.partList.oilsBrands)
    const oilsVolumes = useSelector(state => state.partList.oilsVolumes)
    const oilsSAE = useSelector(state => state.partList.oilsSAE)
    const oilsACEA = useSelector(state => state.partList.oilsACEA)
    const oilsAPI = useSelector(state => state.partList.oilsAPI)
    const oilsOEMOil = useSelector(state => state.partList.oilsOEMOil)
    const oilsATF = useSelector(state => state.partList.oilsATF)
    const oilsASTM = useSelector(state => state.partList.oilsASTM)
    const coolantColors = useSelector(state => state.partList.coolantColors)
    const brakeDOT = useSelector(state => state.partList.brakeDOT)
    const documents = useSelector(state => state.documentList.documents)
    const radioBrands = useSelector(state => state.partList.radioBrands)
    const amplifierBrands = useSelector(state => state.partList.amplifierBrands)
    const audioBrands = useSelector(state => state.partList.audioBrands)
    const audioSizes = useSelector(state => state.partList.audioSizes)
    const loaders = {
        cars: useSelector(state => state.carList.loaders.cars),
        marks: useSelector(state => state.carList.loaders.marks),
        models: useSelector(state => state.carList.loaders.models),
        modifications: useSelector(state => state.carList.loaders.modifications),
        modificationYears: useSelector(state => state.carList.loaders.modificationYears),
        partTypes: useSelector(state => state.partList.loaders.partTypes),
        tiresBrands: useSelector(state => state.partList.loaders.tiresBrands),
        partBrands: useSelector(state => state.partList.loaders.partBrands),
        warehouseSS: useSelector(state => state.warehouseList.loaders.warehouseSS),
        tiresModels: useSelector(state => state.partList.loaders.tiresModels),
        partNumbers: useSelector(state => state.partList.loaders.partNumbers),
        oilsBrands: useSelector(state => state.partList.loaders.oilsBrands),
        oilsVolumes: useSelector(state => state.partList.loaders.oilsVolumes),
        oilsSAE: useSelector(state => state.partList.loaders.oilsSAE),
        oilsACEA: useSelector(state => state.partList.loaders.oilsACEA),
        oilsAPI: useSelector(state => state.partList.loaders.oilsAPI),
        oilsOEMOil: useSelector(state => state.partList.loaders.oilsOEMOil),
        oilsATF: useSelector(state => state.partList.loaders.oilsATF),
        oilsASTM: useSelector(state => state.partList.loaders.oilsASTM),
        coolantColors: useSelector(state => state.partList.loaders.coolantColors),
        brakeDOT: useSelector(state => state.partList.loaders.brakeDOT),
        documents: useSelector(state => state.documentList.loaders.documents),
        radioBrands: useSelector(state => state.partList.loaders.radioBrands),
        amplifierBrands: useSelector(state => state.partList.loaders.amplifierBrands),
        audioBrands: useSelector(state => state.partList.loaders.audioBrands),
        audioSizes: useSelector(state => state.partList.loaders.audioSizes),
    }

    const getIsRequiredDot = () => {
        let isRequiredDot = false

        if (specialProps?.isRequired && field !== partFields.carId) {
            isRequiredDot = true
        } else if (field === partFields.useExistingCar) {
            isRequiredDot = true
        }

        return isRequiredDot
    }

    const onChangeSwitch = (newValue: number) => {
        dispatch({
            type: partCreateUpdateActionTypes.updateManyFields,
            value: [
                {
                    field,
                    value: newValue,
                },
                {
                    field: partFields.customTitle,
                    specialPropsField: partSpecialPropsFields.isTouched,
                    specialPropsValue: false,
                },
            ],
        })
    }

    const onCheckboxChange = (fieldName: string, newValue: boolean) => {
        dispatch({
            type: partCreateUpdateActionTypes.updateField,
            value: {
                field,
                // @ts-ignore
                value: value.map((item: { field: string; value: boolean }) => {
                    if (item.field === fieldName) {
                        return {
                            ...item,
                            value: newValue,
                        }
                    } else {
                        return item
                    }
                }),
            },
        })
    }

    const onChangeInput = (newValue: string) => {
        const isClearError = Boolean(newValue)
        if (isClearError) {
            dispatch({
                type: partCreateUpdateActionTypes.updateField,
                value: {
                    field,
                    value: newValue,
                    specialProps: {
                        ...specialProps,
                        [partSpecialPropsFields.errorMessage]: '',
                    },
                },
            })
        } else {
            dispatch({
                type: partCreateUpdateActionTypes.updateField,
                value: {
                    field,
                    value: newValue,
                    specialProps: {
                        ...specialProps,
                        [partSpecialPropsFields.errorMessage]: specialProps?.errorMessage,
                    },
                },
            })
        }
    }

    const onChangeSelectSearch = (newValue: string | number | boolean, newName: string, option: SelectOptionLocal) => {
        const isClearError = Boolean(newValue)
        if (isClearError) {
            if (
                field === carFields.modelId ||
                field === carFields.markId ||
                field === carFields.applicabilityModelId ||
                field === carFields.applicabilityMarkId
            ) {
                dispatch({
                    type: partCreateUpdateActionTypes.updateManyFields,
                    value: [
                        {
                            field,
                            value: newValue,
                            specialPropsField: partSpecialPropsFields.errorMessage,
                            specialPropsValue: isClearError ? '' : specialProps?.errorMessage,
                        },

                        {
                            field,
                            value: newValue,
                            specialPropsField: partSpecialPropsFields.renderValue,
                            specialPropsValue: isClearError ? newName : '',
                        },
                        {
                            field: partFields.customTitle,
                            specialPropsField: partSpecialPropsFields.isTouched,
                            specialPropsValue: false,
                        },
                    ],
                })
            } else if (field === partFields.partNumber) {
                dispatch({
                    type: partCreateUpdateActionTypes.updateField,
                    value: {
                        field,
                        value: newName,
                        specialProps: {
                            ...specialProps,
                            [partSpecialPropsFields.errorMessage]: '',
                            [partSpecialPropsFields.renderValue]: newName,
                        },
                    },
                })
                dispatch({
                    type: partCreateUpdateActionTypes.updateManyFields,
                    value: [
                        {
                            field: partFields.description,
                            value: option.partData?.description,
                        },
                        {
                            field: partFields.typeId,
                            value: option.partData?.typeId,
                            specialPropsField: partSpecialPropsFields.renderValue,
                            specialPropsValue: option.partData?.title,
                        },
                        {
                            field: partFields.customTitle,
                            value: option.partData?.customTitle,
                        },
                    ],
                })
            } else {
                dispatch({
                    type: partCreateUpdateActionTypes.updateField,
                    value: {
                        field,
                        value: newValue,
                        specialProps: {
                            ...specialProps,
                            [partSpecialPropsFields.errorMessage]: '',
                            [partSpecialPropsFields.renderValue]: newName,
                        },
                    },
                })
            }
        } else {
            dispatch({
                type: partCreateUpdateActionTypes.updateField,
                value: {
                    field,
                    value: newValue,
                    specialProps: {
                        ...specialProps,
                        [partSpecialPropsFields.errorMessage]: specialProps?.errorMessage,
                        [partSpecialPropsFields.renderValue]: '',
                    },
                },
            })
        }
    }

    const onChangeExistingCar = (newValue: string | number | boolean, newName: string) => {
        const isClearError = Boolean(newValue)
        dispatch({
            type: partCreateUpdateActionTypes.updateManyFields,
            value: [
                {
                    field,
                    value: newValue,
                    specialPropsField: partSpecialPropsFields.errorMessage,
                    specialPropsValue: isClearError ? '' : specialProps?.errorMessage,
                },

                {
                    field,
                    value: newValue,
                    specialPropsField: partSpecialPropsFields.renderValue,
                    specialPropsValue: isClearError ? newName : '',
                },
                {
                    field: partFields.customTitle,
                    specialPropsField: partSpecialPropsFields.isTouched,
                    specialPropsValue: false,
                },
            ],
        })
    }

    const onChangePartType = (
        partTypeId: string | number | boolean,
        partName: string,
        groupId?: string | number | boolean,
        groupName?: string,
    ) => {
        const isValue = Boolean(partTypeId)

        if (isValue) {
            dispatch({
                type: partCreateUpdateActionTypes.updateManyFields,
                value: [
                    {
                        field,
                        value: partTypeId,
                        specialPropsField: partSpecialPropsFields.renderValue,
                        specialPropsValue: partName,
                    },
                    {
                        field,
                        specialPropsField: partSpecialPropsFields.errorMessage,
                        specialPropsValue: '',
                    },
                    {
                        field: partFields.groupId,
                        value: groupId,
                        specialPropsField: partSpecialPropsFields.renderValue,
                        specialPropsValue: groupName,
                    },
                    {
                        field: partFields.customTitle,
                        specialPropsField: partSpecialPropsFields.isTouched,
                        specialPropsValue: false,
                    },
                ],
            })
        } else {
            dispatch({
                type: partCreateUpdateActionTypes.updateManyFields,
                value: [
                    {
                        field,
                        value: '',
                        specialPropsField: partSpecialPropsFields.renderValue,
                        specialPropsValue: '',
                    },
                    {
                        field,
                        specialPropsField: partSpecialPropsFields.errorMessage,
                        specialPropsValue: 'поле обязательно к заполнению',
                    },
                    {
                        field: partFields.groupId,
                        value: '',
                        specialPropsField: partSpecialPropsFields.renderValue,
                        specialPropsValue: '',
                    },
                    {
                        field: partFields.position,
                        value: '',
                        specialPropsField: partSpecialPropsFields.renderValue,
                        specialPropsValue: '',
                    },
                    // Чтобы 'Название публикации' не стиралось при изменении 'Название'
                    // {
                    //     field: partFields.customTitle,
                    //     value: '',
                    //     specialPropsField: partSpecialPropsFields.isTouched,
                    //     specialPropsValue: false
                    // },
                ],
            })
        }
    }

    const onChangeTextArea = (newValue: string) => {
        const maxLength = specialProps?.textareaMaxLength
        dispatch({
            type: partCreateUpdateActionTypes.updateField,
            value: {
                field,
                value: newValue,
                specialProps: {
                    ...specialProps,
                    [partSpecialPropsFields.errorMessage]:
                        maxLength && newValue.length > maxLength ? 'превышена длина названия публикации' : '',
                    [partSpecialPropsFields.isTouched]: true,
                },
            },
        })
    }

    const onChangeRenderValue = (newValue: string, willChangeValue?: boolean) => {
        if (willChangeValue) onChangeInput(newValue)

        const isClearError = Boolean(newValue)
        if (isClearError) {
            dispatch({
                type: partCreateUpdateActionTypes.updateSpecialProps,
                value: {
                    field,
                    specialProps: {
                        ...specialProps,
                        [partSpecialPropsFields.renderValue]: newValue,
                        [partSpecialPropsFields.errorMessage]: '',
                    },
                },
            })
        } else {
            dispatch({
                type: partCreateUpdateActionTypes.updateField,
                value: {
                    field,
                    value: '',
                    specialProps: {
                        ...specialProps,
                        [partSpecialPropsFields.renderValue]: newValue,
                        [partSpecialPropsFields.errorMessage]: specialProps?.errorMessage,
                    },
                },
            })
        }
    }

    const validateSelectSearchRenderValue = (
        textValue: string,
        suggestions: SelectOptionLocal[],
        errorMessage: string,
    ) => {
        if (suggestions.findIndex(suggestion => suggestion.name === textValue) === -1 && textValue) {
            dispatch({
                type: partCreateUpdateActionTypes.updateSpecialProps,
                value: {
                    field,
                    specialProps: {
                        ...specialProps,
                        [partSpecialPropsFields.errorMessage]: errorMessage,
                    },
                },
            })

            dispatch({
                type: partCreateUpdateActionTypes.updateField,
                value: { field, value: '' },
            })
        } else {
            dispatch({
                type: partCreateUpdateActionTypes.updateSpecialProps,
                value: {
                    field,
                    specialProps: {
                        ...specialProps,
                        [partSpecialPropsFields.errorMessage]: '',
                    },
                },
            })
        }
    }

    const onImagesAdd = (files: File[]) => {
        dispatch({
            type: partCreateUpdateActionTypes.addFiles,
            value: { field, files },
        })
    }

    const onImageRemove = (fileIndex: number) => {
        dispatch({
            type: partCreateUpdateActionTypes.removeFile,
            value: { field, fileIndex },
        })
    }

    const onImagesReorder = (files: File[]) => {
        dispatch({
            type: partCreateUpdateActionTypes.reorderFiles,
            value: { field, files },
        })
    }

    const onChangeSelect = (newValue: string | number | boolean, newName: string) => {
        const isClearError = Boolean(newValue)
        dispatch({
            type: partCreateUpdateActionTypes.updateField,
            value: {
                field,
                value: newValue,
                specialProps: {
                    ...specialProps,
                    [partSpecialPropsFields.errorMessage]: isClearError ? '' : specialProps?.errorMessage,
                    [partSpecialPropsFields.renderValue]: newName,
                },
            },
        })
    }

    const getSuggestionsGetter = () => {
        if (specialProps?.options) {
            return
        }

        switch (true) {
            case field === carFields.markId || field === carFields.applicabilityMarkId:
                return getAllCarMarks
            case field === carFields.carId:
                return getCarsSelectSearchCarTitle
            case field === carFields.modelId || field === carFields.applicabilityModelId:
                return getAllCarModels
            case field === carFields.modificationId || field === carFields.applicabilityModificationId:
                return getAllCarModifications
            case field === carFields.year || field === carFields.applicabilityYear:
                return getModificationYears
            case field === partFields.typeId:
                return getAllPartTypes
            case field === partFields.Brand:
                return getAllTiresBrands
            case field === partFields.TireBrand:
                return getAllTiresBrands
            case field === partFields.PartBrand:
                return getAllPartBrands
            case field === partFields.TireModel:
                return getAllTiresModels
            case field === partFields.warehouseTitle:
                return warehousesSelectSearch
            case specialProps?.addedField === partFields.partNumber:
                return searchPartListPartNumbers
            case field === partFields.partNumber:
                return SearchPartListPartNumbersWithDescription
            case field === partFields.OilBrand:
                return getAllOilsBrands
            case field === partFields.Volume:
                return getAllOilsVolumes
            case field === partFields.SAE:
                return getAllOilsSAE
            case field === partFields.ACEA:
                return getAllOilsACEA
            case field === partFields.API:
                return getAllOilsAPI
            case field === partFields.OEMOil:
                return getAllOilsOEMOil
            case field === partFields.ATF:
                return getAllOilsATF
            case field === partFields.ASTM:
                return getAllOilsASTM
            case field === partFields.Color:
                return getAllCoolantColors
            case field === partFields.DOT:
                return getAllBrakeDOT
            case field === partFields.RadioBrand:
                return getAllRadioBrands
            case field === partFields.AmplifierBrand:
                return getAllAmplifierBrands
            case field === partFields.AudioBrand:
                return getAllAudioBrands
            case field === partFields.AudioSize:
                return getAllAudioSizes
            default:
                return getCarsSelectSearchCarTitle
        }
    }

    const suggestionsList = useMemo((): SelectOptionLocal[] => {
        if (specialProps?.options) {
            return specialProps.options.map(option => ({ value: option, name: option }))
        }

        switch (true) {
            case field === carFields.carId:
                return cars
            case field === carFields.markId || field === carFields.applicabilityMarkId:
                return marks
            case field === carFields.modelId || field === carFields.applicabilityModelId:
                return models
            case field === carFields.modificationId || field === carFields.applicabilityModificationId:
                return modifications
            case field === carFields.year || field === carFields.applicabilityYear:
                return modificationYears
            case field === partFields.typeId:
                return partTypes
            case field === partFields.Brand:
                return tiresBrands
            case field === partFields.TireBrand:
                return tiresBrands
            case field === partFields.PartBrand:
                return partBrands
            case field === partFields.warehouseTitle:
                return warehousesSS
            case field === partFields.TireModel:
                return tiresModels
            case specialProps?.addedField === partFields.partNumber:
                return partNumbers
            case field === partFields.partNumber:
                return partNumbersWithDescription
            case field === partFields.OilBrand:
                return oilsBrands
            case field === partFields.Volume:
                return oilsVolumes
            case field === partFields.SAE:
                return oilsSAE
            case field === partFields.ACEA:
                return oilsACEA
            case field === partFields.API:
                return oilsAPI
            case field === partFields.OEMOil:
                return oilsOEMOil
            case field === partFields.ATF:
                return oilsATF
            case field === partFields.ASTM:
                return oilsASTM
            case field === partFields.Color:
                return coolantColors
            case field === partFields.DOT:
                return brakeDOT
            case field === partFields.RadioBrand:
                return radioBrands
            case field === partFields.AmplifierBrand:
                return amplifierBrands
            case field === partFields.AudioBrand:
                return audioBrands
            case field === partFields.AudioSize:
                return audioSizes
            default:
                return []
        }
    }, [
        amplifierBrands,
        audioBrands,
        audioSizes,
        brakeDOT,
        cars,
        coolantColors,
        field,
        marks,
        models,
        modificationYears,
        modifications,
        oilsACEA,
        oilsAPI,
        oilsASTM,
        oilsATF,
        oilsBrands,
        oilsOEMOil,
        oilsSAE,
        oilsVolumes,
        partBrands,
        partNumbers,
        partNumbersWithDescription,
        partTypes,
        radioBrands,
        specialProps?.addedField,
        specialProps?.options,
        tiresBrands,
        tiresModels,
        warehousesSS,
    ])

    const getSelectOptionsList = useCallback(() => {
        if (specialProps?.options) {
            return specialProps.options.map(option => ({ value: option, name: option }))
        }

        switch (true) {
            case field === carFields.mileage:
                return mileage
            case field === partFields.conditionId: {
                const fieldParentValue = specialProps?.fieldParentValue
                if (fieldParentValue) {
                    if ([13001, 13004].includes(fieldParentValue as number)) {
                        return wheelsConditionOptions
                    }
                }
                return partConditionOptions
            }
            case field === partFields.TireType:
                return tyreTypes
            case field === partFields.RimType:
                return rimTypes
            case field === carFields.regionId:
                return regions
            case field === partFields.IncomingInvoice:
                return documents
            default:
                return []
        }
    }, [documents, field, specialProps?.fieldParentValue, specialProps?.options])

    const getIsLoading = () => {
        switch (true) {
            case field === carFields.carId:
                return loaders.cars
            case field === carFields.markId || field === carFields.applicabilityMarkId:
                return loaders.marks
            case field === carFields.modelId || field === carFields.applicabilityModelId:
                return loaders.models
            case field === carFields.modificationId || field === carFields.applicabilityModificationId:
                return loaders.modifications
            case field === carFields.year || field === carFields.applicabilityYear:
                return loaders.modificationYears
            case field === partFields.typeId:
                return loaders.partTypes
            case field === partFields.Brand || field === partFields.TireBrand:
                return loaders.tiresBrands
            case field === partFields.PartBrand:
                return loaders.partBrands
            case field === partFields.warehouseTitle:
                return loaders.warehouseSS
            case field === partFields.TireModel:
                return loaders.tiresModels
            case field === partFields.IncomingInvoice:
                return loaders.documents
            case field === partFields.partNumber:
                return loaders.partNumbers
            case field === partFields.OilBrand:
                return loaders.oilsBrands
            case field === partFields.Volume:
                return loaders.oilsVolumes
            case field === partFields.SAE:
                return loaders.oilsSAE
            case field === partFields.ACEA:
                return loaders.oilsACEA
            case field === partFields.API:
                return loaders.oilsAPI
            case field === partFields.OEMOil:
                return loaders.oilsOEMOil
            case field === partFields.ATF:
                return loaders.oilsATF
            case field === partFields.ASTM:
                return loaders.oilsASTM
            case field === partFields.Color:
                return loaders.coolantColors
            case field === partFields.DOT:
                return loaders.brakeDOT
            case field === partFields.RadioBrand:
                return loaders.radioBrands
            case field === partFields.AmplifierBrand:
                return loaders.amplifierBrands
            case field === partFields.AudioBrand:
                return loaders.audioBrands
            case field === partFields.AudioSize:
                return loaders.audioSizes
            default:
                return false
        }
    }

    const onChangeStarRating = (newValue: number) => {
        dispatch({
            type: partCreateUpdateActionTypes.updateField,
            value: {
                field,
                value: newValue,
            },
        })
    }

    const addPartNumberField = () => {
        dispatch({
            type: partCreateUpdateActionTypes.addField,
            value: {
                field: {
                    fieldType: fieldType,
                    valueType: valueType,
                    value: '',
                    title: 'Доп. OEM номер',
                    specialProps: {
                        placeholder: 'введите OEM номер',
                        isAdded: true,
                        addedField: partFields.partNumber,
                        maxAddedFields: specialProps?.maxAddedFields,
                        fieldParent: partFields.partNumber,
                        extraHandle: partFields.partNumber,
                    },
                },
                after: field,
            },
        })
    }

    const deleteApplicability = (index: number) => {
        if (specialProps?.applicabilityItems) {
            const newItems = [...specialProps.applicabilityItems]
            newItems.splice(index, 1)
            dispatch({
                type: partCreateUpdateActionTypes.updateField,
                value: {
                    field,
                    value: !!newItems.length,
                    specialProps: {
                        ...specialProps,
                        [partSpecialPropsFields.applicabilityItems]: newItems,
                    },
                },
            })

            dispatch({
                type: partCreateUpdateActionTypes.returnApplicabilityToSuggestion,
                value: specialProps.applicabilityItems[index],
            })
        }
    }

    const deleteField = (field: partFields | carFields | MarketplacesKeys) => {
        dispatch({
            type: partCreateUpdateActionTypes.deleteField,
            value: {
                field,
            },
        })
    }

    const partNumberSuffix = (
        <>
            {!specialProps?.isAdded ? (
                <span
                    className={styles.plusIcon}
                    onClick={e => {
                        e.stopPropagation()
                        addPartNumberField()
                    }}
                >
                    <PlusLg size={fontSizes.m} color={colors.purple} />
                </span>
            ) : (
                <span className={styles.clearIcon} onClick={() => deleteField(field)}>
                    <XLg color={colors.purple} size={fontSizes.m} />
                </span>
            )}
        </>
    )

    const gettableDataContent = () => {
        switch (true) {
            case fieldType === fieldTypes.switch && Array.isArray(specialProps?.switchRenderOptions):
                return (
                    <PartSwitch
                        onChangeValue={onChangeSwitch}
                        activeOption={typeof value === 'number' ? value : 0}
                        options={specialProps?.switchRenderOptions as string[]}
                    />
                )
            case fieldType === fieldTypes.selectSearch:
                return (
                    <div onClick={() => setIsEditableLocal(true)} className={styles.inputWrap}>
                        <SalesSelectSearch
                            renderValue={specialProps?.renderValue || ''}
                            isEditable={isEditableLocal}
                            getSuggestions={getSuggestionsGetter()}
                            suggestions={suggestionsList}
                            onChangeValue={field === partFields.carId ? onChangeExistingCar : onChangeSelectSearch}
                            onChangeManyValues={field === partFields.typeId ? onChangePartType : undefined}
                            onChangeRenderValue={onChangeRenderValue}
                            allowEmptySearch
                            maxOptions={9999}
                            isLoading={getIsLoading()}
                            placeholder={specialProps?.placeholder}
                            disableAutoFocus
                            isDisabled={specialProps?.isDisabled}
                            filterId={String(specialProps?.fieldParentValue)}
                            errorMessage={specialProps?.errorMessage}
                            validateRenderValue={validateSelectSearchRenderValue}
                            widthInPixelsOptions={specialProps?.isFullWidth ? 530 : 310}
                            delay={300}
                        />
                    </div>
                )
            case fieldType === fieldTypes.readOnly: {
                const text = specialProps?.renderValue || specialProps?.placeholder
                return (
                    <SalesText
                        text={text || ''}
                        color={colors.grayDisabled}
                        marginLeftPixels={20}
                        className={specialProps?.readOnlyTextOverlowEllipsis && styles.textOverlowEllipsis}
                    />
                )
            }
            case fieldType === fieldTypes.select:
                return (
                    <SalesSelect
                        text={specialProps?.renderValue || ''}
                        onChangeValue={onChangeSelect}
                        options={getSelectOptionsList()}
                        maxOptions={7}
                        placeholder={specialProps?.placeholder || ''}
                        errorMessage={specialProps?.errorMessage}
                        widthInPixelsOptions={310}
                    />
                )

            case field === partFields.partPrice:
                return (
                    <div className={styles.mainPartNumber}>
                        <SalesInput
                            value={String(value)}
                            onChangeValue={onChangeInput}
                            disableAutoFocus
                            isDisabled={specialProps?.isDisabled}
                            errorMessage={specialProps?.errorMessage}
                            isNumber
                            delay={300}
                            isConvertValueToLocaleString
                        />
                        {specialProps?.additionalHref && (
                            <div className={styles.customLink}>
                                Цены на
                                <CustomLink
                                    text={'Дром'}
                                    href={specialProps.additionalHref}
                                    target={'_blank'}
                                    marginLeft={4}
                                    marginRight={4}
                                />
                            </div>
                        )}
                    </div>
                )
            case valueType === valueTypes.price:
                return (
                    <SalesInput
                        value={String(value)}
                        onChangeValue={onChangeInput}
                        disableAutoFocus
                        isDisabled={specialProps?.isDisabled}
                        errorMessage={specialProps?.errorMessage}
                        isNumber
                        delay={300}
                        isConvertValueToLocaleString
                    />
                )
            case fieldType === fieldTypes.textarea:
                if (field === partFields.descTemplate) {
                    return (
                        <SingleAccordion
                            marginTopPixels={12}
                            width="fit-content"
                            title="Дополнительное описание для всех объявлений автомобиля"
                        >
                            <SalesDescriptionInput
                                title="Этот текст добавляется к описаниям во всех объявлений автомобиля"
                                width="100%"
                                errorMessage={'Превышен лимит'}
                                value={String(value) || ''}
                                onChangeTextArea={onChangeTextArea}
                                templateButtons={specialProps?.templateButtons}
                            />
                        </SingleAccordion>
                    )
                }

                return (
                    <SalesTextArea
                        minHeightInPixels={specialProps?.textareaMinHeight}
                        value={String(value) || ''}
                        placeholder={specialProps?.placeholder || ''}
                        onChangeValue={onChangeTextArea}
                        disableAutoFocus
                        maxLength={specialProps?.textareaMaxLength}
                        isDisabled={specialProps?.isDisabled}
                        errorMessage={specialProps?.errorMessage}
                        marginTopPixels={field === partFields.description ? -6 : 12}
                        marginBottomPixels={12}
                        templateButtons={specialProps?.templateButtons}
                        fillPlaceholderOnClick={
                            field === partFields.customTitle &&
                            specialProps?.placeholder !== 'название для маркетплейсов'
                        }
                        isClearButton={specialProps?.isClearButton}
                        delay={300}
                    />
                )

            case fieldType === fieldTypes.files:
                return (
                    <SalesUploadImages
                        input={input}
                        handleAddImages={onImagesAdd}
                        removeFile={onImageRemove}
                        handleImagesReorder={onImagesReorder}
                    />
                )

            case fieldType === fieldTypes.checkboxGroup &&
                specialProps?.checkboxItems !== undefined &&
                specialProps?.checkboxItems.length > 0:
                return (
                    <div className={styles.checkboxGroup}>
                        {specialProps?.checkboxItems !== undefined &&
                            specialProps.checkboxItems.map((checkbox, i) => (
                                <SalesCheckboxMarketplace
                                    key={checkbox.title + i}
                                    label={checkbox.title}
                                    isChecked={Boolean(checkbox.value)}
                                    onChange={value => onCheckboxChange(checkbox.field, value)}
                                    className={'d-flex'}
                                    overlayText={
                                        checkbox.field === MarketplacesKeys.Avito
                                            ? specialProps.avitoOverlayText
                                            : undefined
                                    }
                                />
                            ))}
                    </div>
                )

            case fieldType === fieldTypes.checkboxGroupColumn &&
                specialProps?.checkboxItems !== undefined &&
                specialProps?.checkboxItems.length > 0:
                return (
                    <div className={'d-flex justify-content-around w-100 flex-column'}>
                        {specialProps?.checkboxItems !== undefined &&
                            specialProps.checkboxItems.map((checkbox, i) => (
                                <SalesCheckboxMarketplace
                                    key={checkbox.title + i}
                                    label={checkbox.title}
                                    isChecked={Boolean(checkbox.value)}
                                    onChange={value => onCheckboxChange(checkbox.field, value)}
                                />
                            ))}
                    </div>
                )

            case fieldType === fieldTypes.starRating:
                return <StarRating value={Number(value)} onChangeValue={onChangeStarRating} />

            case fieldType === fieldTypes.partNumber:
                if (specialProps?.isAdded) {
                    return (
                        <div onClick={() => setIsEditableLocal(true)} className={styles.inputWrap}>
                            <SalesSelectSearch
                                renderValue={specialProps?.renderValue || ''}
                                onChangeValue={onChangeSelectSearch}
                                onChangeRenderValue={onChangeRenderValue}
                                isEditable={isEditableLocal}
                                getSuggestions={getSuggestionsGetter()}
                                suggestions={suggestionsList}
                                isLoading={getIsLoading()}
                                maxOptions={9999}
                                placeholder={specialProps?.placeholder}
                                allowEmptySearch
                                delay={400}
                                disableAutoFocus
                                filterId={String(specialProps.fieldParentValue)}
                                widthInPixelsOptions={specialProps?.isFullWidth ? 530 : 310}
                                suffixElement={partNumberSuffix}
                                searchEnabled={!!specialProps.fieldParentValue}
                                hideSearchSuffix
                            />
                        </div>
                    )
                } else {
                    return (
                        <div className={styles.mainPartNumber}>
                            <SalesSelectSearch
                                renderValue={specialProps?.renderValue || ''}
                                onChangeValue={onChangeSelectSearch}
                                onChangeRenderValue={newValue => onChangeRenderValue(newValue, true)}
                                isEditable={isEditableLocal}
                                getSuggestions={getSuggestionsGetter()}
                                suggestions={suggestionsList}
                                isLoading={getIsLoading()}
                                maxOptions={9999}
                                placeholder={specialProps?.placeholder}
                                delay={400}
                                disableAutoFocus
                                filterId={specialProps?.filterId}
                                widthInPixelsOptions={specialProps?.isFullWidth ? 530 : 310}
                                suffixElement={partNumberSuffix}
                                isDisableFindCurrentOption
                                optionBeforeText={
                                    <PlusLg size={fontSizes.xxs} color={colors.purple} style={{ marginRight: '4px' }} />
                                }
                                inputPaddingRight={60}
                            />
                            {field === partFields.partNumber && String(specialProps?.renderValue).length > 4 ? (
                                <div className={styles.customLink}>
                                    Искать ОЕМ на
                                    <CustomLink
                                        text={'Дром'}
                                        href={`https://baza.drom.ru/oem/${String(specialProps?.renderValue)}/`}
                                        target={'_blank'}
                                        marginLeft={4}
                                        marginRight={4}
                                    />
                                    /
                                    <CustomLink
                                        text={'Zzap'}
                                        href={`https://www.zzap.ru/public/search.aspx#rawdata=${String(specialProps?.renderValue)}&type=2`}
                                        target={'_blank'}
                                        marginLeft={4}
                                        marginRight={4}
                                    />
                                </div>
                            ) : null}
                        </div>
                    )
                }

            case fieldType === fieldTypes.applicabilitySelected:
                return (
                    <div className={'d-flex gap-3 flex-wrap my-1'}>
                        {specialProps?.applicabilityItems !== undefined &&
                            specialProps?.applicabilityItems.length > 0 &&
                            specialProps?.applicabilityItems.map((item, i) => (
                                <div key={item.title + i}>
                                    <SalesButton
                                        backgroundColor={colors.greenLight}
                                        borderRadius={4}
                                        paddingHorizontalPixels={20}
                                        paddingVerticalPixels={5}
                                    >
                                        <>
                                            <SalesText text={item.title} color={colors.white} />
                                            {!specialProps.readOnly && (
                                                <span
                                                    className={styles.applicabilityClearIcon}
                                                    onClick={() => deleteApplicability(i)}
                                                >
                                                    <XLg color={colors.white} size={fontSizes.l} />
                                                </span>
                                            )}
                                        </>
                                    </SalesButton>
                                </div>
                            ))}
                    </div>
                )

            case fieldType === fieldTypes.applicabilitySuggestion:
                return (
                    <div className={'d-flex gap-3 flex-wrap my-1'}>
                        {specialProps?.applicabilitySuggestionItems !== undefined &&
                            specialProps?.applicabilitySuggestionItems.length > 0 &&
                            specialProps?.applicabilitySuggestionItems.map((item, i) => {
                                if (!item.isAdded) {
                                    return (
                                        <div key={item.title + i}>
                                            <SalesButton
                                                backgroundColor={colors.white}
                                                borderColor={colors.greenLight}
                                                borderWidth={1}
                                                borderStyle={borderStyles.solid}
                                                borderRadius={4}
                                                paddingHorizontalPixels={20}
                                                paddingVerticalPixels={5}
                                                onClick={() =>
                                                    specialProps.addApplicability && specialProps.addApplicability(item)
                                                }
                                            >
                                                <SalesText text={item.title} />
                                            </SalesButton>
                                        </div>
                                    )
                                }
                            })}
                    </div>
                )

            case fieldType === fieldTypes.applicabilityButtons:
                return (
                    <div className={'d-flex justify-content-between w-100'}>
                        <SalesButton
                            backgroundColor={colors.grayLight}
                            borderRadius={4}
                            paddingHorizontalPixels={30}
                            paddingVerticalPixels={5}
                            onClick={() => specialProps?.addApplicability && specialProps.addApplicability()}
                        >
                            <SalesText text={'Добавить'} color={colors.white} />
                        </SalesButton>
                        <SalesButton
                            backgroundColor={colors.grayLight}
                            borderRadius={4}
                            paddingHorizontalPixels={42}
                            paddingVerticalPixels={5}
                            onClick={toggleAccordion}
                        >
                            <SalesText text={'Убрать'} color={colors.white} />
                        </SalesButton>
                    </div>
                )

            default:
                return (
                    <SalesInput
                        value={String(value) || ''}
                        placeholder={specialProps?.placeholder || ''}
                        onChangeValue={onChangeInput}
                        disableAutoFocus
                        isDisabled={specialProps?.isDisabled}
                        errorMessage={specialProps?.errorMessage}
                        maxLength={specialProps?.maxLength}
                        onClick={specialProps?.onClick}
                        delay={300}
                        isNumber={valueType === valueTypes.number}
                    />
                )
        }
    }

    const getTableDataWidthInPixels = () => {
        switch (true) {
            case specialProps?.isFullWidth:
                return 0
            case !!specialProps?.customWidth:
                return specialProps?.customWidth
            default:
                return 222
        }
    }

    const toggleAccordion = () => {
        dispatch({
            type: partCreateUpdateActionTypes.updateField,
            value: {
                field: field,
                value: !value,
            },
        })
    }

    return (
        <>
            {fieldType !== fieldTypes.void && (
                <SalesRow
                    lastRowBottomBorder={isLast}
                    height={specialProps?.rowHeight}
                    borderColor={specialProps?.borderColor}
                    removeBorderTop={
                        field === partFields.carId ||
                        field === partFields.description ||
                        field === carFields.year ||
                        specialProps?.borderTop === false
                    }
                >
                    <>
                        {fieldType !== fieldTypes.checkboxGroup && (
                            <SalesTableData
                                widthInPixels={getTableDataWidthInPixels()}
                                alignItems={specialProps?.alignItemsStretch ? 'stretch' : undefined}
                            >
                                <>
                                    <SalesText
                                        text={title}
                                        color={colors.black}
                                        fontWeight={
                                            specialProps?.fontWeight ? specialProps.fontWeight : fontWeights.semiBold
                                        }
                                        shrinkToFit={field === partFields.customTitle}
                                        onClick={specialProps?.onClick}
                                        href={specialProps?.href}
                                        marginTopPixels={specialProps?.alignItemsStretch ? 15 : undefined}
                                    />
                                    {specialProps?.additionalTitleText ? (
                                        <SalesText
                                            text={specialProps.additionalTitleText}
                                            color={colors.purple}
                                            marginLeftPixels={4}
                                        />
                                    ) : null}
                                    {specialProps?.copyButton ? <CopyButton copyText={String(value)} /> : null}
                                    {specialProps?.printerButton && specialProps?.printerOnClick ? (
                                        <span className={styles.icon}>
                                            <Printer
                                                size={fontSizes.s}
                                                color={colors.purple}
                                                onClick={specialProps.printerOnClick}
                                            />
                                        </span>
                                    ) : null}
                                    {specialProps?.onClickAddButton && (
                                        <SalesButton
                                            backgroundColor={colors.grayLight}
                                            borderRadius={4}
                                            paddingHorizontalPixels={30}
                                            paddingVerticalPixels={5}
                                            onClick={specialProps.onClickAddButton}
                                            marginLeftPixels={20}
                                        >
                                            <SalesText text={'Добавить'} color={colors.white} />
                                        </SalesButton>
                                    )}

                                    {specialProps?.isShowAccordionButton ? (
                                        <Accordion activeKey={value ? '' : '0'} className={styles.accordion}>
                                            <AccordionButton
                                                onClick={toggleAccordion}
                                                className={styles.accordionButton}
                                            />
                                        </Accordion>
                                    ) : null}

                                    {specialProps?.hoverText ? (
                                        <SalesTableDataInfo
                                            text={specialProps.hoverText}
                                            marginLeftPixels={7}
                                            marginRightPixels={7}
                                        />
                                    ) : null}
                                    {getIsRequiredDot() ? <span className={styles.isRequired} /> : null}
                                </>
                            </SalesTableData>
                        )}
                        {!specialProps?.isTitleFullWidth && (
                            <SalesTableData
                                widthInPixels={specialProps?.isFullWidth ? 528 : 308}
                                justifyContent={field === partFields.partImages ? 'center' : undefined}
                            >
                                {gettableDataContent()}
                            </SalesTableData>
                        )}
                    </>
                </SalesRow>
            )}
        </>
    )
}

export default SalesCardRowPart
