import { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'

import getAllPartGroups from '../../../modules/redux/catalogs/getAllPartGroups'
import getMarketplacesSettings from '../../../modules/redux/sales/getMarketplacesSettings'
import setMarketplacesSettings from '../../../modules/redux/sales/setMarketplacesSettings'
import { useDispatch } from '../../../modules/store/customDispatch'
import { useSelector } from '../../../modules/store/customSelector'
import Loader from '../../_atoms/Loader/Loader'
import OverlayWithText from '../../_atoms/OverlayWithText/OverlayWithText'

interface IProps {
    isOpen: boolean
    closeModal: () => void
    marketplace: string
}

const ModalMarketplace = ({ isOpen, closeModal, marketplace }: IProps) => {
    const dispatch = useDispatch()
    const [withPrice, setWithPrice] = useState(false)
    const [withPhoto, setWithPhoto] = useState(false)
    const [selectedCategories, setSelectedCategories] = useState<string[]>([])
    const [priceFrom, setPriceFrom] = useState<number | string>(0)
    const [priceIncrease, setPriceIncrease] = useState<number | string>(0)
    const [isLoading, setIsLoading] = useState(false)
    const categories = useSelector(state => state.partList.partGroups)
    const settings = useSelector(state => state.sales.marketplaces.settings)

    const handleCategoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, checked } = e.target
        if (checked) {
            setSelectedCategories(prevCategories => [...prevCategories, id])
        } else {
            setSelectedCategories(prevCategories => prevCategories.filter(category => category !== id))
        }
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const formData = {
            withPrice,
            withPhoto,
            selectedCategories,
            priceFrom: Number(priceFrom),
            priceIncrease: Number(priceIncrease),
        }
        setIsLoading(true)
        await dispatch(setMarketplacesSettings(formData, marketplace))
        setIsLoading(false)
        onCloseModal()
    }

    const onCloseModal = () => {
        closeModal()
    }

    const loadData = async () => {
        setIsLoading(true)
        await dispatch(getAllPartGroups())
        await dispatch(getMarketplacesSettings(marketplace))
        setIsLoading(false)
    }

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        if (isLoading || !categories.length) {
            return
        }

        setWithPrice(settings.withPrice ? settings.withPrice : false)
        setWithPhoto(settings.withPhoto ? settings.withPhoto : false)
        setPriceFrom(settings.priceFrom ? settings.priceFrom : 0)
        setPriceIncrease(settings.priceIncrease ? settings.priceIncrease : 0)

        if (!settings.selectedCategories) {
            setSelectedCategories(categories.map(category => `${category.value}`))
        } else {
            setSelectedCategories(settings.selectedCategories)
        }
    }, [isLoading, settings])

    return (
        <Modal show={isOpen} onHide={onCloseModal} centered>
            {isLoading && (
                <OverlayWithText backgroundBootstrapColor={'bg-secondary'}>
                    <Loader diameterInPx={100} thicknessInPx={10} />
                </OverlayWithText>
            )}
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <h4>
                        <b>Настройки выгрузки</b>
                    </h4>
                    <Form.Group as={Row} className="mb-2" controlId="withPrice">
                        <Form.Label column sm="5">
                            Только с ценами
                        </Form.Label>
                        <Col sm="7" className={'py-2'}>
                            <Form.Check
                                type={'checkbox'}
                                id={`withPrice`}
                                checked={withPrice}
                                onChange={e => setWithPrice(e.target.checked)}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-2" controlId="withPhoto">
                        <Form.Label column sm="5">
                            Только с фото
                        </Form.Label>
                        <Col sm="7" className={'py-2'}>
                            <Form.Check
                                type={'checkbox'}
                                id={`withPhoto`}
                                checked={withPhoto}
                                onChange={e => setWithPhoto(e.target.checked)}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="category">
                        <Form.Label column sm="5">
                            Категории
                        </Form.Label>
                        <Col sm="7" className={'py-2'}>
                            {categories &&
                                categories.map(category => (
                                    <Form.Check
                                        type={'checkbox'}
                                        id={`${category.value}`}
                                        label={category.name}
                                        checked={selectedCategories.includes(`${category.value}`)}
                                        onChange={handleCategoryChange}
                                        key={`${category.value}${category.name}`}
                                    />
                                ))}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="priceFrom">
                        <Form.Label column sm="5">
                            Цена от (Р)
                        </Form.Label>
                        <Col sm="7">
                            <Form.Control
                                type={'number'}
                                min={'0'}
                                value={priceFrom}
                                onChange={e => setPriceFrom(e.target.value)}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="priceIncrease">
                        <Form.Label column sm="5">
                            Увеличение базовой цены, %
                        </Form.Label>
                        <Col sm="7" className="mt-2">
                            <Form.Control
                                type={'number'}
                                min={'0'}
                                value={priceIncrease}
                                onChange={e => setPriceIncrease(e.target.value)}
                            />
                        </Col>
                    </Form.Group>
                    <div className="d-flex justify-content-end">
                        <Button type={'submit'}>Сохранить</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default ModalMarketplace
