import React, { forwardRef, ReactElement, Ref, useCallback, useEffect, useState } from 'react'
import Select from 'react-select'

import useDebounce from '../../../hooks/useDebounce'

interface ComponentProps<T> {
    value: T | null
    onChange: (value: T | null) => void
    onSearch: (search: string) => void
    getOptionLabel: (option: T) => string
    getOptionValue: (option: T) => string
    options?: T[]
    placeholder?: string
    isLoading?: boolean
}

const CustomSelect = <T,>(
    {
        value,
        onChange,
        onSearch,
        getOptionLabel,
        getOptionValue,
        options = [],
        placeholder = 'Выберите значение',
        isLoading = false,
    }: ComponentProps<T>,
    ref?: Ref<any>,
) => {
    const [searchValue, setSearchValue] = useState(value ? getOptionLabel(value) : '')
    const debouncedSearch = useDebounce(searchValue, 300)

    const handleSearch = useCallback(
        (search: string) => {
            onSearch(search)
        },
        [onSearch],
    )

    useEffect(() => {
        if (debouncedSearch !== undefined) {
            handleSearch(debouncedSearch)
        }
    }, [debouncedSearch, handleSearch])

    const handleInputChange = (value: string) => {
        setSearchValue(value)
    }

    return (
        <Select
            value={value}
            onChange={onChange}
            options={options}
            onInputChange={handleInputChange}
            isLoading={isLoading}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            placeholder={placeholder}
            loadingMessage={() => 'Загрузка...'}
            noOptionsMessage={() => 'Нет данных'}
            ref={ref}
            filterOption={null}
            isClearable
        />
    )
}

export default forwardRef(CustomSelect) as <T>(props: ComponentProps<T> & { ref?: Ref<any> }) => ReactElement
