export type GetDealsListApiResponse = {
    data: Deal[]
    dataCount: number
}
export type GetDealsListApiArg = DealListReq

export type SaveDealApiResponse = {
    status: string
    code: number
    data: Deal
}
export type SaveDealApiArg = DealSaveReq

export type GetDealStagesInfoApiResponse = {
    data: Record<DealStage, StageInfo>
}
export type GetDealStagesInfoApiArg = {
    userId: string
}

export type GetDealStatusDictApiResponse = {
    data: Record<DealStatus, DealStatusInfo>
}

export type SaveClientApiResponse = Client
export type SaveClientApiArg = ClientSaveReq

export type GetClientsListApiResponse = {
    data: Client[]
    dataCount: number
}
export type GetClientsListApiArg = ClientsListReq

export type GetVendorDataApiResponse = {
    status: string
    code: number
    data: VendorData
}
export type GetVendorDataApiArg = {
    userId: string
}

export type UpdateVendorDataApiResponse = {
    status: string
    code: number
    data: VendorData
}
export type UpdateVendorDataApiArg = {
    userId: string
    vendorData: VendorDataWritable
}

export type Deal = {
    id: string
    userId: string
    code: string
    status: DealStatus
    itemSum?: number
    paidSum?: number
    items?: DealItem[]
    client?: DealClient
    seller?: DealSeller
    meta?: DealMeta
    isDeleted?: boolean
    createdAt?: number
    updatedAt?: number
}

export type DealWritable = {
    id?: string
    userId: string
    code: string
    status: DealStatus
    itemSum?: number
    paidSum?: number
    items?: DealItem[]
    client?: DealClient
    seller?: DealSeller
    meta?: DealMeta
}

export enum DealStatus {
    new = 'new',
    reserved = 'reserved',
    partly_paid = 'partly_paid',
    paid = 'paid',
    ready = 'ready',
    issued = 'issued',
    completed = 'completed',
    canceled = 'canceled',
}

// Тип для элементов словаря
export interface DealStatusInfo {
    code: DealStatus
    name: string
}

// Enum для стадий
export enum DealStage {
    inprogress = 'inprogress',
    done = 'done',
    canceled = 'canceled',
    all = 'all',
}

// Описание структуры для каждой стадии
export interface StageInfo {
    title: string
    statuses: DealStatus[]
    count: number
}

export type DealItem = {
    title: string
    price: number
    qty: number
    part: Part
}

export type Part = {
    id: string
    vendorCode: string
    customTitle: string
    title: string
    markTitle: string
    modelTitle: string
    generationTitle: string
    warehouse?: WarehouseData
}

export type WarehouseData = {
    title?: string
    place?: string
    row?: string
    shelf?: string
}

export type DealClient = {
    id: string
    name: string
    phone: string
}

export type DealSeller = {
    id?: string
    name: string
    address?: string
    phone?: number
}

export type DealMeta = {
    comment: string
    source: string
}

export type DealsSearchParams = {
    pagination: {
        page: number
        perPage: number
    }
    id?: string
    userId?: string
    /** Массив статусов, по которым хотим фильтровать */
    status?: DealStatus | DealStatus[]
    isDeleted?: boolean
}

export type DealSaveReq = {
    deal: {
        id?: string
        userId: string
        code?: string
        status: DealStatus
        items?: DealItem[]
        client?: DealClient
        seller?: DealSeller
        meta?: DealMeta
    }
    userId: string
}

export type DealListReq = {
    userId: string
    params: DealsSearchParams
}

export type Client = {
    id: string
    userId: string
    code: string
    name: string
    phone?: string
    email?: string
    meta?: ClientMeta
    isDeleted?: boolean
    createdAt?: number
    updatedAt?: number
}

export type ClientWritable = {
    id?: string
    userId: string
    code: string
    name: string
    phone?: string
    email?: string
    meta?: ClientMeta
}

export type ClientMeta = {
    publicComment?: string
    privateNote?: string
}

export type ClientsSearchParams = {
    pagination: {
        page: number
        perPage: number
    }
    id?: string
    userId?: string
    search?: string
    isDeleted?: boolean
}

export type ClientSaveReq = {
    client: ClientWritable
    userId: string
}

export type ClientsListReq = {
    userId: string
    params: ClientsSearchParams
}

export interface VendorExtraData {
    startingNumber?: number
    vendorName: string
    vendorITN: number
    vendorAddress: string
    vendorTelephone: string
    vendorLogoId?: string
    vendorLogoUrl?: string
    vendorWarrantyRestrictions: string
}

export interface VendorDataWritable {
    user_id: string
    extra_data: VendorExtraData
}

export interface VendorData extends VendorDataWritable {
    createdAt: string
    updatedAt: string
}
