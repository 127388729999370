import React from 'react'
import { ArrowUp, ArrowDown } from 'react-bootstrap-icons'

interface IProps {
    change?: {
        before: number
        after: number
    }
}

const NumChange: React.FC<IProps> = ({ change }) => {
    const beforePrice = Number(change?.before || 0)
    const afterPrice = Number(change?.after || 0)
    const diff = afterPrice - beforePrice

    let arrowIcon = null
    if (diff > 0) {
        arrowIcon = <ArrowUp color="green" />
    } else if (diff < 0) {
        arrowIcon = <ArrowDown color="red" />
    }

    return (
        <span>
            {beforePrice} → {afterPrice} {arrowIcon && <span>{arrowIcon}</span>}
        </span>
    )
}

export default NumChange
