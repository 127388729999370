import { useEffect, useState } from 'react'
import { XLg, Search } from 'react-bootstrap-icons'
import styles from './styles.module.scss'
import SalesInput from '../../_atoms/SalesInput/SalesInput'
import { colors, fontSizes } from '../../../constants/salesStyles'
import SalesText from '../../_atoms/SalesText/SalesText'

interface IProps {
    // Текущее значение поиска
    searchTerm?: string
    // Callback для обновления значения поиска
    setSearchTerm: (term: string) => void
    // Количество найденных результатов (опционально)
    resultCount?: number

    placeholder?: string
}

const SearchComponentRTK = ({ searchTerm = '', setSearchTerm, resultCount, placeholder = 'Поиск по названию, артикулу, OEM или Avito ID' }: IProps) => {
    const [inputValue, setInputValue] = useState<string>(searchTerm)

    useEffect(() => {
        setInputValue(searchTerm)
    }, [searchTerm])

    const handleClear = () => {
        setInputValue('')
        setSearchTerm('')
    }

    const handleSubmit = () => {
        setSearchTerm(inputValue)
    }

    return (
        <SalesInput
            value={inputValue}
            placeholder={placeholder}
            onChangeValue={setInputValue}
            onSubmit={handleSubmit}
            paddingRight={130}
            suffixElement={
                <>
                    {typeof resultCount === 'number' && (
                        <SalesText
                            text={`${resultCount} рез`}
                            marginRightPixels={10}
                            color={colors.grayBorder}
                            fontSize={fontSizes.xxs}
                        />
                    )}
                    {inputValue && (
                        <span className={styles.icon} onClick={handleClear}>
                            <XLg size={20} color={colors.purple} />
                        </span>
                    )}
                    <span className={styles.icon} onClick={handleSubmit}>
                        <Search size={20} color={colors.purple} />
                    </span>
                </>
            }
        />
    )
}

export default SearchComponentRTK
