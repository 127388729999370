import React, { useCallback, useEffect, useState } from 'react'
import { Button, Stack, Table } from 'react-bootstrap'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import Badge from '../../components/_atoms/Badge/Badge'
import BadgeStatus from '../../components/_atoms/BadgeStatus/BadgeStatus'
import ListNoItems from '../../components/_atoms/ListNoItems/ListNoItems'
import ListPagination from '../../components/_atoms/ListPagination/ListPagination'
import ListSkeleton from '../../components/_atoms/ListSkeleton/ListSkeleton'
import SalesText from '../../components/_atoms/SalesText/SalesText'
import ModalDataVendorDocument from '../../components/_molecules/ModalDataVendorDocument/ModalDataVendorDocument'
import { fontSizes, fontWeights } from '../../constants/salesStyles'
import { ToastType, useToast } from '../../hooks/useToast'
import {
    useGetDealsListMutation,
    useGetDealStagesInfoQuery,
    useGetDealStatusDictQuery,
} from '../../modules/store/dochubApi/dochubApi'
import { DealStage } from '../../modules/store/dochubApi/dochubTypes'
import { adminRouteAlias, getAdminNavigationPath } from '../../router/adminRouteAlias'
import { Marketplaces, MarketplacesKeys } from '../../types/part/partTypes'
import { formatDate } from '../../utils/converters'
import { formatPrice } from '../../utils/formatAndUnformatPrice'
import { colWidth } from '../../utils/stylePatches'

import styles from './styles.module.scss'

const perPage = 50

const DealList = () => {
    const navigate = useNavigate()

    const {
        data: dealStatusDictData,
        isLoading: isLoadingGetDealStatusDict,
        isError: isErrorGetDealStatusDict,
    } = useGetDealStatusDictQuery()
    const {
        data: dealStagesInfo,
        isLoading: isLoadingGetDealStagesInfo,
        isError: isErrorGetDealStagesInfo,
    } = useGetDealStagesInfoQuery({})
    const [getDealsList, { data: dealsList, isLoading: isLoadingGetDealsList, isError: isErrorGetDealsList }] =
        useGetDealsListMutation()
    const [dealsCount, setDealsCount] = useState(0)

    const [searchParams, setSearchParams] = useSearchParams()
    const activeStage = (searchParams.get('activeStage') as DealStage) || DealStage.inprogress
    const searchPage = Number(searchParams.get('page')) || 1
    const [page, setPage] = useState(searchPage)

    const [isModalDataVendorOpen, setIsModalDataVendorOpen] = useState(false)

    const openDeal = useCallback(
        (dealId: string) => {
            navigate(getAdminNavigationPath(adminRouteAlias.deals.edit) + `/${dealId}`)
        },
        [navigate],
    )

    const renderListPagination = useCallback(() => {
        if (!isLoadingGetDealsList && dealsCount === 0) return null

        return (
            <div>
                <ListPagination
                    page={page}
                    setPage={setPage}
                    perPage={perPage}
                    itemsCount={dealsCount}
                    justifyContent="flex-end"
                />
            </div>
        )
    }, [dealsCount, isLoadingGetDealsList, page])

    useEffect(() => {
        if (searchPage !== page) {
            setSearchParams(prev => {
                const newParams = new URLSearchParams(prev)
                newParams.set('page', String(page))
                // newParams.set('activeStage', activeStage)
                return newParams
            })
        }
    }, [page])

    useEffect(() => {
        setPage(searchPage)
    }, [searchPage])

    useEffect(() => {
        if (!isLoadingGetDealStagesInfo && dealStagesInfo) {
            getDealsList({
                params: { pagination: { page, perPage }, status: dealStagesInfo.data[activeStage].statuses },
            })
        }
    }, [activeStage, dealStagesInfo, getDealsList, isLoadingGetDealStagesInfo, page])

    useEffect(() => {
        dealsList && setDealsCount(dealsList.dataCount)
    }, [dealsList])

    useToast({ isTriggered: isErrorGetDealsList, type: ToastType.ERROR, message: 'Ошибка загрузки сделок' })
    useToast({
        isTriggered: isErrorGetDealStagesInfo,
        type: ToastType.ERROR,
        message: 'Ошибка загрузки информации о стадиях сделок',
    })
    useToast({
        isTriggered: isErrorGetDealStatusDict,
        type: ToastType.ERROR,
        message: 'Ошибка загрузки словаря статусов',
    })

    return (
        <div className={styles.wrap}>
            <ToastContainer position="bottom-center" autoClose={5000} />
            {isModalDataVendorOpen && (
                <ModalDataVendorDocument
                    isOpen={isModalDataVendorOpen}
                    closeModal={() => setIsModalDataVendorOpen(false)}
                />
            )}

            <div className={styles.rowWrap}>
                <SalesText
                    text={'Сделки'}
                    fontWeight={fontWeights.bold}
                    fontSize={fontSizes.xxl}
                    letterSpacing={-0.02}
                />
            </div>
            <Stack direction="horizontal">
                {dealStagesInfo && (
                    <Stack direction="horizontal" gap={4}>
                        {Object.entries(dealStagesInfo.data).map(([key, stageInfo]) => (
                            <Button
                                key={key}
                                variant={activeStage === key ? 'secondary' : 'outline-secondary'}
                                style={{ width: '130px' }}
                                onClick={() =>
                                    navigate(getAdminNavigationPath(adminRouteAlias.deals.list) + `?activeStage=${key}`)
                                }
                            >
                                {stageInfo.title}
                            </Button>
                        ))}
                    </Stack>
                )}
                <div className="ms-auto">
                    <Button
                        variant="primary"
                        onClick={() => navigate(getAdminNavigationPath(adminRouteAlias.deals.new))}
                    >
                        + Новая сделка
                    </Button>
                </div>
            </Stack>
            <Stack direction="horizontal">
                <div className="ms-auto">
                    <Button variant="primary" onClick={() => setIsModalDataVendorOpen(true)}>
                        Данные для документов
                    </Button>
                </div>
            </Stack>
            {renderListPagination()}
            <Table striped hover>
                <thead>
                    <tr>
                        <th scope="col" style={colWidth(100)}>
                            Номер
                        </th>
                        <th scope="col" style={colWidth(100)}>
                            Дата
                        </th>
                        <th scope="col" style={colWidth(100)}>
                            Покупатель
                        </th>
                        <th scope="col" style={colWidth(70)} className={styles.priceCell}>
                            Сумма
                        </th>
                        <th scope="col" style={colWidth(70)} className={styles.priceCell}>
                            Кол.
                        </th>
                        <th scope="col" style={colWidth(150)}>
                            Статус
                        </th>
                        <th scope="col" style={colWidth(200)}>
                            Комментарий
                        </th>
                        <th scope="col" style={colWidth(150)}>
                            Источник
                        </th>
                        <th scope="col" style={colWidth(100)}>
                            Ответственный
                        </th>
                    </tr>
                </thead>
                {isLoadingGetDealsList ? (
                    <ListSkeleton rowCount={9} columnCount={9} marginVerticalRem={0.21} />
                ) : (
                    dealsList && (
                        <tbody>
                            {dealsList.data.map((deal, index) => (
                                <tr key={index} className={styles.row} onClick={() => openDeal(deal.id)}>
                                    <td scope="row">{deal.code}</td>
                                    <td scope="row">
                                        <Badge text={formatDate(deal.createdAt)} color="#ddd" fontColor="#555" />
                                    </td>
                                    <td scope="row">{deal.client?.name}</td>
                                    <td scope="row" className={styles.priceCell}>
                                        {formatPrice(deal.itemSum || 0)}
                                    </td>
                                    <td scope="row" className={styles.priceCell}>
                                        <div>{deal.items?.reduce((acc, cur) => acc + cur.qty, 0)} шт</div>
                                    </td>
                                    <td scope="row">
                                        <BadgeStatus
                                            text={
                                                dealStatusDictData
                                                    ? dealStatusDictData.data[deal.status]?.name || '---'
                                                    : deal.status
                                            }
                                            status={deal.status}
                                        />
                                    </td>
                                    <td scope="row">{deal.meta?.comment}</td>
                                    <td scope="row">
                                        {deal.meta?.source && Marketplaces[deal.meta.source as MarketplacesKeys]}
                                    </td>
                                    <td scope="row">{deal.seller?.name}</td>
                                </tr>
                            ))}
                        </tbody>
                    )
                )}
            </Table>
            {!isLoadingGetDealsList && dealsCount === 0 ? <ListNoItems /> : renderListPagination()}
        </div>
    )
}

export default DealList
