import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'

import setPartsWarehousePosition from '../../../modules/redux/warehouseList/SetPartsWarehousePosition'
import { useDispatch } from '../../../modules/store/customDispatch'
import { partReducer } from '../../../pages/NewPart/partReducer'
import { carFields } from '../../../types/car/carTypes'
import {
    Part,
    partCreateUpdateActionTypes,
    partFields,
    partInput,
    PartListSearchParams,
    partSpecialPropsFields,
} from '../../../types/part/partTypes'
import Loader from '../../_atoms/Loader/Loader'
import OverlayWithText from '../../_atoms/OverlayWithText/OverlayWithText'
import PartCreateUpdateTable from '../PartCreateUpdateTable/PartCreateUpdateTable'

import { initialPartState } from './initialPartState'
import styles from './styles.module.scss'

interface IProps {
    isOpen: boolean
    closeModal: () => void
    parts: Part[]
    confirmChanges: () => void
    partListSearchParams?: PartListSearchParams
}

const ModalLinkToWarehouse = ({ isOpen, closeModal, parts, confirmChanges, partListSearchParams }: IProps) => {
    const dispatchRedux = useDispatch()
    const [state, dispatch] = useReducer(partReducer, initialPartState)
    const [isLoading, setIsLoading] = useState(false)
    const [isChecked, setIsChecked] = useState(false)

    const getField = useCallback(
        (fieldToSearch: carFields | partFields) =>
            state.fields.find((field: partInput) => field.field === fieldToSearch),
        [state.fields],
    )

    const isConfirmDisabled = useMemo(
        () => !isChecked && !getField(partFields.warehouseTitle)?.value,
        [getField, isChecked],
    )

    const onCloseModal = () => {
        closeModal()
    }

    const confirm = async () => {
        setIsLoading(true)
        const wId = getField(partFields.warehouseTitle).value
        const wTitle = getField(partFields.warehouseTitle).specialProps.renderValue
        // const wRow = getField(partFields.warehouseRow).value
        // const wShelf = getField(partFields.warehouseShelf).value
        // const wPlace = getField(partFields.warehousePlace).value

        try {
            if (isChecked) {
                await dispatchRedux(
                    setPartsWarehousePosition({
                        partsId: parts.map(part => part.id),
                        searchParams: partListSearchParams,
                    }),
                )
            } else {
                await dispatchRedux(
                    setPartsWarehousePosition({
                        partsId: parts.map(part => part.id),
                        warehouseId: wId,
                        // row: wRow,
                        // shelf: wShelf,
                        // place: wPlace,
                        searchParams: partListSearchParams,
                    }),
                )
            }
            toast.success(
                isChecked ? 'Привязка к сладу успешно сброшена' : `Запчасти успешно привязаны к складу ${wTitle}`,
            )
        } catch (error) {
            toast.error(`Запчасти не удалось привязать к складу`)
            console.error('Ошибка при привязке к складу', error)
        }

        confirmChanges()
        onCloseModal()
        setIsLoading(false)
    }

    const setIsDisabledFields = useCallback(
        () =>
            state.fields.map((field: partInput) => {
                const selectSearchText = field.specialProps?.renderValue
                const errorMessage = field.value ? '' : field.specialProps?.errorMessage

                if (field.specialProps?.fieldParent) {
                    const fieldParentValue = getField(field.specialProps.fieldParent).value
                    const fieldParentValueInChild = field.specialProps?.[partSpecialPropsFields.fieldParentValue]
                    const isEnabled = fieldParentValue
                    const isParentChanged =
                        fieldParentValueInChild &&
                        fieldParentValue !== fieldParentValueInChild &&
                        !field.specialProps.isAdded

                    return {
                        ...field,
                        value: isParentChanged ? '' : field.value,
                        specialProps: {
                            ...field.specialProps,
                            [partSpecialPropsFields.renderValue]: isParentChanged ? '' : selectSearchText,
                            [partSpecialPropsFields.isDisabled]: !isEnabled,
                            [partSpecialPropsFields.fieldParentValue]: isEnabled ? fieldParentValue : '',
                            [partSpecialPropsFields.errorMessage]: isEnabled ? errorMessage : '',
                        },
                    }
                } else {
                    return field
                }
            }),
        [getField, state.fields],
    )

    useEffect(() => {
        if (!isLoading) {
            dispatch({
                type: partCreateUpdateActionTypes.initializeState,
                value: { state: { fields: setIsDisabledFields() } },
            })
        }
    }, [isLoading, getField(partFields.warehouseTitle).value])

    useEffect(() => {
        const warehouseTitleField = getField(partFields.warehouseTitle)
        const warehouseTitleFieldValue = warehouseTitleField.value

        dispatch({
            type: partCreateUpdateActionTypes.updateManyFields,
            value: [
                {
                    field: warehouseTitleField.field,
                    specialPropsField: partSpecialPropsFields.isDisabled,
                    specialPropsValue: isChecked,
                },
                // {
                //     field: partFields.warehouseRow,
                //     specialPropsField: partSpecialPropsFields.isDisabled,
                //     specialPropsValue: !(warehouseTitleFieldValue && !isChecked),
                // },
                // {
                //     field: partFields.warehouseShelf,
                //     specialPropsField: partSpecialPropsFields.isDisabled,
                //     specialPropsValue: !(warehouseTitleFieldValue && !isChecked),
                // },
                // {
                //     field: partFields.warehousePlace,
                //     specialPropsField: partSpecialPropsFields.isDisabled,
                //     specialPropsValue: !(warehouseTitleFieldValue && !isChecked),
                // },
            ],
        })
    }, [isChecked])

    return (
        <Modal show={isOpen} onHide={onCloseModal} centered dialogClassName={styles.modal}>
            {isLoading && (
                <OverlayWithText backgroundBootstrapColor={'bg-secondary'}>
                    <Loader diameterInPx={100} thicknessInPx={10} />
                </OverlayWithText>
            )}
            <Modal.Header closeButton>
                <Modal.Title>
                    <b>Привязать к складу</b>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PartCreateUpdateTable dispatch={dispatch} fields={state.fields} />
                <Form className={styles.radioButton}>
                    <Form.Check
                        type={'checkbox'}
                        label={`Сбросить привязку к складу`}
                        id={`resetCheckbox`}
                        checked={isChecked}
                        onChange={event => setIsChecked(event.target.checked)}
                    />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={confirm} disabled={isConfirmDisabled}>
                    Применить
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalLinkToWarehouse
