import { combineReducers } from 'redux'

import createActionLogListReducer from './actionLogList/actionLogListReducer'
import { atolApi } from './atolApi/atolApi'
import createCarListReducer from './carList/carListReducer'
import { datahubApi } from './datahubApi/datahubApi'
import { dochubApi } from './dochubApi/dochubApi'
import createDocumentListReducer from './documentList/documentListReducer'
import { imagesApi } from './imagesApi/imagesApi'
import createPartListReducer from './partList/partListReducer'
import createSalesReducer from './sales/salesReducer'
import createUserReducer from './user/userReducer'
import createWarehouseListReducer from './warehouseList/warehouseListReducer'

const rootReducer = combineReducers({
    userData: createUserReducer,
    partList: createPartListReducer,
    warehouseList: createWarehouseListReducer,
    carList: createCarListReducer,
    sales: createSalesReducer,
    documentList: createDocumentListReducer,
    actionLogList: createActionLogListReducer,
    [dochubApi.reducerPath]: dochubApi.reducer,
    [datahubApi.reducerPath]: datahubApi.reducer,
    [atolApi.reducerPath]: atolApi.reducer,
    [imagesApi.reducerPath]: imagesApi.reducer,
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
