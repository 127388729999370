import React from 'react'

interface IconButtonProps {
    onClick: () => void
    marginLeft?: number
    marginRight?: number
    color?: string
}

const ArrowIconButton: React.FC<IconButtonProps> = ({ onClick, marginLeft = 0, marginRight = 0, color = 'inherit' }) => (
    <button
        onClick={onClick}
        style={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft,
            marginRight,
            background: 'none',
            border: 'none',
            padding: 0,
            cursor: 'pointer',
            color,
        }}
    >
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <line x1="4" y1="8" x2="12" y2="8" />
            <polyline points="8 4 12 8 8 12" />
        </svg>
    </button>
)

export default ArrowIconButton
