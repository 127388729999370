import axios from 'axios'

import anchor from '../../constants/anchor'
import getAccessToken from '../redux/user/getAccessToken'
import { store } from '../store/store'
import { storeDispatchTypes } from '../store/storeDispatchTypes'

axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
export const apiInstance = axios.create({
    baseURL: 'https://api.stock-pro.net/datahub/',
    // baseURL: 'http://localhost:3001/',
})

export const apiCatalog = axios.create({
    baseURL: 'https://api.stock-pro.net/catalog/',
    // baseURL: 'http://localhost:3001/'
})

export const apiImages = axios.create({
    baseURL: 'https://api.stock-pro.net/images',
    // baseURL: 'http://localhost:3001/images'
})

export const apiPriceChart = axios.create({
    baseURL: 'https://api.stock-pro.net/dsp',
})

export const apiAuth = axios.create({
    baseURL: 'https://api.stock-pro.net/auth',
})

export const apiBilling = axios.create({
    baseURL: 'https://api.stock-pro.net/billing',
    // baseURL: 'http://localhost:37777/'
})

export const apiDocument = axios.create({
    baseURL: 'https://api.stock-pro.net/dochub',
    // baseURL: 'http://localhost:3002/'
})

export const apiTasksService = axios.create({
    baseURL: 'https://api.stock-pro.net/tasks',
    // baseURL: 'http://localhost:3002/'
})

// @ts-ignore
const failedRequests = []
// Response interceptor for API calls
apiInstance.interceptors.response.use(
    response => {
        const state = store.getState()
        const {
            userData: {
                smsAuth: { isRefreshing },
            },
        } = state
        if (failedRequests.length !== 0) {
            // @ts-ignore
            failedRequests.forEach(function (x, i) {
                if (response.config.url === x.url) {
                    // @ts-ignore
                    failedRequests.splice(i, 1)
                }
                if (isRefreshing && failedRequests.length === 0) {
                    store.dispatch({ type: storeDispatchTypes.setIsRefreshingSms, value: { isRefreshing: false } })
                }
            })
        }
        return response
    },
    async error => {
        // Если сами отменили запрос
        if (axios.isCancel(error)) {
            return
        }

        const originalRequest = error.config
        // @ts-ignore
        failedRequests.push(originalRequest)
        const state = store.getState()
        const {
            userData: {
                smsAuth: { isRefreshing },
            },
        } = state
        const oldRefreshToken = localStorage.getItem('refreshToken')
        const oldAccessToken = localStorage.getItem('accessToken')
        if (
            error.response.status === 401 &&
            !originalRequest._retry &&
            !isRefreshing &&
            oldRefreshToken &&
            oldAccessToken
        ) {
            originalRequest._retry = true
            if (!isRefreshing) {
                store.dispatch({ type: storeDispatchTypes.setIsRefreshingSms, value: { isRefreshing: true } })
            }
            await getAccessToken(oldRefreshToken)
            const newAccessToken = localStorage.getItem('accessToken') || undefined
            if (newAccessToken) {
                originalRequest.headers['Authorization'] = `${anchor} ${newAccessToken}`

                if (failedRequests.length != 0) {
                    // @ts-ignore
                    failedRequests.forEach(x => {
                        try {
                            // console.log('failedRequests.forEach', x)
                            x.headers.Authorization = `${anchor} ${newAccessToken}`
                            apiInstance(x)
                        } catch (e) {
                            console.error(e)
                        }
                    })
                }
            } else {
                store.dispatch({ type: storeDispatchTypes.setUserIdSms, value: { userId: undefined } })
                store.dispatch({ type: storeDispatchTypes.setUserPhoneNumberSms, value: { phoneNumber: undefined } })
                store.dispatch({ type: storeDispatchTypes.setIsRefreshingSms, value: { isRefreshing: false } })
            }
            return apiInstance(originalRequest)
        }
        return Promise.reject(error)
    },
)

apiAuth.interceptors.response.use(
    response => response,
    error => {
        store.dispatch({ type: storeDispatchTypes.setAuthErrorSms, value: { msg: error.response.data.msg } })
    },
)

apiAuth.interceptors.request.use(
    request => {
        store.dispatch({ type: storeDispatchTypes.setAuthErrorSms, value: { msg: '' } })
        return request
    },
    error => error,
)
