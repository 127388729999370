import React, { useCallback, useEffect } from 'react'
import { Button, Col, Container, Modal, Row, Stack } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import Form from 'react-bootstrap/Form'

import { ToastType, useToast } from '../../../hooks/useToast'
import { useSaveClientMutation } from '../../../modules/store/dochubApi/dochubApi'
import { Client, SaveClientApiArg } from '../../../modules/store/dochubApi/dochubTypes'
import Loader from '../../_atoms/Loader/Loader'
import OverlayWithText from '../../_atoms/OverlayWithText/OverlayWithText'

interface FormData {
    name: string
    phone?: string
    email?: string
    publicComment?: string
    privateNote?: string
}

interface ComponentProps {
    isOpen: boolean
    closeModal: () => void
    onSuccess?: () => void
    clientData?: Client | null
}

export const ModalSaveClient = ({ isOpen, closeModal, onSuccess, clientData }: ComponentProps) => {
    const { register, handleSubmit, setValue } = useForm<FormData>()

    const [saveClient, { data: client, isLoading, isError }] = useSaveClientMutation()

    useEffect(() => {
        if (clientData) {
            setValue('name', clientData.name)
            setValue('phone', clientData.phone)
            setValue('email', clientData.email)
            setValue('publicComment', clientData.meta?.publicComment)
            setValue('privateNote', clientData.meta?.privateNote)
        }
    }, [clientData, setValue])

    const onSubmit = useCallback(
        (data: FormData) => {
            const clientRequest: Omit<SaveClientApiArg, 'userId'> = {
                client: {
                    userId: localStorage.getItem('userId')!,
                    code: clientData?.code || 'code', // если есть код клиента, то его передаем
                    id: clientData?.id, // если есть id, значит обновляем
                    name: data.name,
                    phone: data.phone,
                    email: data.email,
                    meta: {
                        publicComment: data.publicComment,
                        privateNote: data.privateNote,
                    },
                },
            }
            saveClient(clientRequest)
        },
        [clientData, saveClient],
    )

    useEffect(() => {
        if (client) {
            closeModal()
            onSuccess && onSuccess()
        }
    }, [client, closeModal, onSuccess])

    useToast({ isTriggered: isError, type: ToastType.ERROR, message: 'Ошибка сохранения клиента' })

    return (
        <Modal show={isOpen} onHide={closeModal} centered size={'lg'}>
            {isLoading && (
                <OverlayWithText backgroundBootstrapColor={'bg-secondary'}>
                    <Loader diameterInPx={100} thicknessInPx={10} />
                </OverlayWithText>
            )}
            <Modal.Header closeButton>
                <Modal.Title>
                    {clientData ? `Редактирование клиента: ${clientData.name}` : 'Создание клиента'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: 'rgb(230, 230, 230)' }}>
                <Container style={{ padding: 0 }}>
                    <Form.Group className="mb-4">
                        <Form.Label>ФИО или название</Form.Label>
                        <Form.Control type="text" {...register('name', { required: true })} placeholder="Введите имя" />
                    </Form.Group>
                    <Row>
                        <Form.Group className="mb-4" as={Col}>
                            <Form.Label>Телефон</Form.Label>
                            <Form.Control type="text" {...register('phone')} placeholder="+7 900 000-00-00" />
                        </Form.Group>
                        <Form.Group className="mb-4" as={Col}>
                            <Form.Label>Электронная почта</Form.Label>
                            <Form.Control type="text" {...register('email')} placeholder="myemail@gmail.com" />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-4" as={Col}>
                            <Form.Label>Примечание (видно клиентам)</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={7}
                                placeholder="Введите текст"
                                {...register('publicComment')}
                            />
                        </Form.Group>
                        <Form.Group className="mb-4" as={Col}>
                            <Form.Label>Заметка (видно только вам)</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={7}
                                placeholder="Введите текст"
                                {...register('privateNote')}
                            />
                        </Form.Group>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Stack direction="horizontal" gap={3}>
                    <Button variant="primary" style={{ width: '200px' }} onClick={handleSubmit(onSubmit)}>
                        {clientData ? 'Сохранить изменения' : 'Создать клиента'}
                    </Button>
                    <Button variant="outline-primary" style={{ width: '150px' }} onClick={() => closeModal()}>
                        Отменить
                    </Button>
                </Stack>
            </Modal.Footer>
        </Modal>
    )
}
