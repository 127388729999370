import React from 'react'
import { Button } from 'react-bootstrap'
import { Trash3, Pencil } from 'react-bootstrap-icons'

import removeFromListDelay from '../../../constants/removeFromListDelay'
import DeleteWarehouseInCloud from '../../../modules/redux/warehouseList/DeleteWarehouseInCloud'
import LoadWarehouseList from '../../../modules/redux/warehouseList/LoadWarehouseList'
import { useDispatch } from '../../../modules/store/customDispatch'
import { storeDispatchTypes } from '../../../modules/store/storeDispatchTypes'
import HoverOverlay from '../HoverOverlay/HoverOverlay'

interface IProps {
    warehouseId: string
    editModal: () => void
}

const CellButtonsWarehouses = ({ warehouseId, editModal }: IProps) => {
    const dispatch = useDispatch()

    const onClickDelete = () => {
        dispatch({
            type: storeDispatchTypes.deleteWarehouseById,
            value: warehouseId,
        })
        dispatch(DeleteWarehouseInCloud(warehouseId))
        setTimeout(() => {
            dispatch(LoadWarehouseList(1, 50))
        }, removeFromListDelay)
    }

    return (
        <>
            <HoverOverlay tooltipText={'Редактировать'}>
                <Button variant="secondary" size="sm" onClick={editModal} className="d-flex align-items-center">
                    <Pencil color={'white'} size={18} />
                </Button>
            </HoverOverlay>
            <HoverOverlay tooltipText={'Удалено'}>
                <Button variant="danger" size="sm" onClick={onClickDelete} className="d-flex align-items-center">
                    <Trash3 color={'white'} size={18} />
                </Button>
            </HoverOverlay>
        </>
    )
}

export default CellButtonsWarehouses
