import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { saveAs } from 'file-saver'

import anchor from '../../../constants/anchor'

import {
    GetClientsListApiArg,
    GetClientsListApiResponse,
    GetDealsListApiArg,
    GetDealsListApiResponse,
    GetDealStagesInfoApiArg,
    GetDealStagesInfoApiResponse,
    GetDealStatusDictApiResponse,
    GetVendorDataApiArg,
    GetVendorDataApiResponse,
    SaveClientApiArg,
    SaveClientApiResponse,
    SaveDealApiArg,
    SaveDealApiResponse,
    UpdateVendorDataApiArg,
    UpdateVendorDataApiResponse,
} from './dochubTypes'

export const BASE_URL = 'https://api.stock-pro.net/dochub'
// export const BASE_URL = 'http://localhost:3002'

const customBaseQuery = async (args: any, api: any, extraOptions: any) => {
    const baseQuery = fetchBaseQuery({ baseUrl: BASE_URL })
    const userId = localStorage.getItem('userId')
    const accessToken = localStorage.getItem('accessToken')
    const authorizationHeader = `${anchor} ${accessToken}`

    // Добавляем headers с Authorization и userId к каждому запросу
    if (typeof args === 'object') {
        args.headers = {
            ...args.headers,
            Authorization: authorizationHeader,
        }

        if (args.body) {
            args.body = { ...args.body, userId }
        }
    }

    return baseQuery(args, api, extraOptions)
}

export const dochubApi = createApi({
    reducerPath: 'dochubApi',
    baseQuery: customBaseQuery,
    endpoints: builder => ({
        getDealsList: builder.mutation<GetDealsListApiResponse, Omit<GetDealsListApiArg, 'userId'>>({
            query: queryArg => ({
                url: `/getDealsList`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        saveDeal: builder.mutation<SaveDealApiResponse, Omit<SaveDealApiArg, 'userId'>>({
            query: queryArg => ({
                url: `/saveDeal`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        getDealStagesInfo: builder.query<GetDealStagesInfoApiResponse, Omit<GetDealStagesInfoApiArg, 'userId'>>({
            query: queryArg => ({
                url: `/getDealStagesInfo`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        getDealStatusDict: builder.query<GetDealStatusDictApiResponse, void>({
            query: queryArg => ({
                url: `/getDealStatusDict`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        saveClient: builder.mutation<SaveClientApiResponse, Omit<SaveClientApiArg, 'userId'>>({
            query: queryArg => ({
                url: `/saveClient`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        getClientsList: builder.query<GetClientsListApiResponse, Omit<GetClientsListApiArg, 'userId'>>({
            query: queryArg => ({
                url: `/getClientsList`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        getVendorData: builder.query<GetVendorDataApiResponse, GetVendorDataApiArg>({
            query: queryArg => ({
                url: `/getVendorData`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        updateVendorData: builder.mutation<UpdateVendorDataApiResponse, UpdateVendorDataApiArg>({
            query: queryArg => ({
                url: `/updateVendorData`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        downloadDealDocument: builder.mutation<Blob, { dealId: string }>({
            query: queryArg => ({
                url: `/downloadDealDocument`,
                method: 'POST',
                body: queryArg,
                cache: 'no-cache',
                responseHandler: (response: Response) => response.blob(),
            }),
            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    const fileName = `${arg.dealId}.pdf`
                    saveAs(new Blob([data], { type: 'application/pdf' }), fileName)
                } catch (error) {
                    console.error('Error downloading the document:', error)
                }
            },
        }),
    }),
})

export const {
    useGetDealsListMutation,
    useSaveDealMutation,
    useGetDealStagesInfoQuery,
    useGetDealStatusDictQuery,
    useSaveClientMutation,
    useGetClientsListQuery,
    useLazyGetClientsListQuery,
    useGetVendorDataQuery,
    useUpdateVendorDataMutation,
    useDownloadDealDocumentMutation,
} = dochubApi
