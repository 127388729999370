import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Form, Modal, Col, Row, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { v4 as uuidV4 } from 'uuid'

import { ToastType, useToast } from '../../../hooks/useToast'
import { useSaveImageInCloudMutation } from '../../../modules/store/datahubApi/datahubApi'
import { useGetVendorDataQuery, useUpdateVendorDataMutation } from '../../../modules/store/dochubApi/dochubApi'
import { useUploadImageToStoreMutation } from '../../../modules/store/imagesApi/imagesApi'
import Loader from '../../_atoms/Loader/Loader'
import OverlayWithText from '../../_atoms/OverlayWithText/OverlayWithText'

import styles from './styles.module.scss'

interface FormData {
    // startingNumber: number
    vendorName: string
    vendorITN: number
    vendorAddress: string
    vendorTelephone: string
    vendorLogoId?: string
    vendorLogoUrl?: string
    vendorWarrantyRestrictions: string
}

interface ComponentProps {
    isOpen: boolean
    closeModal: () => void
}

const ModalDataVendorDocument = ({ isOpen, closeModal }: ComponentProps) => {
    const { register, handleSubmit, setValue, watch } = useForm<FormData>()
    const logoUrl = watch('vendorLogoUrl')

    const userId = useMemo(() => localStorage.getItem('userId')!, [])

    const fileInputRef = useRef<HTMLInputElement | null>(null)

    const {
        data: dataGetVendorData,
        isLoading: isLoadingGetVendorData,
        refetch: refetchVendorData,
    } = useGetVendorDataQuery({ userId })
    const [
        updateVendorData,
        { data: dataUpdateVendorData, isLoading: isLoadingUpdateVendorData, isError: isErrorUpdateVendorData },
    ] = useUpdateVendorDataMutation()
    const [
        uploadImageToStore,
        { data: dataUploadImageToStore, isLoading: isLoadingUploadImageToStore, isError: isErrorUploadImageToStore },
    ] = useUploadImageToStoreMutation()
    const [
        saveImageInCloud,
        { data: dataSaveImageInCloud, isLoading: isLoadingSaveImageInCloud, isError: isErrorSaveImageInCloud },
    ] = useSaveImageInCloudMutation()

    const isLoading = useMemo(
        () =>
            isLoadingUpdateVendorData ||
            isLoadingGetVendorData ||
            isLoadingUploadImageToStore ||
            isLoadingSaveImageInCloud,
        [isLoadingGetVendorData, isLoadingSaveImageInCloud, isLoadingUpdateVendorData, isLoadingUploadImageToStore],
    )

    const onCloseModal = () => {
        closeModal()
    }

    const onSubmit = useCallback(
        (data: FormData) => {
            updateVendorData({
                vendorData: { user_id: userId, extra_data: { ...data, vendorLogoUrl: logoUrl } },
                userId,
            })
        },
        [logoUrl, updateVendorData, userId],
    )

    const handleUploadLogo = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]
        if (!file) return

        uploadImageToStore({
            file,
            userId,
            imageType: 'logo',
        })
    }

    useEffect(() => {
        if (dataUploadImageToStore) {
            const { status, cloudPath, cloudLongUrl, thumpCloudLongUrl } = dataUploadImageToStore
            const id = `logo-${uuidV4()}`
            const createdAt = Date.now()
            saveImageInCloud({
                userId,
                image: {
                    id,
                    userId,
                    index: 0,
                    entityId: userId,
                    entityType: 'logo',
                    cloudPath,
                    cloudLongUrl,
                    createdAt,
                    updatedAt: createdAt,
                },
            })
        }
    }, [dataUploadImageToStore, saveImageInCloud, userId])

    useEffect(() => {
        if (dataUpdateVendorData) {
            closeModal()
        }
    }, [closeModal, dataUpdateVendorData])

    useEffect(() => {
        if (dataGetVendorData) {
            const vendorData = dataGetVendorData.data.extra_data
            // setValue('startingNumber', vendorData.startingNumber)
            setValue('vendorName', vendorData.vendorName)
            setValue('vendorITN', vendorData.vendorITN)
            setValue('vendorAddress', vendorData.vendorAddress)
            setValue('vendorTelephone', vendorData.vendorTelephone)
            setValue('vendorLogoId', vendorData.vendorLogoId)
            setValue('vendorLogoUrl', vendorData.vendorLogoUrl)
            setValue('vendorWarrantyRestrictions', vendorData.vendorWarrantyRestrictions)
        }
    }, [dataGetVendorData, setValue])

    useEffect(() => {
        refetchVendorData()
    }, [refetchVendorData])

    useEffect(() => {
        if (dataSaveImageInCloud && dataUploadImageToStore) {
            setValue('vendorLogoId', dataSaveImageInCloud.id)
            setValue('vendorLogoUrl', dataUploadImageToStore.cloudLongUrl)
        }
    }, [dataSaveImageInCloud, setValue])

    useToast({
        isTriggered: !!dataUpdateVendorData,
        type: ToastType.SUCCESS,
        message: 'Данные для документов успешно сохранены',
    })
    useToast({
        isTriggered: isErrorUpdateVendorData,
        type: ToastType.ERROR,
        message: 'Ошибка сохранения данных для документов',
    })
    useToast({
        isTriggered: !!dataSaveImageInCloud,
        type: ToastType.SUCCESS,
        message: 'Логотип успешно загружен',
    })
    useToast({
        isTriggered: isErrorUploadImageToStore || isErrorSaveImageInCloud,
        type: ToastType.ERROR,
        message: 'Ошибка загрузки логотипа',
    })

    return (
        <>
            <Modal className={styles.modalDataDocument} show={isOpen} onHide={onCloseModal} centered>
                {isLoading && (
                    <OverlayWithText backgroundBootstrapColor={'bg-secondary'}>
                        <Loader diameterInPx={100} thicknessInPx={10} />
                    </OverlayWithText>
                )}
                <Modal.Header closeButton>
                    <Modal.Title>
                        <b>Укажите данные для документов</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {/*<Form.Group as={Row} className="mb-3">*/}
                        {/*    <Form.Label column sm="5">*/}
                        {/*        Стартовый номер*/}
                        {/*    </Form.Label>*/}
                        {/*    <Col sm="7">*/}
                        {/*        <Form.Control type="number" min={0} {...register('startingNumber')} />*/}
                        {/*    </Col>*/}
                        {/*</Form.Group>*/}
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="5">
                                Название продавца
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control type="text" {...register('vendorName')} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="5">
                                ИНН продавца
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control type="number" min={0} {...register('vendorITN')} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="5">
                                Адрес продавца
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control type="text" {...register('vendorAddress')} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="5">
                                Телефон продавца
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control type="text" {...register('vendorTelephone')} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="5">
                                Гарантийные ограничения
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control as="textarea" type="text" {...register('vendorWarrantyRestrictions')} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="5">
                                Логотип продавца
                            </Form.Label>
                            <Col sm="7">
                                {logoUrl && (
                                    <Link to={logoUrl} target="_blank">
                                        Посмотреть лого
                                    </Link>
                                )}
                                <Button
                                    variant="primary"
                                    onClick={() => fileInputRef.current?.click()}
                                    className="w-100"
                                >
                                    Загрузить лого
                                </Button>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    accept="image/*"
                                    onChange={handleUploadLogo}
                                />
                            </Col>
                        </Form.Group>
                        <div className="d-flex justify-content-end">
                            <Button onClick={handleSubmit(onSubmit)}>Сохранить</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalDataVendorDocument
