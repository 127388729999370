import { useEffect } from 'react'

import getAccountCashierInfo from '../../modules/redux/user/getAccountCashierInfo'
import { useDispatch } from '../../modules/store/customDispatch'

export const useGetAccountCashierInfo = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAccountCashierInfo())
    }, [])
}
