import { useEffect, useReducer, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

import changeCarDataForParts from '../../../modules/redux/partList/ChangeCarDataForParts'
import { useDispatch } from '../../../modules/store/customDispatch'
import { useSelector } from '../../../modules/store/customSelector'
import { partReducer } from '../../../pages/NewPart/partReducer'
import { carFields } from '../../../types/car/carTypes'
import { CatalogModification } from '../../../types/common/commonTypes'
import {
    Part,
    partCreateUpdateActionTypes,
    partFields,
    partInput,
    PartListSearchParams,
    partSpecialPropsFields,
} from '../../../types/part/partTypes'
import Loader from '../../_atoms/Loader/Loader'
import OverlayWithText from '../../_atoms/OverlayWithText/OverlayWithText'
import PartCreateUpdateTable from '../PartCreateUpdateTable/PartCreateUpdateTable'

import { initialCarState } from './initialPartState'
import styles from './styles.module.scss'

interface IProps {
    isOpen: boolean
    closeModal: () => void
    parts: Part[]
    confirmChanges: () => void
    partListSearchParams?: PartListSearchParams
}

const ModalChangeCarData = ({ isOpen, closeModal, parts, confirmChanges, partListSearchParams }: IProps) => {
    const dispatchRedux = useDispatch()
    const [state, dispatch] = useReducer(partReducer, initialCarState)
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const filteredModifications = useSelector(state => state.carList.filteredModifications)

    const onCloseModal = () => {
        closeModal()
    }

    const confirm = async () => {
        setIsLoading(true)
        const newCarData = await getApiObjectFromState()
        if (newCarData) {
            await dispatchRedux(
                changeCarDataForParts(
                    parts.map(part => part.id),
                    newCarData,
                    partListSearchParams,
                ),
            )
            confirmChanges()
            onCloseModal()
        }
        setIsLoading(false)
    }

    const getField = (fieldToSearch: carFields | partFields) =>
        state.fields.find((field: partInput) => field.field === fieldToSearch)
    const getBodyTypeId = (modificationId: number) =>
        Number(
            filteredModifications.find(
                (modificationObject: CatalogModification) => Number(modificationObject.id) === modificationId,
            )?.bodyTypeId,
        )

    const setIsDisabledFields = () =>
        state.fields.map((field: partInput) => {
            const selectSearchText = field.specialProps?.renderValue
            const errorMessage = field.value ? '' : field.specialProps?.errorMessage

            if (field.specialProps?.fieldParent) {
                const fieldParentValue = getField(field.specialProps.fieldParent).value
                const fieldParentValueInChild = field.specialProps?.[partSpecialPropsFields.fieldParentValue]
                const isEnabled = fieldParentValue
                const isParentChanged =
                    fieldParentValueInChild &&
                    fieldParentValue !== fieldParentValueInChild &&
                    !field.specialProps.isAdded

                return {
                    ...field,
                    value: isParentChanged ? '' : field.value,
                    specialProps: {
                        ...field.specialProps,
                        [partSpecialPropsFields.renderValue]: isParentChanged ? '' : selectSearchText,
                        [partSpecialPropsFields.isDisabled]: !isEnabled,
                        [partSpecialPropsFields.fieldParentValue]: isEnabled ? fieldParentValue : '',
                        [partSpecialPropsFields.errorMessage]: isEnabled ? errorMessage : '',
                    },
                }
            } else {
                return field
            }
        })

    const getIsSubmitDisabled = () => {
        let isDisabled = false
        state.fields.forEach((field: partInput) => {
            if (field?.specialProps?.errorMessage) {
                isDisabled = true
            }
        })
        setIsSubmitDisabled(isDisabled)
    }

    const getApiObjectFromState = async () => {
        let apiObject = {
            markId: 0,
            modelId: 0,
            modificationId: 0,
            year: 0,
            bodyTypeId: 0,
        }
        let isObjectReady = true

        state.fields.map((item: partInput) => {
            if (item.value && !item?.specialProps?.errorMessage && !item?.specialProps?.extraHandle) {
                apiObject = { ...apiObject, [item.field]: item.value }
            } else if (item?.specialProps?.isRequired && !item.value) {
                dispatch({
                    type: partCreateUpdateActionTypes.updateSpecialProps,
                    value: {
                        field: item.field,
                        specialProps: {
                            ...item.specialProps,
                            [partSpecialPropsFields.errorMessage]: 'поле обязательно к заполнению',
                        },
                    },
                })
                isObjectReady = false
            } else if (item?.specialProps?.errorMessage) {
                isObjectReady = false
            }
        })

        if (isObjectReady) {
            apiObject.bodyTypeId = getBodyTypeId(apiObject.modificationId)
            return apiObject
        } else {
            setIsSubmitDisabled(true)
        }
    }

    useEffect(() => {
        getIsSubmitDisabled()
        dispatch({
            type: partCreateUpdateActionTypes.initializeState,
            value: { state: { fields: setIsDisabledFields() } },
        })
    }, [...state.fields.map((field: partInput) => field.value)])

    return (
        <Modal show={isOpen} onHide={onCloseModal} centered dialogClassName={styles.modal}>
            {isLoading && (
                <OverlayWithText backgroundBootstrapColor={'bg-secondary'}>
                    <Loader diameterInPx={100} thicknessInPx={10} />
                </OverlayWithText>
            )}
            <Modal.Header closeButton>
                <Modal.Title>
                    <b>Изменить марка/модель/поколение/модификация</b>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PartCreateUpdateTable dispatch={dispatch} fields={state.fields} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={confirm} disabled={isSubmitDisabled}>
                    Сохранить
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalChangeCarData
