import React, { useState } from 'react'
import { Image } from 'react-bootstrap-icons'
import { ReactSortable } from 'react-sortablejs'

import { PartImage } from '../../../types/part/partTypes'
import ModalCarousel from '../ModalCarousel/ModalCarousel'
import ModalImageThumb from '../ModalImageThumb/ModalImageThumb'

import styles from './styles.module.scss'

interface IProps {
    images: PartImage[]
    setImages?: (images: PartImage[]) => void
    markImg?: (id: string) => void
    unmarkImg?: (id: string) => void
    showMainImg?: boolean
}

const ModalImageGroup = ({ images, setImages, markImg, unmarkImg, showMainImg = true }: IProps) => {
    const [activeImageIndex, setActiveImageIndex] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false)

    const setActiveImage = (index: number) => {
        setActiveImageIndex(index)
        setIsModalOpen(true)
    }

    return (
        <div className={styles.modalImageGroup__wrap}>
            {images.length === 0 && showMainImg ? (
                <div className={styles.modalImageGroup__placeholder}>
                    <Image color={'#ced4da'} size={180} />
                    <p>Добавьте фотографии запчасти, чтобы видеть их тут</p>
                </div>
            ) : (
                <>
                    {showMainImg && (
                        <div className={styles.modalImageGroup__main}>
                            <img src={images[activeImageIndex].publicUrl} alt={images[activeImageIndex].id} />
                        </div>
                    )}
                    <div className={styles.modalImageGroup__all}>
                        {setImages ? (
                            <ReactSortable list={images} setList={setImages} className={'justify-content-start'}>
                                {images.map((image, index) => (
                                    <div className={styles.imageWrap} key={image.id}>
                                        <ModalImageThumb
                                            key={image.id}
                                            markImg={markImg && markImg}
                                            unmarkImg={unmarkImg && unmarkImg}
                                            setActiveImageIndex={setActiveImage}
                                            index={index}
                                            id={image.id}
                                            publicUrl={image.publicUrl}
                                        />
                                    </div>
                                ))}
                            </ReactSortable>
                        ) : (
                            <div>
                                {images.map((image, index) => (
                                    <div className={styles.imageWrap} key={image.id}>
                                        <ModalImageThumb
                                            key={image.id}
                                            setActiveImageIndex={setActiveImage}
                                            index={index}
                                            id={image.id}
                                            publicUrl={image.publicUrl}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </>
            )}
            {!showMainImg && (
                <ModalCarousel
                    closeModal={() => setIsModalOpen(false)}
                    pictures={images}
                    isOpen={isModalOpen}
                    pictureIndex={activeImageIndex}
                />
            )}
        </div>
    )
}

export default ModalImageGroup
