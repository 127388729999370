import React, { useCallback, useEffect, useState } from 'react'
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import Badge from '../../components/_atoms/Badge/Badge'
import ListNoItems from '../../components/_atoms/ListNoItems/ListNoItems'
import ListPagination from '../../components/_atoms/ListPagination/ListPagination'
import ListSkeleton from '../../components/_atoms/ListSkeleton/ListSkeleton'
import SalesText from '../../components/_atoms/SalesText/SalesText'
import { ModalSaveClient } from '../../components/_molecules/ModalSaveClient/ModalSaveClient'
import SearchComponentRTK from '../../components/_molecules/SearchComponent/SearchComponentRTK'
import { fontSizes, fontWeights } from '../../constants/salesStyles'
import { ToastType, useToast } from '../../hooks/useToast'
import { useGetClientsListQuery } from '../../modules/store/dochubApi/dochubApi'
import { Client, ClientsSearchParams } from '../../modules/store/dochubApi/dochubTypes'
import { adminRouteAlias, getAdminNavigationPath } from '../../router/adminRouteAlias'
import { formatDate } from '../../utils/converters'
import { colWidth } from '../../utils/stylePatches'

import styles from './styles.module.scss'

const ClientList = () => {
    const navigate = useNavigate()

    const [isModalSaveClientOpen, setModalSaveClientOpen] = useState(false)
    const [clientToEdit, setClientToEdit] = useState<Client | null>(null)

    const [searchParams, setSearchParams] = useSearchParams()
    const searchPage = Number(searchParams.get('page')) || 1
    const searchQuery = searchParams.get('search') || ''

    const [filters, setFilters] = useState<ClientsSearchParams>({
        pagination: {
            page: searchPage,
            perPage: 50,
        },
        search: searchQuery,
    })
    const {
        pagination: { page, perPage },
        search,
    } = filters

    const {
        data,
        isFetching: isLoadingGetClientsList,
        isError: isErrorGetClientsList,
        refetch,
    } = useGetClientsListQuery(
        {
            params: {
                pagination: { page, perPage },
                search,
            },
        },
        {
            refetchOnMountOrArgChange: true,
        },
    )

    // Извлекаем массив логов и общее количество
    const clientsList = data?.data?.filter(item => item.createdAt !== 0) || []
    const clientsCount = data?.dataCount || 0

    const setFiltersAndUpdateUrl = (newFilters: ClientsSearchParams) => {
        // Обновляем состояние фильтров
        setFilters(newFilters)

        // Обновляем URL с новыми параметрами
        const newParams = new URLSearchParams()

        // Сохраняем параметры фильтров
        if (newFilters.pagination.page > 1) {
            newParams.set('page', String(newFilters.pagination.page))
        }
        if (newFilters.search) {
            newParams.set('search', newFilters.search)
        }

        // Обновляем URL без перезаписи истории
        navigate(
            {
                pathname: getAdminNavigationPath(adminRouteAlias.clients.location),
                search: newParams.toString(), // Все параметры фильтров
            },
            { replace: false },
        ) // Это сохраняет текущий URL в истории
    }

    const setPage = (newPage: number) => {
        setFiltersAndUpdateUrl({
            ...filters,
            pagination: { ...filters.pagination, page: newPage },
        })
    }

    const setSearchTerm = (newTerm: string) => {
        setFiltersAndUpdateUrl({
            ...filters,
            search: newTerm,
            pagination: { ...filters.pagination, page: 1 },
        })
    }

    const renderListPagination = useCallback(() => {
        if (!isLoadingGetClientsList && clientsCount === 0) return null

        return (
            <div>
                <ListPagination
                    page={page}
                    setPage={setPage}
                    perPage={perPage}
                    itemsCount={clientsCount}
                    justifyContent="flex-end"
                />
            </div>
        )
    }, [clientsCount, isLoadingGetClientsList, page])

    // Обновление фильтров при изменении URL (например, при нажатии "Назад")
    useEffect(() => {
        setFilters(prev => ({
            ...prev,
            pagination: {
                ...prev.pagination,
                page: searchPage,
            },
            search: searchQuery,
        }))
    }, [searchPage, searchQuery])

    const openModalForEditing = (client: Client) => {
        setClientToEdit(client)
        setModalSaveClientOpen(true)
    }

    useToast({ isTriggered: isErrorGetClientsList, type: ToastType.ERROR, message: 'Ошибка загрузки клиентов' })

    const renderEllipsisCell = (text?: string) => (
        <OverlayTrigger placement="left" overlay={text ? <Tooltip>{text}</Tooltip> : <></>}>
            <span className={styles.ellipsis}>{text}</span>
        </OverlayTrigger>
    )

    return (
        <div className={styles.wrap}>
            <ToastContainer position="bottom-center" autoClose={5000} />
            {isModalSaveClientOpen && (
                <ModalSaveClient
                    isOpen={isModalSaveClientOpen}
                    closeModal={() => setModalSaveClientOpen(false)}
                    onSuccess={refetch}
                    clientData={clientToEdit}
                />
            )}

            <div className={styles.rowWrap}>
                <SalesText
                    text={'Клиенты'}
                    fontWeight={fontWeights.bold}
                    fontSize={fontSizes.xxl}
                    letterSpacing={-0.02}
                />
                <Button variant="primary" className={styles.addButton} onClick={() => setModalSaveClientOpen(true)}>
                    + Добавить Клиента
                </Button>
            </div>
            <div>
                <SearchComponentRTK
                    searchTerm={filters.search}
                    setSearchTerm={setSearchTerm}
                    placeholder="Поиск по клиентам"
                />
            </div>
            {renderListPagination()}
            <Table striped hover>
                <thead>
                    <tr>
                        <th scope="col" style={colWidth(100)}>
                            Номер
                        </th>
                        <th scope="col" style={colWidth(100)}>
                            Дата
                        </th>
                        <th scope="col" style={colWidth(150)}>
                            Название
                        </th>
                        <th scope="col" style={colWidth(150)}>
                            Телефон
                        </th>
                        <th scope="col" style={colWidth(150)}>
                            Email
                        </th>
                        <th scope="col" style={colWidth(100)}>
                            Примечание
                        </th>
                        <th scope="col" style={colWidth(100)}>
                            Заметка
                        </th>
                    </tr>
                </thead>
                {isLoadingGetClientsList ? (
                    <ListSkeleton rowCount={9} columnCount={7} marginVerticalRem={0.21} />
                ) : (
                    clientsList && (
                        <tbody>
                            {clientsList.map((client, index) => (
                                <tr key={index} className={styles.row} onClick={() => openModalForEditing(client)}>
                                    <td scope="row">{client.code}</td>
                                    <td scope="row">
                                        <Badge text={formatDate(client.createdAt)} color="#ddd" fontColor="#555" />
                                    </td>
                                    <td scope="row">{client.name}</td>
                                    <td scope="row">{client.phone}</td>
                                    <td scope="row">{client.email}</td>
                                    <td scope="row" className={styles.ellipsis}>
                                        {renderEllipsisCell(client.meta?.publicComment)}
                                    </td>
                                    <td scope="row" className={styles.ellipsis}>
                                        {renderEllipsisCell(client.meta?.privateNote)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    )
                )}
            </Table>
            {!isLoadingGetClientsList && (clientsCount === 0 || !clientsList.length) ? (
                <ListNoItems resetFilters={() => navigate(getAdminNavigationPath(adminRouteAlias.clients.location))} />
            ) : (
                renderListPagination()
            )}
        </div>
    )
}

export default ClientList
