import { useState, KeyboardEvent, useRef, useEffect } from 'react'
import { Form, Overlay, Tooltip } from 'react-bootstrap'

import setPartDeletedStatus from '../../../modules/redux/partList/SetPartDeletedStatus'
import setPartQuantity from '../../../modules/redux/partList/SetPartQuantity'
import { useDispatch } from '../../../modules/store/customDispatch'
import { Part } from '../../../types/part/partTypes'

interface IProps {
    part: Part
}

const CellQuantityParts = ({ part }: IProps) => {
    const dispatch = useDispatch()
    const [quantity, setQuantity] = useState(part.extraData?.qty ? part.extraData?.qty : 1)
    const [showOverlay, setShowOverlay] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const inputRef = useRef(null)

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (
            !((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'Delete' || e.key.includes('Arrow'))
        ) {
            e.preventDefault()
        }
        if (e.key === 'Enter') {
            if (quantity == 0) {
                dispatch(setPartDeletedStatus(part.id))
            } else {
                const inputElement = e.target
                if (inputElement instanceof HTMLInputElement) {
                    inputElement.blur()
                }
            }
        }
    }

    const handleBlur = async () => {
        if (quantity == part.extraData?.qty) return
        setIsLoading(true)
        const result = await dispatch(setPartQuantity(part.id, quantity))
        if (!result) {
            setShowOverlay(true)
            setTimeout(() => {
                setShowOverlay(false)
            }, 5000)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        setQuantity(part.extraData?.qty ? part.extraData?.qty : 1)
    }, [part.extraData?.qty])

    return (
        <Form>
            <Form.Control
                value={quantity}
                size="sm"
                type="text"
                onKeyDown={handleKeyDown}
                onChange={e => setQuantity(Number(e.target.value))}
                onBlur={handleBlur}
                ref={inputRef}
                disabled={isLoading}
                placeholder="Кол."
            />

            <Overlay target={inputRef.current} show={showOverlay} placement="top">
                {props => (
                    <Tooltip id="overlay-error" {...props}>
                        Не удалось сохранить информацию. Обновите страницу и попробуйте еще раз
                    </Tooltip>
                )}
            </Overlay>
        </Form>
    )
}

export default CellQuantityParts
