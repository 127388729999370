import { useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'

import { fontSizes, fontWeights } from '../../../constants/salesStyles'
import LoadWarehouseList from '../../../modules/redux/warehouseList/LoadWarehouseList'
import saveWarehouse from '../../../modules/redux/warehouseList/SaveWarehouse'
import { useDispatch } from '../../../modules/store/customDispatch'
import { Warehouse } from '../../../types/warehouse/warehouseTypes'
import Loader from '../../_atoms/Loader/Loader'
import OverlayWithText from '../../_atoms/OverlayWithText/OverlayWithText'
import SalesText from '../../_atoms/SalesText/SalesText'
import SalesDescriptionInput from '../SalesDescriptionInput'

import styles from './styles.module.scss'

interface IProps {
    isOpen: boolean
    closeModal: () => void
}

const ModalAddWarehouse = ({ isOpen, closeModal }: IProps) => {
    const dispatch = useDispatch()
    const [newWarehouse, setNewWarehouse] = useState<Warehouse>({
        id: '',
        warehouseId: '',
        userId: '',
        title: '',
        address: '',
        phone: '',
        vendorPrefix: '',
        isDeleted: false,
        createdAt: 0,
        updatedAt: 0,
        descTemplate: '',
    })

    const [validationErrors, setValidationErrors] = useState({
        title: '',
        address: '',
        phone: '',
        vendorPrefix: '',
    })
    const [isLoading, setIsLoading] = useState(false)
    const [isConfirmDisabled, setIsConfirmDisabled] = useState(false)
    const [isChanged, setIsChanged] = useState(false)

    const handleValidation = () => {
        const errors = {
            title: '',
            address: '',
            phone: '',
            vendorPrefix: '',
        }

        let formIsValid = true

        if (!newWarehouse.title) {
            formIsValid = false
            errors.title = 'Введите название склада'
        }

        setValidationErrors(errors)

        return formIsValid
    }

    const setWarehouseTitle = (title: string) => {
        setIsChanged(true)
        setIsConfirmDisabled(false)
        setValidationErrors({ ...validationErrors, title: '' })
        setNewWarehouse({ ...newWarehouse, title })
    }

    const setWarehouseAddress = (address: string) => {
        setIsChanged(true)
        setIsConfirmDisabled(false)
        setValidationErrors({ ...validationErrors, address: '' })
        setNewWarehouse({ ...newWarehouse, address })
    }

    const setWarehousePhone = (phone: string) => {
        const phoneRegExp = /^[\+]?[0-9]{0,3}[-\s]?[(]?[0-9]{1,4}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{2}[-\s\.]?[0-9]{2}$/ // eslint-disable-line
        const isValidPhone = phoneRegExp.test(phone)
        setIsChanged(true)
        setIsConfirmDisabled(!isValidPhone)

        // add '+' to phone number if it's not there
        if (phone[0] !== '+' && phone.length > 0) {
            phone = '+' + phone
        }

        setNewWarehouse({ ...newWarehouse, phone })
    }

    const setWarehouseVendorPrefix = (vendorPrefix: string) => {
        const vendorPrefixRegExp = /^[A-Z]{0,4}$/
        const isValidVendorPrefix = vendorPrefixRegExp.test(vendorPrefix)
        setIsChanged(true)
        setIsConfirmDisabled(!isValidVendorPrefix)
        if (!isValidVendorPrefix) {
            setValidationErrors({
                ...validationErrors,
                vendorPrefix: 'Префикс может состоять только из 4х заглавных букв',
            })
        } else {
            setValidationErrors({ ...validationErrors, vendorPrefix: '' })
        }
        setNewWarehouse({ ...newWarehouse, vendorPrefix })
    }

    const onCloseModal = () => {
        closeModal()
    }

    const confirmChange = () => {
        ;(async () => {
            if (handleValidation()) {
                setIsLoading(true)

                dispatch(saveWarehouse(newWarehouse))

                setTimeout(() => {
                    dispatch(LoadWarehouseList(1, 50))
                }, 1000)

                closeModal()
                setIsLoading(false)
            } else {
                setIsConfirmDisabled(true)
            }
        })()
    }

    return (
        <>
            <Modal show={isOpen} onHide={onCloseModal} size="lg">
                {isLoading && (
                    <OverlayWithText backgroundBootstrapColor={'bg-secondary'}>
                        <Loader diameterInPx={100} thicknessInPx={10} />
                    </OverlayWithText>
                )}
                <Modal.Header closeButton>
                    <Modal.Title>Добавление склада</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className={'mt-1 me-5'}>
                            <Col md={8}>
                                <Form.Group className="mb-3 mt-1">
                                    <Form.Label className={`${styles.modalEditPart__formLabel}`}>
                                        Название склада *
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        defaultValue={''}
                                        placeholder={'Введите название склада'}
                                        maxLength={50}
                                        required
                                        onChange={e => setWarehouseTitle(e.target.value)}
                                        className={`shadow-none`}
                                        isInvalid={!!validationErrors.title}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validationErrors.title}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={8}>
                                <Form.Group className="mb-3 mt-1">
                                    <Form.Label className={`${styles.modalEditPart__formLabel}`}>
                                        Адрес склада
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        defaultValue={''}
                                        placeholder={'Введите адрес склада'}
                                        maxLength={50}
                                        onChange={e => setWarehouseAddress(e.target.value)}
                                        className={`shadow-none`}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validationErrors.address}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={8}>
                                <Form.Group className="mb-3 mt-1">
                                    <Form.Label className={`${styles.modalEditPart__formLabel}`}>
                                        Телефон склада
                                    </Form.Label>
                                    <Form.Control
                                        type="phone"
                                        defaultValue={''}
                                        placeholder={'Введите номер телефона склада'}
                                        onChange={e => setWarehousePhone(e.target.value)}
                                        className={`shadow-none`}
                                        isInvalid={!!validationErrors.phone}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validationErrors.phone}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={8}>
                                <Form.Group className="mb-3 mt-1">
                                    <Form.Label className={`${styles.modalEditPart__formLabel}`}>
                                        Префикс склада
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        defaultValue={''}
                                        placeholder={'Введите префикс склада'}
                                        maxLength={4}
                                        onChange={e => setWarehouseVendorPrefix(e.target.value)}
                                        className={`shadow-none`}
                                        isInvalid={!!validationErrors.vendorPrefix}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validationErrors.vendorPrefix}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={8}>
                                <Form.Group className="mb-3 mt-1">
                                    <SalesText
                                        text="Дополнительное описание для всех объявлений складa"
                                        fontSize={fontSizes.xs}
                                        fontWeight={fontWeights.bold}
                                    />
                                    <SalesDescriptionInput
                                        title="Этот текст добавляет описаниям во всех объявлений складa"
                                        marginTopPixels={12}
                                        width="100%"
                                        errorMessage={'Превышен лимит'}
                                        fontSize={fontSizes.xxs}
                                        value={newWarehouse.descTemplate}
                                        onChangeTextArea={value =>
                                            setNewWarehouse({
                                                ...newWarehouse,
                                                descTemplate: value,
                                            })
                                        }
                                        placeholder={'Введите описание склада'}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {isChanged ? (
                        <Button variant="primary" onClick={confirmChange} disabled={isConfirmDisabled}>
                            Сохранить изменения
                        </Button>
                    ) : (
                        <Button variant="secondary" onClick={onCloseModal}>
                            Закрыть
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalAddWarehouse
