import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react'

import { Dispatch as DispatchRedux } from 'redux'

import { colors, fontSizes } from '../../../constants/salesStyles'
import { useDispatch } from '../../../modules/store/customDispatch'
import { RootState } from '../../../modules/store/rootReducer'
import { KeyboardEventLocal, SelectOptionLocal } from '../../../types/common/commonTypes'
import SalesSelectOptions from '../SalesSelectOptions/SalesSelectOptions'
import SalesSelectSearchSuffix from '../SalesSelectSearchSuffix/SalesSelectSearchSuffix'
import SalesTextEditable from '../SalesTextEditable/SalesTextEditable'

import styles from './styles.module.scss'

interface IProps {
    renderValue: string
    onChangeValue: (newValue: string | number, newName: string, option: SelectOptionLocal) => void
    onChangeManyValues?: (
        newValue: string | number,
        newName: string,
        secondValue?: number | string,
        secondName?: string,
    ) => void
    onChangeRenderValue: (newValue: string) => void
    isEditable: boolean
    getSuggestions?: (
        searchTerm: string,
        filterId?: string,
    ) => (dispatch: DispatchRedux, getState: () => RootState) => Promise<void>
    suggestions: SelectOptionLocal[]
    allowEmptySearch?: boolean
    isLoading: boolean
    maxOptions?: number
    placeholder?: string
    disableAutoFocus?: boolean
    isDisabled?: boolean
    filterId?: string
    errorMessage?: string
    validateRenderValue?: (textValue: string, suggestions: SelectOptionLocal[], errorMessage: string) => void
    widthInPixelsOptions?: number
    onSubmit?: () => void
    delay?: number
    onClose?: () => void
    value?: string | number
    color?: colors
    colorHover?: colors
    backgroundColor?: colors
    backgroundColorHover?: colors
    inputBackgroundColor?: colors
    inputPaddingRight?: number
    suffixColor?: colors
    selectTop?: number
    selectPaddingTop?: number
    selectPaddingBottom?: number
    minSearchLength?: number
    suffixElement?: ReactElement
    searchEnabled?: boolean
    hideSearchSuffix?: boolean
    isDisableFindCurrentOption?: boolean
    optionBeforeText?: JSX.Element
}

const SalesSelectSearch = ({
    renderValue,
    onChangeValue,
    onChangeManyValues,
    onChangeRenderValue,
    isEditable,
    getSuggestions,
    suggestions,
    allowEmptySearch = false,
    isLoading,
    maxOptions = 9999,
    placeholder,
    disableAutoFocus,
    isDisabled,
    filterId,
    errorMessage,
    validateRenderValue,
    widthInPixelsOptions,
    onSubmit,
    delay,
    onClose,
    value,
    color,
    colorHover,
    backgroundColor,
    backgroundColorHover,
    inputBackgroundColor,
    inputPaddingRight,
    suffixColor,
    selectTop,
    selectPaddingTop,
    selectPaddingBottom,
    minSearchLength = 0,
    suffixElement,
    searchEnabled = true,
    hideSearchSuffix = false,
    isDisableFindCurrentOption,
    optionBeforeText,
}: IProps) => {
    const [isOpen, setIsOpen] = useState(false)
    const dispatchLocal = useDispatch()
    const divRef = useRef<HTMLDivElement | null>(null)
    const timeoutRef = useRef<NodeJS.Timeout | null>(null)
    const [currentOption, setCurrentOption] = useState(0)
    // Чтобы предотвратить проверку, пока пользователь сам не откроет поиск
    const [firstOpened, setFirstOpened] = useState(false)
    const [localSuggestions, setLocalSuggestions] = useState(suggestions)
    const findCurrentOption = useCallback(
        () =>
            localSuggestions.findIndex(
                suggestion => suggestion.name.toLocaleLowerCase() === renderValue.toLocaleLowerCase(),
            ),
        [localSuggestions, renderValue],
    )

    const updateField = (option: SelectOptionLocal) => {
        const { value, name, subValue, subName } = option
        if (onChangeManyValues) {
            onChangeManyValues(value, name, subValue, subName)
        } else {
            onChangeValue(value, name, option)
        }
        getSuggestionsLocal(name)
    }

    const getSuggestionsLocal = (newValue: string, preventSearch?: boolean) => {
        if (!newValue?.length && !allowEmptySearch) return
        if (preventSearch || newValue?.length < minSearchLength || !searchEnabled) return

        if (getSuggestions) {
            if (filterId) {
                dispatchLocal(getSuggestions(newValue, filterId))
            } else {
                dispatchLocal(getSuggestions(newValue))
            }
        } else {
            setLocalSuggestions(
                suggestions.filter(suggestion =>
                    suggestion.name.toLocaleLowerCase().includes(newValue.toLocaleLowerCase()),
                ),
            )
        }
    }

    const updateRenderValue = (newValue: string, preventSearch?: boolean) => {
        onChangeRenderValue(newValue)

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }
        timeoutRef.current = setTimeout(() => {
            if (newValue !== renderValue) {
                getSuggestionsLocal(newValue, preventSearch)
            }
        }, delay)
    }

    const nextOption = () => {
        if (currentOption === maxOptions - 1 || currentOption === localSuggestions.length - 1) {
            return
        }
        if (isOpen) {
            updateRenderValue(localSuggestions[currentOption + 1].name, true)
            setCurrentOption(currentOption + 1)
        }
    }

    const prevOption = () => {
        if (currentOption == 0) {
            return
        }
        if (isOpen) {
            updateRenderValue(localSuggestions[currentOption - 1].name, true)

            setCurrentOption(currentOption - 1)
        }
    }

    const keyHandler = (event: KeyboardEventLocal) => {
        switch (true) {
            case event.code === 'ArrowDown':
                nextOption()
                break

            case event.code === 'ArrowUp':
                prevOption()
                break

            case !isDisabled && (event.code === 'Space' || event.code === 'Backspace' || event.code === 'Delete'):
                setIsOpen(true)
                break

            case event.code === 'Enter' || event.code === 'NumpadEnter':
                if (isOpen && currentOption !== -1) {
                    updateField(localSuggestions[currentOption])
                    setIsOpen(false)
                } else {
                    setIsOpen(true)
                }
                break

            case event.code === 'Escape':
                setIsOpen(false)
                break

            default:
                if (isEditable && !isOpen && !isDisabled) {
                    setIsOpen(true)
                }
                return
        }
    }

    useEffect(() => {
        setLocalSuggestions(suggestions)
    }, [suggestions])

    useEffect(() => {
        if (isOpen && renderValue.length === 0) {
            getSuggestionsLocal('')
        } else if (
            isOpen &&
            renderValue.length > 0 &&
            !localSuggestions.find(suggestion => suggestion.name === renderValue)
        ) {
            getSuggestionsLocal(renderValue)
        }
    }, [isOpen])

    useEffect(() => {
        if (isEditable && !isDisabled && !disableAutoFocus) {
            setIsOpen(true)
        }
    }, [isEditable])

    useEffect(() => {
        if (isOpen && !isDisableFindCurrentOption) {
            setCurrentOption(findCurrentOption())
        }
    }, [findCurrentOption, isDisableFindCurrentOption, isOpen])

    useEffect(() => {
        const handleClickOutside = (event: Event) => {
            if (divRef.current && !divRef.current.contains(event.target as Node)) {
                if (onClose) onClose()
                setIsOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [divRef])

    useEffect(() => {
        if (!isOpen && validateRenderValue && firstOpened) {
            validateRenderValue(renderValue, localSuggestions, 'выберите значение из списка')
        }
    }, [renderValue, isOpen, isEditable])

    useEffect(() => {
        if (isOpen && !firstOpened) setFirstOpened(true)
    }, [firstOpened, isOpen])

    return (
        <div
            ref={divRef}
            onKeyDown={keyHandler}
            tabIndex={0}
            onClick={() => {
                if (isEditable && !isOpen && !isDisabled) {
                    setIsOpen(true)
                }
            }}
            className={styles.wrap}
        >
            <SalesTextEditable
                renderValue={renderValue}
                placeholder={placeholder}
                onChangeValue={updateRenderValue}
                isEditable={isEditable}
                disableAutoFocus={disableAutoFocus}
                isDisabled={isDisabled}
                errorMessage={errorMessage}
                inputPaddingRight={inputPaddingRight}
                suffixElement={
                    <>
                        {suffixElement}
                        {!hideSearchSuffix && (
                            <SalesSelectSearchSuffix
                                text={renderValue}
                                isLoading={isLoading}
                                clearText={() => updateField({ value: '', name: '', subValue: '', subName: '' })}
                                color={suffixColor}
                            />
                        )}
                    </>
                }
                onSubmit={onSubmit}
                backgroundColor={inputBackgroundColor}
            />
            {!isOpen || !searchEnabled ? null : (
                <SalesSelectOptions
                    hideOptions={() => setIsOpen(false)}
                    noOptionsText={
                        !allowEmptySearch && renderValue.length < minSearchLength
                            ? 'введите запрос'
                            : 'ничего не найдено'
                    }
                    options={localSuggestions}
                    updateField={updateField}
                    maxOptions={maxOptions}
                    fontSize={fontSizes.xs}
                    fontSizeSubName={fontSizes.xxxs}
                    currentOption={currentOption}
                    widthInPixels={widthInPixelsOptions}
                    color={color}
                    colorHover={colorHover}
                    backgroundColor={backgroundColor}
                    backgroundColorHover={backgroundColorHover}
                    top={selectTop}
                    paddingTop={selectPaddingTop}
                    paddingBottom={selectPaddingBottom}
                    showOptions={renderValue.length >= minSearchLength}
                    optionBeforeText={optionBeforeText}
                />
            )}
        </div>
    )
}

export default SalesSelectSearch
