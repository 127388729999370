import { configureStore } from '@reduxjs/toolkit'

import { atolApi } from './atolApi/atolApi'
import { datahubApi } from './datahubApi/datahubApi'
import { dochubApi } from './dochubApi/dochubApi'
import { imagesApi } from './imagesApi/imagesApi'
import rootReducer from './rootReducer'

// Redux: Store
export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(
            dochubApi.middleware,
            datahubApi.middleware,
            atolApi.middleware,
            imagesApi.middleware,
        ),
})
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
