import { useCallback, useEffect, useState } from 'react'
import { CameraFill } from 'react-bootstrap-icons'
// import { FileUploader } from "react-drag-drop-files"
import { useDropzone } from 'react-dropzone'
import { ReactSortable } from 'react-sortablejs'

import classNames from 'classnames'
import heic2any from 'heic2any'

import { colors } from '../../../constants/salesStyles'
import { isArrayOfTypeFile } from '../../../pages/NewPart/partReducer'
import { Image, partInput } from '../../../types/part/partTypes'
import Loader from '../../_atoms/Loader/Loader'
import ModalCarousel from '../../_atoms/ModalCarousel/ModalCarousel'
import OverlayWithText from '../../_atoms/OverlayWithText/OverlayWithText'
import SalesText from '../../_atoms/SalesText/SalesText'
import SalesThumbPreview from '../../_atoms/SalesThumbPreview/SalesThumbPreview'

import styles from './styles.module.scss'

interface IProps {
    input: partInput
    handleAddImages: (file: File[]) => void
    removeFile: (index: number) => void
    handleImagesReorder: (images: File[]) => void
}

const SalesUploadImages = ({ input, handleAddImages, removeFile, handleImagesReorder }: IProps) => {
    const { value, specialProps } = input
    const fileTypes = {
        'image/jpg': ['.jpg'],
        'image/jpeg': ['.jpeg'],
        'image/png': ['.png'],
        'image/heic': ['.heic'],
    }

    const files = value
    const isShowPreviews = Array.isArray(files) && files.length > 0 && isArrayOfTypeFile(files)
    const isDisabled = isShowPreviews && files.length >= 10

    const [imagesToReorder, setImagesToReorder] = useState<Image[]>([])

    const handleImageReorder = () => {
        handleImagesReorder(imagesToReorder.map(image => image.file))
    }

    const onSortableChange = (sortedImages: Image[]) => {
        setImagesToReorder(sortedImages)
    }

    const [activeImageIndex, setActiveImageIndex] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const setActiveImage = (index: number) => {
        setActiveImageIndex(index)
        setIsModalOpen(true)
    }

    const handleFileUpload = async (acceptedFiles: File[]) => {
        setIsLoading(true)
        for (const file of acceptedFiles) {
            let convertedFile = file
            if (file.type === 'image/heic') {
                const converted = await heic2any({ blob: file, toType: 'image/jpeg' })
                convertedFile = new File([converted as Blob], file.name.replace(/\..+$/, '.jpeg'), {
                    type: 'image/jpeg',
                })
            }
            handleAddImages([convertedFile])
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (Array.isArray(files) && isArrayOfTypeFile(files)) {
            const sortableFiles = files.map((file, index) => ({
                id: file.name,
                file: file,
                index: index,
            }))
            setImagesToReorder(sortableFiles)
        }
    }, [files])

    const onDrop = useCallback((acceptedFiles: File[]) => {
        handleFileUpload(acceptedFiles)
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: fileTypes,
        multiple: true,
        disabled: isDisabled || isLoading,
        onDropRejected: () => console.error('Формат файла не поддерживается.'),
    })

    return (
        <div className={styles.wrap}>
            {isDisabled ? (
                <div className={`${styles.dropAreaWrap} ${styles.disabled}`}>
                    <SalesText text={'Не более 10 фотографий'} />
                </div>
            ) : (
                <div
                    {...getRootProps({ className: classNames(styles.dropAreaWrap, { [styles.disabled]: isLoading }) })}
                >
                    <input {...getInputProps()} />
                    <SalesText text={specialProps?.renderValue || 'add button title to RENDER_VALUE'} />
                    <div className={styles.iconWrap}>
                        <CameraFill color={colors.blueDark} size={18} />
                    </div>
                </div>
            )}

            <>
                <div className={styles.thumbsWrap}>
                    {!isShowPreviews ? null : (
                        <ReactSortable
                            list={imagesToReorder}
                            setList={onSortableChange}
                            className={styles.flex}
                            onEnd={handleImageReorder}
                            disabled={isLoading}
                        >
                            {imagesToReorder.map((image, index) => (
                                <SalesThumbPreview
                                    key={image.id + index}
                                    index={index}
                                    file={image.file}
                                    removeFile={removeFile}
                                    setActiveImageIndex={setActiveImage}
                                />
                            ))}
                        </ReactSortable>
                    )}
                </div>
            </>
            {
                <ModalCarousel
                    closeModal={() => setIsModalOpen(false)}
                    pictures={imagesToReorder}
                    isOpen={isModalOpen}
                    pictureIndex={activeImageIndex}
                />
            }

            {isLoading && (
                <OverlayWithText backgroundBootstrapColor={'bg-secondary'}>
                    <Loader diameterInPx={90} thicknessInPx={9} />
                </OverlayWithText>
            )}
        </div>
    )
}

export default SalesUploadImages
