import React, { useCallback, useEffect, useState } from 'react'
import { Button, Modal, Stack } from 'react-bootstrap'

import { defaultCustomizableColumns } from '../../../constants/defaultCustomizableColumns'
import useResetFiltersOnUnmount from '../../../hooks/partList/useResetFiltersOnUnmount'
import { ToastType, useToast } from '../../../hooks/useToast'
import { useDispatch } from '../../../modules/store/customDispatch'
import { useSelector } from '../../../modules/store/customSelector'
import { useGetPartsAdminListMutation } from '../../../modules/store/datahubApi/datahubApi'
import { storeDispatchTypes } from '../../../modules/store/storeDispatchTypes'
import { Part } from '../../../types/part/partTypes'
import ListPagination from '../../_atoms/ListPagination/ListPagination'
import TablePartList from '../../_orgamisms/TablePartList/TablePartList'
import SearchComponent from '../SearchComponent/SearchComponent'

interface ComponentProps {
    isOpen: boolean
    closeModal: () => void
    setSelectedParts: (selectedParts: Part[]) => void
    disabledParts: Part[]
}

export const ModalChoseParts = ({ isOpen, closeModal, setSelectedParts, disabledParts }: ComponentProps) => {
    const dispatch = useDispatch()

    const [getPartsAdminList, { data: partsListData, isLoading, isError }] = useGetPartsAdminListMutation()
    const [itemsCount, setItemsCount] = useState(0)
    const [itemsTotal, setItemsTotal] = useState(0)

    useEffect(() => {
        if (partsListData) {
            setItemsCount(partsListData.dataCount)
            setItemsTotal(partsListData.total)
        }
    }, [partsListData])

    const selectedParts = useSelector(state => state.partList.selectedParts.parts)

    // filters
    const page = useSelector(state => state.partList.filters.page)
    const perPage = useSelector(state => state.partList.filters.perPage)
    const sortOrder = useSelector(state => state.partList.sortOrder)
    const modelId = useSelector(state => state.partList.filters.modelId)
    const markId = useSelector(state => state.partList.filters.markId)
    const categoryId = useSelector(state => state.partList.filters.categoryId)
    const partName = useSelector(state => state.partList.filters.partName)
    const priceRange = useSelector(state => state.partList.filters.priceRange)
    const priceFrom = useSelector(state => state.partList.filters.priceRange?.from)
    const priceTo = useSelector(state => state.partList.filters.priceRange?.to)
    const yearRange = useSelector(state => state.partList.filters.yearRange)
    const yearFrom = useSelector(state => state.partList.filters.yearRange?.from)
    const yearTo = useSelector(state => state.partList.filters.yearRange?.to)
    const partNumber = useSelector(state => state.partList.filters.partNumber)
    const filterDate = useSelector(state => state.partList.filters.filterDate)
    const filterDateField = useSelector(state => state.partList.filters.filterDateField)

    const handleCheckboxChange = useCallback(
        (part: Part) => {
            const newCheckedParts = [...selectedParts]
            const partIndex = newCheckedParts.findIndex(p => p.id === part.id)

            if (partIndex !== -1) {
                newCheckedParts.splice(partIndex, 1)
            } else {
                newCheckedParts.push(part)
            }

            dispatch({
                type: storeDispatchTypes.setSelectedParts,
                value: newCheckedParts,
            })
        },
        [dispatch, selectedParts],
    )
    const setNameSearch = useCallback(
        (searchTerm: string) => {
            dispatch({
                type: storeDispatchTypes.setPartNameTextFilterParts,
                value: {
                    partName: searchTerm,
                },
            })
        },
        [dispatch],
    )

    const onSubmit = useCallback(() => {
        setSelectedParts(selectedParts)
        closeModal()
    }, [closeModal, setSelectedParts, selectedParts])

    const renderListPagination = useCallback(() => {
        if (!isLoading && partsListData?.data.length === 0) return null

        return (
            <div>
                <ListPagination
                    page={page}
                    setPage={page =>
                        dispatch({
                            type: storeDispatchTypes.setPageFilterParts,
                            value: { page },
                        })
                    }
                    perPage={perPage}
                    itemsCount={itemsCount}
                    justifyContent="flex-end"
                />
            </div>
        )
    }, [dispatch, isLoading, itemsCount, page, partsListData?.data.length, perPage])

    useEffect(() => {
        getPartsAdminList({
            params: {
                pagination: {
                    page,
                    perPage,
                },
                order: sortOrder,
                modelId,
                markId,
                groupId: categoryId,
                search: partName,
                priceRange: priceRange && { from: priceFrom, to: priceTo },
                yearRange: yearRange && { from: yearFrom, to: yearTo },
                partNumber,
                filterDate,
                filterDateField,
            },
        })
    }, [
        categoryId,
        filterDate,
        filterDateField,
        getPartsAdminList,
        markId,
        modelId,
        page,
        partName,
        partNumber,
        perPage,
        priceFrom,
        priceRange,
        priceTo,
        sortOrder,
        yearFrom,
        yearRange,
        yearTo,
    ])

    // Сброс фильтров при уходе со страницы
    useResetFiltersOnUnmount()

    useToast({ isTriggered: isError, type: ToastType.ERROR, message: 'Ошибка загрузки запчастей' })

    return (
        <Modal show={isOpen} onHide={closeModal} fullscreen={true}>
            <Modal.Header closeButton>
                <Modal.Title>Выберите запчасти</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Stack gap={4}>
                    <SearchComponent
                        oldSearchTerm={partName}
                        setSearchTerm={setNameSearch}
                        resultCount={itemsCount !== itemsTotal ? itemsCount : undefined}
                    />
                    {renderListPagination()}
                    <TablePartList
                        columns={defaultCustomizableColumns.filter(column => column.id !== 'actions')}
                        parts={partsListData?.data || []}
                        isLoading={isLoading}
                        selectedParts={selectedParts}
                        handleCheckboxChange={handleCheckboxChange}
                        disabledParts={disabledParts}
                        readonly
                    />
                    {renderListPagination()}
                </Stack>
            </Modal.Body>
            <Modal.Footer>
                <Stack direction="horizontal" gap={3}>
                    <Button
                        variant="primary"
                        style={{ width: '200px' }}
                        onClick={onSubmit}
                        disabled={!selectedParts.length}
                    >
                        Добавить запчасти
                    </Button>
                    <Button variant="outline-primary" style={{ width: '200px' }} onClick={closeModal}>
                        Отменить
                    </Button>
                </Stack>
            </Modal.Footer>
        </Modal>
    )
}
