import React, { useCallback, useEffect } from 'react'
import { Button, Col, Modal, Row, Stack } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import Select from 'react-select'

import Form from 'react-bootstrap/Form'

import { fontSizes, fontWeights } from '../../../constants/salesStyles'
import { ToastType, useToast } from '../../../hooks/useToast'
import { useSelector } from '../../../modules/store/customSelector'
import { useUpdateSoldPartsFromDealMutation } from '../../../modules/store/datahubApi/datahubApi'
import {
    useLazyGetClientsListQuery,
    useGetDealStatusDictQuery,
    useSaveDealMutation,
} from '../../../modules/store/dochubApi/dochubApi'
import {
    Client,
    DealItem,
    DealStage,
    DealStatus,
    DealStatusInfo,
    SaveDealApiArg,
} from '../../../modules/store/dochubApi/dochubTypes'
import { adminRouteAlias, getAdminNavigationPath } from '../../../router/adminRouteAlias'
import { EDocumentType } from '../../../types/document/documentTypes'
import { Marketplaces, Part } from '../../../types/part/partTypes'
import { formatPrice, unformatPrice } from '../../../utils/formatAndUnformatPrice'
import { getCurrencySymbol } from '../../../utils/getCurrencySymbol'
import CustomSelect from '../../_atoms/CustomSelect/CustomSelect'
import Loader from '../../_atoms/Loader/Loader'
import OverlayWithText from '../../_atoms/OverlayWithText/OverlayWithText'
import SalesText from '../../_atoms/SalesText/SalesText'
import CellThumbCarousel from '../../_orgamisms/CellThumbCarousel/CellThumbCarousel'

import styles from './styles.module.scss'

interface IOption {
    value: string
    label: string
}

const sources: IOption[] = Object.entries(Marketplaces).map(([key, value]) => ({ value: key, label: value }))

interface FormData {
    price: number
    client: Client | null
    seller: string
    selectedSource: IOption
    comment: string
    selectedStatus: DealStatusInfo | null
    invoice: boolean
    receipt: boolean
}

interface ComponentProps {
    parts: Part[]
    isOpen: boolean
    closeModal: (documentsArray?: EDocumentType[], selectedSource?: string, deal?: any) => void
}

export const ModalFastDeal = ({ parts, isOpen, closeModal }: ComponentProps) => {
    const { register, handleSubmit, control, watch } = useForm<FormData>()
    const [invoice, receipt, selectedSource] = watch(['invoice', 'receipt', 'selectedSource'])

    const navigate = useNavigate()

    const [saveDeal, { data: deal, isLoading, isError }] = useSaveDealMutation()
    const [updateSoldPartsFromDeal] = useUpdateSoldPartsFromDealMutation()

    const {
        data: dealStatusDictData,
        isLoading: isLoadingGetDealStatusDict,
        isError: isErrorGetDealStatusDict,
    } = useGetDealStatusDictQuery()
    const [getClientsList, { data: clientsList, isFetching: isLoadingGetClientsList, isError: isErrorGetClientsList }] =
        useLazyGetClientsListQuery()

    const currentCurrency = useSelector(state => state.partList.currentCurrency)
    const userPhoneNumber = useSelector(state => state.userData.smsAuth.phoneNumber)

    const handleSearchClients = useCallback(
        (search: string) => {
            getClientsList({ params: { pagination: { page: 1, perPage: 10 }, search } })
        },
        [getClientsList],
    )

    const onSubmit = useCallback(
        (data: FormData) => {
            const dealItems: DealItem[] = parts.map(part => ({
                title: part.customTitle || part.title,
                price: data.price || part.price || 0,
                qty: 1,
                part: {
                    id: part.id,
                    vendorCode: part.vendorCode,
                    customTitle: part.customTitle,
                    title: part.title,
                    markTitle: part.markTitle,
                    modelTitle: part.modelTitle,
                    generationTitle: part.generationTitle,
                },
            }))

            const dealData: Omit<SaveDealApiArg, 'userId'> = {
                deal: {
                    userId: localStorage.getItem('userId')!,
                    code: 'code',
                    status: data.selectedStatus?.code || DealStatus.reserved,
                    items: dealItems,
                    ...(data.client && {
                        client: {
                            id: data.client.id,
                            name: data.client.name,
                            phone: data.client.phone || '',
                        },
                    }),
                    seller: {
                        name: data.seller,
                    },
                    meta: {
                        comment: data.comment,
                        source: data.selectedSource.value,
                    },
                },
            }
            saveDeal(dealData)
                .unwrap()
                .then(response => {
                    // Если сделка успешно сохранена и получен dealId
                    const dealId = response.data.id
                    // Формируем payload для updateSoldPartsFromDeal
                    const updatePayload = {
                        userId: localStorage.getItem('userId')!,
                        dealId,
                        dealStatus: response.data.status,
                        items: dealItems.map(item => ({
                            partId: item.part.id,
                            marketplace: data.selectedSource ? data.selectedSource.value : 'avito', // можно заменить на константу, если требуется
                            price: item.price,
                            qty: item.qty,
                        })),
                    }
                    updateSoldPartsFromDeal(updatePayload)
                        .unwrap()
                        .then(() => {
                            // При необходимости можно добавить уведомление об успехе
                        })
                        .catch((err: any) => {
                            console.error('Ошибка updateSoldPartsFromDeal: ', err)
                        })
                })
                .catch(err => {
                    console.error('Ошибка сохранения сделки: ', err)
                })
        },
        [parts, saveDeal],
    )

    useEffect(() => {
        if (deal) {
            const documentsArray: EDocumentType[] = []

            if (invoice) {
                documentsArray.push(EDocumentType.Invoice)
            }

            if (receipt) {
                documentsArray.push(EDocumentType.Receipt)
            }

            closeModal(documentsArray, selectedSource.value, deal)

            if (!documentsArray.length) {
                navigate(getAdminNavigationPath(adminRouteAlias.deals.list) + `?activeStage=${DealStage.all}`)
            }
        }
    }, [closeModal, deal, invoice, navigate, receipt, selectedSource])

    useToast({ isTriggered: isError, type: ToastType.ERROR, message: 'Ошибка создания сделки' })
    useToast({
        isTriggered: isErrorGetDealStatusDict,
        type: ToastType.ERROR,
        message: 'Ошибка загрузки словаря статусов',
    })
    useToast({
        isTriggered: isErrorGetClientsList,
        type: ToastType.ERROR,
        message: 'Ошибка загрузки покупателей',
    })
    useToast({
        isTriggered: !!deal,
        type: ToastType.SUCCESS,
        message: 'Сделка успешно создана',
    })

    return (
        <Modal show={isOpen} onHide={closeModal} centered>
            {isLoading && (
                <OverlayWithText backgroundBootstrapColor={'bg-secondary'}>
                    <Loader diameterInPx={100} thicknessInPx={10} />
                </OverlayWithText>
            )}
            <Modal.Header closeButton>
                <Modal.Title>Быстрая сделка</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                    <h5>Выбрано товаров: {parts.length}</h5>
                    <Stack direction="horizontal" gap={5}>
                        <Form.Check {...register('invoice')} label={'Расходная накладная'} />
                        <Form.Check {...register('receipt')} label={'Товарный чек'} />
                    </Stack>
                </>
                {parts.length === 1 && (
                    <>
                        <div className="mt-4">
                            <Stack direction="horizontal" gap={2}>
                                <div style={{ alignSelf: 'start' }}>
                                    <CellThumbCarousel pictures={parts[0].images} part={parts[0]} />
                                </div>
                                <div>
                                    <SalesText
                                        text={`${parts[0]?.customTitle ?? parts[0]?.title}`}
                                        fontSize={fontSizes.m}
                                        fontWeight={fontWeights.bold}
                                    />
                                    {parts[0].vendorCode && (
                                        <SalesText text={`Артикул: ${parts[0].vendorCode}`} fontSize={fontSizes.m} />
                                    )}
                                </div>
                            </Stack>
                        </div>
                        <Form.Group as={Row} className="mt-4">
                            <Form.Label column>Сумма сделки, {getCurrencySymbol(currentCurrency)}</Form.Label>
                            <Col xs={8}>
                                <Form.Control
                                    type="text"
                                    defaultValue={formatPrice(parts[0].price || 0)}
                                    {...register('price', {
                                        setValueAs: value => unformatPrice(value),
                                        required: true,
                                    })}
                                    onChange={e => {
                                        const rawValue = unformatPrice(e.target.value)
                                        e.target.value = formatPrice(rawValue)
                                    }}
                                />
                            </Col>
                        </Form.Group>
                    </>
                )}

                <div className={styles.grayContainer}>
                    <Form.Group as={Row} className="mb-4">
                        <Form.Label column>Покупатель</Form.Label>
                        <Col xs={8}>
                            <Controller
                                name="client"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <CustomSelect
                                        {...field}
                                        onSearch={handleSearchClients}
                                        options={clientsList?.data}
                                        isLoading={isLoadingGetClientsList}
                                        getOptionLabel={option => option.name}
                                        getOptionValue={option => option.id}
                                        placeholder="Выберите покупателя"
                                    />
                                )}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-4">
                        <Form.Label column>Ответственный</Form.Label>
                        <Col xs={8}>
                            <Form.Control
                                type="text"
                                {...register('seller', { required: true })}
                                placeholder="Введите ответственного"
                                defaultValue={userPhoneNumber}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-4">
                        <Form.Label column>Источник сделки</Form.Label>
                        <Col xs={8}>
                            <Controller
                                name="selectedSource"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Select {...field} options={sources} placeholder="Выберите источник" isClearable />
                                )}
                            />
                        </Col>
                    </Form.Group>
                </div>
                <Form.Group as={Row} className="mb-4">
                    <Form.Label column>Статус сделки</Form.Label>
                    <Col xs={8}>
                        <Controller
                            name="selectedStatus"
                            control={control}
                            {...(dealStatusDictData
                                ? {
                                      defaultValue: Object.values(dealStatusDictData.data).find(
                                          d => d.code === DealStatus.issued,
                                      ),
                                  }
                                : {})}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={
                                        dealStatusDictData
                                            ? Object.values(dealStatusDictData.data).map(
                                                  dealStatusInfo => dealStatusInfo,
                                              )
                                            : []
                                    }
                                    isLoading={isLoadingGetDealStatusDict}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.code}
                                    placeholder="Выберите статус сделки"
                                    isClearable
                                />
                            )}
                        />
                    </Col>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Комментарии к сделке</Form.Label>
                    <Form.Control as="textarea" rows={5} placeholder="Введите комментарий" {...register('comment')} />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Stack direction="horizontal" gap={3}>
                    <Button variant="primary" style={{ width: '150px' }} onClick={handleSubmit(onSubmit)}>
                        Создать сделку
                    </Button>
                    <Button variant="outline-primary" style={{ width: '150px' }} onClick={() => closeModal()}>
                        Отменить
                    </Button>
                </Stack>
            </Modal.Footer>
        </Modal>
    )
}
