import React from 'react'
import { NavLink } from 'react-router-dom'

import classNames from 'classnames'

import { colors } from '../../../constants/salesStyles'
import { TCustomLink } from '../../../types/common/commonTypes'

import styles from './styles.module.scss'

type IProps = {
    className?: string
    marginLeft?: number
    marginRight?: number
    marginTop?: number
    marginBottom?: number
    color?: colors
    arrowIcon?: boolean
}

const ArrowIcon: React.FC = () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        stroke="#555"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{ marginLeft: 4 }}
    >
        <line x1="4" y1="8" x2="12" y2="8" />
        <polyline points="8 4 12 8 8 12" />
    </svg>
)

const CustomLink = ({
    className,
    upperText,
    text,
    href,
    onClick,
    target,
    truncate,
    marginRight,
    marginLeft,
    marginTop,
    marginBottom,
    color,
    arrowIcon,
}: TCustomLink & IProps) => (
    <div className={classNames(className, `${styles.wrap} ${truncate ? styles.truncate : ''}`)}>
        {upperText && <div className={`${styles.upperText} ${truncate ? styles.truncate : ''}`}>{upperText}</div>}
        {target ? (
            <a
                href={href}
                target={target}
                className={`${styles.headerLink} ${truncate ? styles.truncate : ''}`}
                onClick={onClick}
                rel={target == '_blank' ? 'noopener noreferrer' : ''}
                style={{
                    marginLeft: marginLeft && marginLeft + 'px',
                    marginRight: marginRight && marginRight + 'px',
                    marginTop: marginTop && marginTop + 'px',
                    marginBottom: marginBottom && marginBottom + 'px',
                    color: color && color,
                }}
            >
                {text}
                {arrowIcon && <ArrowIcon />}
            </a>
        ) : (
            <NavLink
                to={href}
                className={`${styles.headerLink} ${truncate ? styles.truncate : ''}`}
                onClick={onClick}
                style={{
                    marginLeft: marginLeft && marginLeft + 'px',
                    marginRight: marginRight && marginRight + 'px',
                    marginTop: marginTop && marginTop + 'px',
                    marginBottom: marginBottom && marginBottom + 'px',
                    color: color && color,
                }}
            >
                {text}
                {arrowIcon && <ArrowIcon />}
            </NavLink>
        )}
    </div>
)

export default CustomLink
