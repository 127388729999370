import { Dispatch } from 'redux'
import { v4 as uuidV4 } from 'uuid'

import anchor from '../../../constants/anchor'
import { apiImages, apiInstance } from '../../api/apiInstance'
import { RootState } from '../../store/rootReducer'
import { storeDispatchTypes } from '../../store/storeDispatchTypes'

const saveImageInCloud =
    (file: File, index: number, entityId: string, entityType: 'part' | 'car', isLast = false) =>
    async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const { userData } = getState()
        const { userId } = userData.smsAuth
        const localPath = `oldField`
        const createdAt = Date.now()
        const id = `${entityType}-${entityId}-${uuidV4()}`

        const switchLoaders = () => {
            if (entityType === 'car') {
                dispatch({ type: storeDispatchTypes.seCarsLoader, value: { field: 'isCarSaving', value: false } })
                dispatch({ type: storeDispatchTypes.seCarsLoader, value: { field: 'isCarCreated', value: true } })
            } else if (entityType === 'part') {
                dispatch({ type: storeDispatchTypes.setPartsLoader, value: { field: 'isPartSaving', value: false } })
                dispatch({ type: storeDispatchTypes.setPartsLoader, value: { field: 'isPartCreated', value: true } })
            }
        }
        if (!userId) {
            throw new Error('При загрузке фото произошла ошибка, пожалуйста, сообщите в поддержку')
        }

        const toCloudConfig = {
            headers: { Authorization: `${anchor} ${localStorage.getItem('accessToken')}` },
        }

        const toStoreConfig = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        }

        try {
            const formData = new FormData()
            formData.append('file', file)
            formData.append('userId', userId)
            formData.append('imageType', entityType)

            const {
                data: { status, cloudPath, cloudLongUrl },
            } = await apiImages.post('/uploadImageToStoreV2', formData, toStoreConfig)

            if (status === 'ok') {
                const toCloudParams = {
                    userId,
                    image: {
                        id,
                        userId,
                        index,
                        entityId,
                        entityType,
                        localPath,
                        cloudPath,
                        cloudLongUrl,
                        createdAt,
                        updatedAt: createdAt,
                    },
                }
                await apiInstance.post('/saveImageInCloud', toCloudParams, toCloudConfig)
                if (isLast) {
                    switchLoaders()
                }
            }
        } catch (e) {
            console.error('save car in cloud failed', e)
            switchLoaders()
        }
    }

export default saveImageInCloud
