import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import anchor from '../../../constants/anchor'

import {
    GetPartsAdminListApiArg,
    GetPartsAdminListApiResponse,
    SaveImageInCloudApiArg,
    SaveImageInCloudApiResponse,
    UpdateSoldPartsFromDealApiArg,
    UpdateSoldPartsFromDealApiResponse,
} from './datahubTypes'

export const BASE_URL = 'https://api.stock-pro.net/datahub/'
// export const BASE_URL = 'http://localhost:3001/'

const customBaseQuery = async (args: any, api: any, extraOptions: any) => {
    const baseQuery = fetchBaseQuery({ baseUrl: BASE_URL })
    const userId = localStorage.getItem('userId')
    const accessToken = localStorage.getItem('accessToken')
    const authorizationHeader = `${anchor} ${accessToken}`

    // Добавляем headers с Authorization и userId к каждому запросу
    if (typeof args === 'object') {
        args.headers = {
            ...args.headers,
            Authorization: authorizationHeader,
        }

        if (args.body) {
            args.body = { ...args.body, userId }
        }
    }

    return baseQuery(args, api, extraOptions)
}

export const datahubApi = createApi({
    reducerPath: 'datahubApi',
    baseQuery: customBaseQuery,
    endpoints: builder => ({
        getPartsAdminList: builder.mutation<GetPartsAdminListApiResponse, Omit<GetPartsAdminListApiArg, 'userId'>>({
            query: queryArg => ({
                url: `/getPartsAdminList`,
                method: 'POST',
                body: queryArg,
            }),
        }),
        getActionLogList: builder.query({
            query: params => ({
                url: '/getActionLogList',
                method: 'POST',
                body: {
                    userId: localStorage.getItem('userId'),
                    params,
                },
            }),
        }),
        updateSoldPartsFromDeal: builder.mutation<UpdateSoldPartsFromDealApiResponse, UpdateSoldPartsFromDealApiArg>({
            query: payload => ({
                url: '/updateSoldPartsFromDeal',
                method: 'POST',
                body: payload,
            }),
        }),
        saveImageInCloud: builder.mutation<SaveImageInCloudApiResponse, SaveImageInCloudApiArg>({
            query: payload => ({
                url: '/saveImageInCloud',
                method: 'POST',
                body: payload,
            }),
        }),
    }),
})

export const {
    useGetPartsAdminListMutation,
    useGetActionLogListQuery,
    useUpdateSoldPartsFromDealMutation,
    useSaveImageInCloudMutation,
} = datahubApi
