import { useState } from 'react'
import { Button, Form, Modal, Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router'

import createDocument from '../../../modules/redux/documentList/CreateDocument'
import { useDispatch } from '../../../modules/store/customDispatch'
import { adminRouteAlias, getAdminNavigationPath } from '../../../router/adminRouteAlias'
import { EDocumentType } from '../../../types/document/documentTypes'
import { Part } from '../../../types/part/partTypes'
import Loader from '../../_atoms/Loader/Loader'
import OverlayWithText from '../../_atoms/OverlayWithText/OverlayWithText'

import styles from './styles.module.scss'

interface IProps {
    isOpen: boolean
    closeModal: () => void
    checks: {
        documentType: EDocumentType[]
        marketplace?: string,
        deal?: any
    }
    parts: Part[]
}

const ModalDataDocument = ({ isOpen, closeModal, checks, parts }: IProps) => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)

    const [number, setNumber] = useState(checks.deal?.data?.code ?? '')
    const [buyerName, setBuyerName] = useState(checks.deal?.data?.client?.name ?? '')
    const [buyerAddress, setBuyerAddress] = useState('')
    const [buyerTelephone, setBuyerTelephone] = useState(checks.deal?.data?.client?.phone ?? '')
    const [comment, setComment] = useState('')

    const navigate = useNavigate()

    const onCloseModal = () => {
        closeModal()
    }

    const confirmChange = async () => {
        setIsLoading(true)

        // console.log('checks.deal?.data', checks.deal?.data)
        // console.log('checks.deal?.data?.items', checks.deal?.data?.items)
        // console.log('parts', parts)

        let documentParts = parts

        if (checks.deal?.data?.items) {
            documentParts = parts.map(p => {
                const items = checks.deal?.data?.items || []
                const item = items.find((i: { part: { id: string } }) => i.part?.id == p.id)
                if (item?.price) (
                    p.price = item.price
                )
                return p
            })
        }

        const documentAmount = documentParts.reduce((sum, part) => sum + (part.price || 0), 0)

        const document = {
            title: number,
            type: checks.documentType,
            extra_data: {
                number,
                buyerName,
                buyerAddress,
                buyerTelephone,
                comment,
                parts: documentParts,
                amount: documentAmount,
            },
        }



        try {
            // TODO: Убрал продажу деталей при создании документа
            // await Promise.all(parts.map(part => dispatch(setPartSaleInfo(part, part.price || 0, checks.marketplace))))
            await dispatch(createDocument(document))

            if (checks.documentType.includes(EDocumentType.Receipt)) {
                navigate(`${getAdminNavigationPath(adminRouteAlias.documents.location)}?type=receipt`)
            } else if (checks.documentType.includes(EDocumentType.IncomingInvoice)) {
                navigate(`${getAdminNavigationPath(adminRouteAlias.documents.location)}?type=incomingInvoice`)
            }

            if (checks.documentType.includes(EDocumentType.Invoice)) {
                navigate(`${getAdminNavigationPath(adminRouteAlias.documents.location)}?type=invoice`)
            }

            closeModal()
        } catch (error) {
            console.error('Ошибка при выполнении запросов:', error)
        }

        setIsLoading(false)
    }

    return (
        <>
            <Modal className={styles.modalDataDocument} show={isOpen} onHide={onCloseModal} centered>
                {isLoading && (
                    <OverlayWithText backgroundBootstrapColor={'bg-secondary'}>
                        <Loader diameterInPx={100} thicknessInPx={10} />
                    </OverlayWithText>
                )}
                <Modal.Header closeButton>
                    <Modal.Title>
                        <b>Укажите данные для документов</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group as={Row} className="mb-3" controlId="formNumber">
                            <Form.Label column sm="5">
                                Номер
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control
                                    defaultValue={number ?? ''}
                                    onChange={e => setNumber(e.target.value)} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formName">
                            <Form.Label column sm="5">
                                Название покупателя
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control
                                    defaultValue={buyerName ?? ''}
                                    onChange={e => setBuyerName(e.target.value)} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formAddress">
                            <Form.Label column sm="5">
                                Адрес покупателя
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control onChange={e => setBuyerAddress(e.target.value)} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formTelephone">
                            <Form.Label column sm="5">
                                Телефон покупателя
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control
                                    defaultValue={buyerTelephone ?? ''}
                                    onChange={e => setBuyerTelephone(e.target.value)} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formComment">
                            <Form.Label column sm="5">
                                Комментарий
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control as="textarea" onChange={e => setComment(e.target.value)} />
                            </Col>
                        </Form.Group>
                        <div className="d-flex justify-content-end">
                            <Button onClick={confirmChange} disabled={!number}>
                                Сохранить
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalDataDocument
