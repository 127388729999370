import React from 'react'

import { useDispatch } from '../../../modules/store/customDispatch'
import { storeDispatchTypes } from '../../../modules/store/storeDispatchTypes'

import styles from './styles.module.scss'

interface IProps {
    slice?: 'carList' | 'partList' | 'documentList' | 'actionLogList'
    message?: string
    resetFilters?: () => void
}

const ListNoItems = ({ slice, message, resetFilters }: IProps) => {
    const dispatch = useDispatch()
    let actionType:
        | storeDispatchTypes.resetFiltersCars
        | storeDispatchTypes.resetFiltersParts
        | storeDispatchTypes.resetFiltersDocuments
        | storeDispatchTypes.resetFiltersActionLogList

    switch (slice) {
        case 'carList':
            actionType = storeDispatchTypes.resetFiltersCars
            break
        case 'partList':
            actionType = storeDispatchTypes.resetFiltersParts
            break
        case 'documentList':
            actionType = storeDispatchTypes.resetFiltersDocuments
            break
        case 'actionLogList':
            actionType = storeDispatchTypes.resetFiltersActionLogList
            break
    }
    const handleResetFilters = () => {
        dispatch({
            type: actionType,
        })
    }
    return (
        <div className={styles.wrap}>
            <p>Ничего не найдено</p>
            {message && <p>{message}</p>}
            {(slice || resetFilters) && (
                <p>
                    Попробуйте{' '}
                    <span
                        className={styles.reset}
                        onClick={() => (resetFilters ? resetFilters() : handleResetFilters())}
                    >
                        сбросить фильтры
                    </span>
                </p>
            )}
        </div>
    )
}

export default ListNoItems
