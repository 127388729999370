import { useEffect } from 'react'

import { AppDispatch, useDispatch } from '../../modules/store/customDispatch'
import { defaultPartList } from '../../modules/store/partList/partListReducer'
import { storeDispatchTypes } from '../../modules/store/storeDispatchTypes'

export const resetFilters = (dispatch: AppDispatch) => {
    dispatch({
        type: storeDispatchTypes.resetFiltersParts,
    })
    dispatch({
        type: storeDispatchTypes.setPartList,
        value: defaultPartList,
    })
    dispatch({
        type: storeDispatchTypes.setSelectAllParts,
        value: false,
    })
}

const useResetFiltersOnUnmount = () => {
    const dispatch = useDispatch()
    // Сброс фильтров при уходе со страницы
    useEffect(
        () => () => {
            resetFilters(dispatch)
        },
        [dispatch],
    )
}

export default useResetFiltersOnUnmount
