import React, { useEffect, useState } from 'react'
import { Search, XCircleFill } from 'react-bootstrap-icons'

import { Dispatch } from 'redux'

import { colors } from '../../../constants/salesStyles'
import { useDispatch } from '../../../modules/store/customDispatch'
import { RootState } from '../../../modules/store/rootReducer'
import { storeDispatchTypes } from '../../../modules/store/storeDispatchTypes'
import { SelectOptionLocal } from '../../../types/common/commonTypes'
import SalesSelectSearch from '../../_molecules/SalesSelectSearch/SalesSelectSearch'

import styles from './styles.module.scss'

interface IProps {
    slice: 'carList' | 'partList' | 'actionLogList'
    filterId: number | number[] | undefined | string[] | string
    options: SelectOptionLocal[]
    tableHead: string
    placeholder: string
    actionType: storeDispatchTypes
    searchOptions: (searchTerm: string) => (dispatch: Dispatch, getState: () => RootState) => Promise<void>
    minSearchLength?: number
    allowEmptySearch?: boolean
    onClear?: () => void
    additionalFilterId?: string
}

const TableHeadSelectSearch = ({
    slice,
    filterId,
    options,
    tableHead,
    placeholder,
    actionType,
    searchOptions,
    minSearchLength,
    allowEmptySearch = true,
    onClear,
    additionalFilterId,
}: IProps) => {
    const dispatch = useDispatch()
    const [isEditable, setIsEditable] = useState(false)
    const [renderValue, setRenderValue] = useState('')
    const [oldSearch, setOldSearch] = useState('')
    let initialFilterId: number | undefined | string
    switch (slice) {
        case 'partList':
            if (filterId && Array.isArray(filterId)) {
                initialFilterId = filterId[0]
            }
            break
        case 'carList':
            if (typeof filterId === 'number') {
                initialFilterId = filterId
            }
            break
        case 'actionLogList':
            if (typeof filterId === 'number') {
                initialFilterId = filterId
            }
            break
    }
    const [selectedId, setSelectedId] = useState<number | undefined | string>(initialFilterId)

    const onChangeSelectSearch = (newValue: string | number, newName: string) => {
        setRenderValue(newName)
        setIsEditable(false)

        if (newValue !== selectedId) {
            setSelectedId(newValue)
            dispatchSetFilter(newValue)
            setOldSearch(newName)
        }
    }

    const onChangeRenderValue = (newValue: string) => {
        setRenderValue(newValue)
    }

    const dispatchSetFilter = (id: string | number | undefined) => {
        dispatch({
            type: actionType,
            value: {
                filterId: id,
            },
        })
    }

    const onClose = () => {
        setIsEditable(false)
    }

    const clearHandler = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.stopPropagation()
        setRenderValue('')
        setSelectedId(undefined)
        dispatchSetFilter(undefined)
        setOldSearch('')
        onClear && onClear()
    }

    useEffect(() => {
        if (isEditable) {
            setRenderValue(`${oldSearch}`)
        }
    }, [isEditable])

    useEffect(() => {
        setSelectedId('')
        setIsEditable(false)
    }, [initialFilterId])

    useEffect(() => {
        switch (slice) {
            case 'partList':
                if (filterId && Array.isArray(filterId)) {
                    setSelectedId(filterId[0])
                }
                break
            case 'carList':
                if (typeof filterId === 'number') {
                    setSelectedId(filterId)
                }
                break
        }

        // Сброс старого поиска
        if (!filterId) {
            setOldSearch('')
        }
    }, [filterId])

    return (
        <div className={styles.wrap}>
            {isEditable ? (
                <span className={styles.selectSearchWrap}>
                    <SalesSelectSearch
                        renderValue={renderValue}
                        onChangeValue={onChangeSelectSearch}
                        onChangeRenderValue={onChangeRenderValue}
                        isEditable={isEditable}
                        getSuggestions={searchOptions}
                        suggestions={options}
                        isLoading={false}
                        maxOptions={9999}
                        placeholder={placeholder}
                        allowEmptySearch={allowEmptySearch}
                        delay={400}
                        onClose={onClose}
                        value={selectedId}
                        widthInPixelsOptions={270}
                        onSubmit={() => onChangeSelectSearch(renderValue, renderValue)}
                        color={colors.white}
                        colorHover={colors.black}
                        backgroundColor={colors.pinkDark}
                        backgroundColorHover={colors.pinkTransparent}
                        inputBackgroundColor={colors.white}
                        suffixColor={colors.grayDark}
                        selectTop={41}
                        selectPaddingTop={8}
                        selectPaddingBottom={8}
                        minSearchLength={minSearchLength}
                        filterId={additionalFilterId}
                    />
                </span>
            ) : (
                <>
                    <div onClick={() => setIsEditable(true)} className={styles.tableHeadWrap}>
                        <span>{tableHead}</span>
                    </div>
                    {initialFilterId ? (
                        <span className={styles.clearIcon} onClick={clearHandler}>
                            <XCircleFill color="grey" size={14} />
                        </span>
                    ) : (
                        <span className={styles.icon} onClick={() => setIsEditable(true)}>
                            <Search color={colors.grayBorderApp} size={14} />
                        </span>
                    )}
                </>
            )}
        </div>
    )
}

export default TableHeadSelectSearch
