import React, { useEffect, useState } from 'react'
import { Funnel } from 'react-bootstrap-icons'

import { stateTypeActionLogList } from '../../../modules/store/actionLogList/actionLogListReducer'
import { stateTypeCars } from '../../../modules/store/carList/carListReducer'
import { useDispatch } from '../../../modules/store/customDispatch'
import { stateTypeParts } from '../../../modules/store/partList/partListReducer'
import { storeDispatchTypes } from '../../../modules/store/storeDispatchTypes'
import HoverOverlay from '../HoverOverlay/HoverOverlay'

import styles from './styles.module.scss'

type carStateFilters = Pick<stateTypeCars, 'filters'>
type partStateFilters = Pick<stateTypeParts, 'filters'>
type actionLogListStateFilters = Pick<stateTypeActionLogList, 'filters'>

interface IProps {
    slice: string
    leftInPixes: number
    topInPixes: number
    currentFilters: unknown
    hoverOffset: [number, number]
}

const ListResetFiltersButton = ({ slice, leftInPixes, topInPixes, currentFilters, hoverOffset }: IProps) => {
    const dispatch = useDispatch()
    const [isFilters, setIsFilters] = useState(false)
    const iconSizeFix = 16
    const iconColorFix = '#BA1274'

    let actionType:
        | storeDispatchTypes.resetFiltersCars
        | storeDispatchTypes.resetFiltersParts
        | storeDispatchTypes.resetFiltersActionLogList

    switch (slice) {
        case 'carList':
            actionType = storeDispatchTypes.resetFiltersCars
            break
        case 'partList':
            actionType = storeDispatchTypes.resetFiltersParts
            break
        case 'actionLogList':
            actionType = storeDispatchTypes.resetFiltersActionLogList
            break
    }

    let filters: carStateFilters | partStateFilters
    switch (slice) {
        case 'carList':
            filters = currentFilters as carStateFilters
            break
        case 'partList':
            filters = currentFilters as partStateFilters
            break
        case 'actionLogList':
            filters = currentFilters as actionLogListStateFilters
            break
    }

    const getIsFilters = () => {
        const filterEntries = Object.entries(filters)
        let result = false
        filterEntries.map(item => {
            if (item[0] == 'page' && item[1] == 1) return
            if (item[0] == 'perPage' && item[1] == 50) return
            // if (item[1]) {
            if (item[0]) {
                result = true
            }
        })
        return result
    }
    const resetFilters = () => {
        dispatch({
            type: actionType,
        })
    }

    useEffect(() => {
        const isFiltersNew = getIsFilters()
        setIsFilters(isFiltersNew)
    }, [currentFilters])

    return (
        <>
            {isFilters && (
                <HoverOverlay
                    tooltipText={'Очистить фильтры'}
                    placement={'right'}
                    offset={hoverOffset}
                    maxWidthInPixels={450}
                >
                    <span
                        onClick={resetFilters}
                        className={styles.wrap}
                        style={{ left: leftInPixes + 'px', top: topInPixes + 'px' }}
                    >
                        <Funnel size={iconSizeFix} color={iconColorFix} />
                    </span>
                </HoverOverlay>
            )}
        </>
    )
}

export default ListResetFiltersButton
