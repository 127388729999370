import { fieldTypes, valueTypes } from '../common/commonTypes'
import { PartImage } from '../part/partTypes'

export type CarExtraData = {
    defects?: number[]
    colorCode?: string
    regionId?: number
    conditionId?: number
    steeringType?: string
    equipment?: string
    customTitle?: string
    bodycars?: string
    engine?: string
}

export type Car = {
    bodyTypeId: number
    bodyTypeTitle: string
    createdAt: string
    description: string
    id: string
    images: PartImage[]
    isArchived: boolean
    isDeleted: boolean
    markId: number
    markTitle: string
    mileage: number
    modelId: number
    modelTitle: string
    modificationId: number
    modificationTitle: string
    price: number
    updatedAt: string
    userId: string
    vinCode: string
    year: number
    partsCnt: number
    soldPartsPercent: number
    sumSoldParts: number
    leftPartsSum: number
    extraData?: CarExtraData
    soldPartsCount?: number
    descTemplate: string
    sumAllParts: number
    partsOnSaleCount: number
    partsOnSaleSum: number
}

export enum carFields {
    carId = 'carId',
    markId = 'markId',
    modelId = 'modelId',
    modificationId = 'modificationId',
    bodyTypeId = 'bodyTypeId',
    year = 'year',
    vinCode = 'vinCode',
    carPrice = 'carPrice',
    userId = 'userId',
    description = 'description',
    mileage = 'mileage',
    isArchived = 'isArchived',
    isDeleted = 'isDeleted',
    carImages = 'carImages',
    steeringType = 'steeringType',
    equipment = 'equipment',
    colorCode = 'colorCode',
    regionId = 'regionId',
    defects = 'defects',
    conditionId = 'conditionId',
    sumSoldParts = 'sumSoldParts',
    soldPartsCount = 'soldPartsCount',
    sumAllParts = 'sumAllParts',
    partsOnSaleCount = 'partsOnSaleCount',
    partsOnSaleSum = 'partsOnSaleSum',
    roi = 'roi',
    roiMax = 'roiMax',
    profit = 'profit',
    descTemplate = 'descTemplate',
    applicabilityMarkId = 'applicabilityMarkId',
    applicabilityModelId = 'applicabilityModelId',
    applicabilityModificationId = 'applicabilityModificationId',
    applicabilityBodyTypeId = 'applicabilityBodyTypeId',
    applicabilityYear = 'applicabilityYear',
    customTitle = 'customTitle',
    bodycars = 'bodycars',
    engine = 'engine',
}

export type fieldValuePair = { field: carFields; value: number | string | boolean }
export type fieldUpdateObject = {
    field: carFields
    value?: number | string | boolean
    specialPropsField?: carSpecialPropsFields
    specialPropsValue: number | string | boolean
}
export type templateButton = { title: string; value: string }

export type carSpecialProps = {
    hoverText?: string
    renderValue?: string
    isRequired?: boolean
    isVisibleSwitch?: fieldValuePair[]
    isVisibleAny?: fieldValuePair[]
    fieldParent?: carFields
    fieldParentValue?: string | boolean | number
    fieldChild?: carFields
    isDisabled?: boolean
    placeholder?: string
    errorMessage?: string
    isSelect?: boolean
    switchRenderOptions?: string[]
    textareaMinHeight?: number
    textareaMaxLength?: number
    column?: number
    isTouched?: boolean
    isFullWidth?: boolean
    extraHandle?:
        | 'carImage'
        | 'defects'
        | 'steeringType'
        | 'regionId'
        | 'colorCode'
        | 'equipment'
        | 'conditionId'
        | 'customTitle'
    templateButtons?: templateButton[]
    customWidth?: number
    checkboxItems?: carInput[]
    maxLength?: number
}

export enum carSpecialPropsFields {
    hoverText = 'hoverText',
    renderValue = 'renderValue',
    isRequired = 'isRequired',
    fieldParent = 'fieldParent',
    fieldChild = 'fieldChild',
    fieldParentValue = 'fieldParentValue',
    isDisabled = 'isDisabled',
    placeholder = 'placeholder',
    errorMessage = 'errorMessage',
    isVisibleSwitch = 'isVisibleSwitch',
    isVisibleAny = 'isVisibleAny',
    textareaMinHeight = 'textareaMinHeight',
    textareaMaxLength = 'textareaMaxLength',
    column = 'column',
    isTouched = 'isTouched',
    isFullWidth = 'isFullWidth',
    extraHandle = 'extraHandle',
    templateButtons = 'templateButtons',
    checkboxItems = 'checkboxItems',
    onClick = 'onClick',
    href = 'href',
}

export type checkbox = {
    field: number
    value: boolean
}

export type carInput = {
    field: carFields
    fieldType: fieldTypes
    value?: string | number | boolean | File[] | checkbox[]
    valueType: valueTypes
    title: string
    specialProps?: carSpecialProps
}

export type carState = {
    fields: carInput[]
}

export enum carCreateUpdateActionTypes {
    updateField = 'updateField',
    updateManyFields = 'updateManyFields',
    initializeState = 'initializeState',
    updateSpecialProps = 'updateSpecialProps',
    addFiles = 'addFiles',
    removeFile = 'removeFile',
    reorderFiles = 'reorderFiles',
    updateChecboxGroup = 'updateChecboxGroup',
    updateFieldTypeToVoid = 'updateFieldTypeToVoid',
}

export type ApiCarCreateUpdate = {
    id: string
    markId: number
    modelId: number
    modificationId: number
    bodyTypeId: number
    year: number
    vinCode: string
    price: number
    description: string
    mileage: number
    isArchived: boolean
    isDeleted: boolean
    userId: string
    updatedAt?: string
    createdAt?: string
    descTemplate?: string
    extraData?: CarExtraData
}

export type CarListPageParams = {
    carId?: string
}
