import React from 'react'

import styles from './styles.module.scss'

interface IProps {
    text: string
    status: string
}

const BadgeStatus = ({ text, status }: IProps) => {
    let color = '#ddd'
    let fontColor = '#555'
    switch (status) {
        case 'reserved':
            // серая
            color = '#ddd'
            fontColor = '#555'
            break
        case 'partly_paid':
            // желтая
            color = '#ffc107'
            fontColor = '#fff'
            break
        case 'paid':
            // светло-зеленая
            color = '#dfd'
            fontColor = '#198754'
            break
        case 'ready':
            // синесереневая
            color = '#ddf'
            fontColor = '#00389c'
            break
        case 'issued':
            // темно-зеленая
            color = '#198754'
            fontColor = '#fff'
            break
        case 'canceled':
            color = '#fdd'
            fontColor = '#eb4f4f'
            break
    }

    return (
        <>
            <span style={{ backgroundColor: color, color: fontColor }} className={styles.wrap}>
                {text}
            </span>
        </>
    )
}

export default BadgeStatus
