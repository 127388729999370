import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { PlusLg } from 'react-bootstrap-icons'
import { browserName } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'

import ListNoItems from '../../components/_atoms/ListNoItems/ListNoItems'
import ListPagination from '../../components/_atoms/ListPagination/ListPagination'
import ListSkeleton from '../../components/_atoms/ListSkeleton/ListSkeleton'
import SalesButton from '../../components/_atoms/SalesButton/SalesButton'
import SalesText from '../../components/_atoms/SalesText/SalesText'
import ModalAddWarehouse from '../../components/_molecules/ModalAddWarehouse/ModalAddWarehouse'
import ModalEditWarehouse from '../../components/_molecules/ModalEditWarehouse/ModalEditWarehouse'
import CellActionsWarehouses from '../../components/_orgamisms/CellActionsWarehouses/CellActionsWarehouses'
import { colors, fontSizes, fontWeights } from '../../constants/salesStyles'
import LoadWarehouseList from '../../modules/redux/warehouseList/LoadWarehouseList'
import { useDispatch } from '../../modules/store/customDispatch'
import { useSelector } from '../../modules/store/customSelector'
import { Warehouse } from '../../types/warehouse/warehouseTypes'
import { colWidth } from '../../utils/stylePatches'

import styles from './styles.module.scss'

const WarehouseList = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [page, setPage] = useState(1)
    const [perPage] = useState(50)

    const [isModalsOpen, setIsModalsOpen] = useState({
        add: false,
        edit: false,
    })
    const [currentWarehouse, setCurrentWarehouse] = useState<Warehouse | null>(null)

    const itemsCount = useSelector(state => state.warehouseList.warehouseList.count || 0)
    const isLoading = useSelector(state => state.warehouseList.isLoading)
    const warehouses = useSelector(state => state.warehouseList.warehouseList.warehouses || [])

    useEffect(() => {
        dispatch(LoadWarehouseList(page, perPage))
    }, [page, perPage])

    const openWarehouseParts = (warehouse: Warehouse) => {
        setCurrentWarehouse(warehouse)
        const queryParams = new URLSearchParams(location.search)

        if (warehouse.id) {
            queryParams.set('wh', '' + warehouse.id)
        }
        navigate(`/admin/parts?${queryParams.toString()}`)
    }

    const openEditModal = (warehouse: Warehouse) => {
        setCurrentWarehouse(warehouse)
        setIsEditModalOpen(true)
    }

    const setIsAddModalOpen = (isOpen: boolean) => {
        setIsModalsOpen({
            ...isModalsOpen,
            add: isOpen,
        })
    }

    const setIsEditModalOpen = (isOpen: boolean) => {
        setIsModalsOpen({
            ...isModalsOpen,
            edit: isOpen,
        })
    }

    return (
        <div className={styles.wrap}>
            <div className={styles.titleWrap}>
                <SalesText
                    text={'Склады'}
                    fontWeight={fontWeights.bold}
                    fontSize={fontSizes.xxl}
                    letterSpacing={-0.02}
                />
                <SalesButton
                    borderRadius={10}
                    backgroundColor={colors.blueLight}
                    paddingHorizontalPixels={20}
                    paddingVerticalPixels={6}
                    onClick={() => setIsAddModalOpen(true)}
                >
                    <>
                        <PlusLg size={24} color={colors.white} />
                        <SalesText
                            text={'Добавить склад'}
                            marginLeftPixels={10}
                            fontSize={fontSizes.s}
                            color={colors.white}
                        />
                    </>
                </SalesButton>
                <div className={styles.wrapRight}>
                    {!isLoading && warehouses.length === 0 ? (
                        <></>
                    ) : (
                        <ListPagination page={page} setPage={setPage} perPage={perPage} itemsCount={itemsCount} />
                    )}
                </div>
            </div>

            {isModalsOpen.add && (
                <ModalAddWarehouse isOpen={isModalsOpen.add} closeModal={() => setIsAddModalOpen(false)} />
            )}
            {isModalsOpen.edit && (
                <ModalEditWarehouse
                    warehouse={currentWarehouse as Warehouse}
                    isOpen={isModalsOpen.edit}
                    closeModal={() => setIsEditModalOpen(false)}
                />
            )}
            <Table striped hover>
                <thead>
                    <tr>
                        <th scope="col" style={colWidth(1)}>
                            №
                        </th>
                        <th scope="col" style={colWidth(350)}>
                            Название
                        </th>
                        <th scope="col" style={colWidth(350)}>
                            Адрес
                        </th>
                        <th scope="col" style={colWidth(200)}>
                            Телефон
                        </th>
                        <th scope="col" style={colWidth(1)}>
                            Префикс Склада
                        </th>
                        <th scope="col" style={colWidth(60)}>
                            Кол.
                        </th>
                        <th scope="col" style={colWidth(1)}>
                            Действия
                        </th>
                    </tr>
                </thead>
                {isLoading || !warehouses ? (
                    <ListSkeleton rowCount={5} columnCount={7} marginVerticalRem={0.21} />
                ) : (
                    <tbody>
                        {warehouses.map((warehouse, index) => (
                            <tr key={index} className={styles.row}>
                                <td scope="row" onClick={e => openWarehouseParts(warehouse)}>
                                    {++index}
                                </td>
                                <td scope="row" onClick={e => openWarehouseParts(warehouse)}>
                                    {warehouse.title}
                                </td>
                                <td scope="row" onClick={e => openWarehouseParts(warehouse)}>
                                    {warehouse.address}
                                </td>
                                <td scope="row" onClick={e => openWarehouseParts(warehouse)}>
                                    {warehouse.phone}
                                </td>
                                <td scope="row" onClick={e => openWarehouseParts(warehouse)}>
                                    {warehouse.vendorPrefix}
                                </td>
                                <td scope="row" onClick={() => openWarehouseParts(warehouse)}>
                                    {warehouse.partCount}
                                </td>
                                <td scope="row">
                                    <CellActionsWarehouses
                                        warehouse={warehouse}
                                        isSafari={browserName === 'Safari'}
                                        editModal={() => openEditModal(warehouse)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                )}
            </Table>

            {!isLoading && warehouses.length === 0 ? (
                <ListNoItems message="Попробуйте добавить склад" />
            ) : (
                <ListPagination
                    page={page}
                    setPage={setPage}
                    perPage={perPage}
                    itemsCount={itemsCount}
                    justifyContent={'flex-end'}
                />
            )}
        </div>
    )
}

export default WarehouseList
