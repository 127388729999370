import { Dispatch } from 'redux'

import anchor from '../../../constants/anchor'
import { apiInstance } from '../../api/apiInstance'
import { RootState } from '../../store/rootReducer'
import { storeDispatchTypes } from '../../store/storeDispatchTypes'

const setPartQuantity =
    (partId: string, qty: number) =>
    async (dispatch: Dispatch, getState: () => RootState): Promise<boolean> => {
        const { userData } = getState()
        try {
            const {
                data: { status },
            } = await apiInstance.post(
                '/setPartExtraDataFieldValue',
                {
                    userId: userData.smsAuth.userId,
                    partId,
                    extraDataField: 'qty',
                    extraDataValue: qty,
                },
                {
                    headers: {
                        Authorization: `${anchor} ${localStorage.getItem('accessToken')}`,
                    },
                },
            )

            if (status === 'ok') {
                dispatch({
                    type: storeDispatchTypes.updatePartExtraDataQuantity,
                    value: {
                        id: partId,
                        qty: qty,
                    },
                })
                return true
            } else {
                return false
            }
        } catch (e) {
            console.error('setPartQuantity failed', e)
            return false
        }
    }

export default setPartQuantity
