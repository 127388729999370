import { useEffect, useState } from 'react'
import { Button, Form, Modal, Col, Row } from 'react-bootstrap'

import addPartsToInvoice from '../../../modules/redux/documentList/AddPartsToInvoice'
import createIncomingInvoice from '../../../modules/redux/documentList/CreateIncomingInvoice'
import getDocumentsSelectSearch from '../../../modules/redux/documentList/GetDocumentsSelectSearch'
import getIncomingInvoicesByPartsId from '../../../modules/redux/documentList/GetIncomingInvoicesByPartsId'
import { useDispatch } from '../../../modules/store/customDispatch'
import { useSelector } from '../../../modules/store/customSelector'
import { EDocumentType } from '../../../types/document/documentTypes'
import { Part } from '../../../types/part/partTypes'

import styles from './styles.module.scss'

interface IProps {
    parts: Part[]
    isOpen: boolean
    closeModal: () => void
}

const ModalIncomingInvoiceOpen = ({ parts, isOpen, closeModal }: IProps) => {
    const dispatch = useDispatch()

    const [number, setNumber] = useState('')
    const [providerName, setProviderName] = useState('')
    const [providerAddress, setProviderAddress] = useState('')
    const [providerTelephone, setProviderTelephone] = useState('')
    const [comment, setComment] = useState('')
    const [selectedDocumentId, setSelectedDocumentId] = useState<string>('')
    const [disabled, setDisabled] = useState(false)

    const documents = useSelector(state => state.documentList.documents)

    const onCloseModal = () => {
        closeModal()
    }

    const confirmChange = async () => {
        const partsId = parts.map(part => part.id)
        const document = {
            title: number,
            partsId,
            extraData: {
                number,
                providerName,
                providerAddress,
                providerTelephone,
                comment,
            },
        }

        if (selectedDocumentId) {
            await dispatch(addPartsToInvoice({ partsId, id: selectedDocumentId }))
        } else {
            await dispatch(createIncomingInvoice(document))
        }
        dispatch(getIncomingInvoicesByPartsId(partsId))
        closeModal()
    }

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value
        newValue ? setDisabled(true) : setDisabled(false)
        setSelectedDocumentId(newValue)
    }

    useEffect(() => {
        dispatch(getDocumentsSelectSearch(EDocumentType.IncomingInvoice))
    }, [])

    return (
        <>
            <Modal show={isOpen} onHide={onCloseModal} centered dialogClassName={styles.modalDocument}>
                <Modal.Body className={styles.modalBody}>
                    <Form>
                        <h4>
                            <b>Укажите данные для приходной накладной</b>
                        </h4>
                        {documents.length != 0 && (
                            <>
                                <Form.Group as={Row} className="mb-3" controlId="formDocument">
                                    <Form.Label column sm="5">
                                        Добавить в существующую
                                    </Form.Label>
                                    <Col sm="7">
                                        <Form.Select value={selectedDocumentId} onChange={handleSelectChange}>
                                            <option value={''}>Не выбрано</option>
                                            {documents.map(document => (
                                                <option key={document.value} value={document.value}>
                                                    {document.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                            </>
                        )}
                        <h4>
                            <b>Создать новую</b>
                        </h4>
                        <Form.Group as={Row} className="mb-3" controlId="formNumber">
                            <Form.Label column sm="5">
                                Номер
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control onChange={e => setNumber(e.target.value)} disabled={disabled} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formProviderName">
                            <Form.Label column sm="5">
                                Название поставщика
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control onChange={e => setProviderName(e.target.value)} disabled={disabled} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formProviderAddress">
                            <Form.Label column sm="5">
                                Адрес поставщика
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control onChange={e => setProviderAddress(e.target.value)} disabled={disabled} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formProviderTelephone">
                            <Form.Label column sm="5">
                                Телефон поставщика
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control
                                    onChange={e => setProviderTelephone(e.target.value)}
                                    disabled={disabled}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formComment">
                            <Form.Label column sm="5">
                                Комментарий
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control
                                    as="textarea"
                                    onChange={e => setComment(e.target.value)}
                                    disabled={disabled}
                                />
                            </Col>
                        </Form.Group>
                        <div className="d-flex justify-content-end">
                            <Button onClick={confirmChange}>Сохранить</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalIncomingInvoiceOpen
