import { useState, useEffect, useCallback } from 'react'

import { Table } from 'react-bootstrap'
import { useNavigate, useSearchParams } from 'react-router-dom'

import Badge from '../../components/_atoms/Badge/Badge'
import BadgeStatus from '../../components/_atoms/BadgeStatus/BadgeStatus'
import NumChange from '../../components/_atoms/NumChange/NumChange'
import ListNoItems from '../../components/_atoms/ListNoItems/ListNoItems'
import ListPagination from '../../components/_atoms/ListPagination/ListPagination'
import ListSkeleton from '../../components/_atoms/ListSkeleton/ListSkeleton'
import SalesText from '../../components/_atoms/SalesText/SalesText'
import ColumnSortRTK from '../../components/_molecules/ColumnSort/ColumnSortRTK'
import SearchComponentRTK from '../../components/_molecules/SearchComponent/SearchComponentRTK'
import { fontSizes, fontWeights } from '../../constants/salesStyles'
import { useGetActionLogListQuery } from '../../modules/store/datahubApi/datahubApi'
import { adminRouteAlias, getAdminNavigationPath } from '../../router/adminRouteAlias'
import {
    useGetDealStatusDictQuery
} from '../../modules/store/dochubApi/dochubApi'
import { SortFieldActionLogs, SortIconType, SortOrder } from '../../types/common/commonTypes'
import { countDaysInStock, formatDate, num_word } from '../../utils/converters'
import { colWidth } from '../../utils/stylePatches'

import styles from './styles.module.scss'
import { DealStatus } from '../../modules/store/dochubApi/dochubTypes'
import CustomLink from '../../components/_atoms/CustomLink/CustomLink'
import ArrowIconButton from '../../components/_atoms/ArrowIconButton/ArrowIconButton'

// Типизация для фильтров
interface PriceRange {
    from: number
    to: number
}

interface ActionLogFilters {
    page: number
    perPage: number
    filterVendorCode: string
    partName: string
    priceRange: PriceRange | null
    modelId: number | null
    markId: number | null
    partNumber: string
    // sortOrder хранится как массив кортежей: [sortField, SortOrder]
    sortOrder: [string, SortOrder][]
}

const initialFilters: ActionLogFilters = {
    page: 1,
    perPage: 50,
    filterVendorCode: '',
    partName: '',
    priceRange: null,
    modelId: null,
    markId: null,
    partNumber: '',
    sortOrder: [],
}

const ActionLog = () => {
    const navigate = useNavigate()

    // Читаем параметр "page" из URL
    const [searchParams, setSearchParams] = useSearchParams()
    const searchPage = Number(searchParams.get('page')) || 1

    // Инициализируем локальное состояние фильтров с учетом номера страницы из URL
    const [filters, setFilters] = useState<ActionLogFilters>({
        ...initialFilters,
        page: searchPage,
    })
    const { page, perPage, filterVendorCode, partName, priceRange, modelId, markId, partNumber, sortOrder } = filters
    const priceFrom = priceRange?.from
    const priceTo = priceRange?.to

    const {
        data: dealStatusDictData,
        isLoading: isLoadingGetDealStatusDict,
        isError: isErrorGetDealStatusDict,
    } = useGetDealStatusDictQuery()

    const openDeal = useCallback(
        (dealId: string) => {
            navigate(getAdminNavigationPath(adminRouteAlias.deals.edit) + `/${dealId}`)
        },
        [navigate],
    )

    // Синхронизируем URL с локальным состоянием, обновляем URL только, если значение отличается
    useEffect(() => {
        const currentParam = searchParams.get('page')
        if (currentParam !== String(page)) {
            setSearchParams(prev => {
                const newParams = new URLSearchParams(prev)
                newParams.set('page', String(page))
                return newParams
            })
        }
    }, [page, searchParams, setSearchParams])

    // Запрашиваем данные через RTK Query, при монтировании и изменении аргументов происходит refetch
    const { data, isLoading, isFetching, error } = useGetActionLogListQuery({
        objectType: 'part',
        pagination: { page, perPage },
        order: sortOrder,
        modelId,
        markId,
        search: partName,
        priceRange: priceRange ? { from: priceFrom, to: priceTo } : undefined,
        partNumber,
        filterVendorCode,
    }, {
        refetchOnMountOrArgChange: true,
    })

    // Извлекаем массив логов и общее количество
    const actionLogList = data?.data?.data || []
    const itemsCount = data?.data?.dataCount || 0

    const getDifference = (difference: number) =>
        difference > 0 ? `+${difference.toLocaleString('ru-RU')}` : `${difference.toLocaleString('ru-RU')}`

    if (error) {
        return <div>Ошибка загрузки данных</div>
    }

    // Вычисляем текущую сортировку для поля "Дата изменения"
    const currentSortOrderForDate =
        filters.sortOrder.find(item => item[0] === SortFieldActionLogs.UPDATED_AT)?.[1] || SortOrder.NONE

    return (
        <div className={styles.wrap}>
            <div className={styles.rowWrap}>
                <SalesText
                    text={'Журнал действий'}
                    fontWeight={fontWeights.bold}
                    fontSize={fontSizes.xxl}
                    letterSpacing={-0.02}
                />
            </div>

            {/* Компонент поиска, обновляет фильтр partName и сбрасывает страницу на 1 */}
            <div className={styles.searchWrap}>
                <SearchComponentRTK
                    searchTerm={filters.partName}
                    setSearchTerm={(newTerm) =>
                        setFilters(prev => ({ ...prev, partName: newTerm, page: 1 }))
                    }
                    placeholder="Поиск по журналу"
                />
            </div>

            <Table striped hover>
                <thead>
                    <tr>
                        <th scope="col" style={colWidth(100)}>
                            Дата
                            <ColumnSortRTK
                                iconType={SortIconType.NUMERIC}
                                sortField={SortFieldActionLogs.UPDATED_AT}
                                reverseSort={true}
                                currentSortOrder={currentSortOrderForDate}
                                onSortChange={(field, newOrder) => {
                                    setFilters(prev => {
                                        const newSort = prev.sortOrder.filter(item => item[0] !== field)
                                        if (newOrder !== SortOrder.NONE) {
                                            newSort.push([field, newOrder])
                                        }
                                        return { ...prev, sortOrder: newSort, page: 1 }
                                    })
                                }}
                            />
                        </th>
                        <th scope="col" style={colWidth(100)}>
                            Аккаунт
                        </th>
                        <th scope="col" style={colWidth(100)}>
                            Тип Действия
                        </th>
                        <th scope="col" style={colWidth(250)}>
                            Запчасть
                        </th>
                        <th scope="col" style={colWidth(100)}>
                            Марка
                        </th>
                        <th scope="col" style={colWidth(100)}>
                            Модель
                        </th>
                        <th scope="col" style={colWidth(100)}>
                            Артикул
                        </th>
                        <th scope="col" style={colWidth(100)}>
                            OEM
                        </th>
                        <th scope="col" style={colWidth(100)}>
                            Цена
                        </th>
                        <th scope="col" style={colWidth(250)}>
                            Изменения
                        </th>
                    </tr>
                </thead>
                {(isLoading || isFetching) ? (
                    <ListSkeleton rowCount={9} columnCount={10} marginVerticalRem={0.21} />
                ) : (
                    <tbody>
                        {actionLogList.map((actionLog: any, index: number) => (
                            <tr key={index} className={styles.row}>
                                <td scope="row">
                                    <div>
                                        <Badge text={formatDate(actionLog.updatedAt)} color="#ddd" fontColor="#555" />
                                    </div>
                                    {+countDaysInStock(`${actionLog.updatedAt}`) > 10 && (
                                        <div>
                                            <Badge
                                                text={
                                                    countDaysInStock(`${actionLog.updatedAt}`) +
                                                    ' ' +
                                                    num_word(+countDaysInStock(`${actionLog.updatedAt}`), ['день', 'дня', 'дней'])
                                                }
                                                color="#eee"
                                                fontColor="#777"
                                            />
                                        </div>
                                    )}
                                </td>
                                <td scope="row">{actionLog.details?.telephoneNumber}</td>
                                <td scope="row">
                                    <div>
                                        {actionLog.actionTypeText}
                                        {actionLog.details?.deal && (
                                            <ArrowIconButton
                                                onClick={() => openDeal(actionLog.details.deal.id)}
                                                marginLeft={4}
                                                marginRight={4}
                                                color="#777"
                                            />)}
                                    </div>
                                </td>
                                <td scope="row">{actionLog.objectData?.title}</td>
                                <td scope="row">{actionLog.objectData?.markTitle}</td>
                                <td scope="row">{actionLog.objectData?.modelTitle}</td>
                                <td scope="row">{actionLog.objectData?.vendorCode}</td>
                                <td scope="row">{actionLog.objectData?.partNumber}</td>
                                <td scope="row">{actionLog.objectData?.price}</td>
                                <td scope="row">
                                    {actionLog.details?.qty && (
                                        <div>Кол.: <NumChange change={actionLog.details.qty} /></div>
                                    )}
                                    {actionLog.details?.deal && (
                                        <div className={styles.dealChange}>
                                            <div>В сделке:</div>
                                            {actionLog.details?.deal?.price?.after && (
                                                <div>Цена: <NumChange change={actionLog.details.deal.price} /></div>
                                            )}
                                            {actionLog.details?.deal?.qty?.after && (
                                                <div>Кол.: <NumChange change={actionLog.details.deal.qty} /></div>
                                            )}
                                            {actionLog.details?.deal?.status && (
                                                <div>Статус: <BadgeStatus
                                                    text={
                                                        dealStatusDictData
                                                            ? dealStatusDictData.data[actionLog.details?.deal?.status as DealStatus]?.name || '---'
                                                            : actionLog.details?.deal?.status
                                                    }
                                                    status={actionLog.details?.deal?.status}
                                                /></div>
                                            )}
                                        </div>
                                    )}
                                    {actionLog.details?.price && (
                                        <div>Цена: <NumChange change={actionLog.details.price} /></div>
                                    )}
                                    {actionLog.details?.soldPrice && (
                                        <div>Цена продажи: {actionLog.details.soldPrice.toLocaleString('ru-RU')}</div>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                )}
            </Table>
            {(!isLoading && actionLogList.length === 0) ? (
                <ListNoItems slice={'actionLogList'} />
            ) : (
                <ListPagination
                    page={page}
                    setPage={(newPage: number) => setFilters(prev => ({ ...prev, page: newPage }))}
                    perPage={perPage}
                    itemsCount={itemsCount}
                    justifyContent={'flex-end'}
                />
            )}
        </div>
    )
}

export default ActionLog
