import React from 'react'
import { Button, Table } from 'react-bootstrap'
import { Form } from 'react-bootstrap'

import Badge from '../../../components/_atoms/Badge/Badge'
import CategoryIcon from '../../../components/_atoms/CategogyIcon/CategoryIcon'
import CopyButton from '../../../components/_atoms/CopyButton/CopyButton'
import ListSkeleton from '../../../components/_atoms/ListSkeleton/ListSkeleton'
import CellThumbCarousel from '../../../components/_orgamisms/CellThumbCarousel/CellThumbCarousel'
import { Part } from '../../../types/part/partTypes'
import { countDaysInStock, formatDate, num_word } from '../../../utils/converters'
import { formatPrice, unformatPrice } from '../../../utils/formatAndUnformatPrice'
import { colWidth } from '../../../utils/stylePatches'

import styles from './styles.module.scss'

interface Props {
    parts: Part[]
    removePart: (part: Part) => void
    updatePartData: (id: string, field: 'price' | 'qty', value: number) => void
    partsData: Record<string, { price: number; qty: number }>
    isLoading: boolean
    onPartClick: (part: Part) => void
}

export const PartsTable = ({ parts, removePart, updatePartData, partsData, isLoading, onPartClick }: Props) => (
    <Table striped hover>
        <thead>
            <tr>
                <th scope="col" style={colWidth(80)}>
                    Кат.
                </th>
                <th scope="col" style={colWidth(204)}>
                    Запчасть
                </th>
                <th scope="col" style={colWidth(100)}>
                    Марка
                </th>
                <th scope="col" style={colWidth(200)}>
                    Модель
                </th>
                <th scope="col" style={colWidth(35)}>
                    Фото
                </th>
                <th scope="col" style={colWidth(130)}>
                    Цена
                </th>
                <th scope="col" style={colWidth(135)}>
                    Артикул
                </th>
                <th scope="col" style={colWidth(103)}>
                    OEM
                </th>
                <th scope="col" style={colWidth(85)}>
                    Дата
                </th>
                <th scope="col" style={colWidth(80)}>
                    Кол.
                </th>
                <th scope="col" style={colWidth(80)}>
                    Действия
                </th>
            </tr>
        </thead>

        {isLoading ? (
            <ListSkeleton rowCount={9} columnCount={11} marginVerticalRem={0.21} />
        ) : (
            <tbody className={styles.body}>
                {parts.map((part, index) => (
                    <tr key={index} className={styles.row}>
                        <td scope="row" onClick={() => onPartClick(part)}>
                            <CategoryIcon categoryId={part.groupId} />
                        </td>
                        <td scope="row" onClick={() => onPartClick(part)}>
                            {part.customTitle ? part.customTitle : part.title}
                        </td>
                        <td scope="row" onClick={() => onPartClick(part)}>
                            {part.markTitle}
                        </td>
                        <td scope="row" onClick={() => onPartClick(part)}>
                            {part.modelTitle}
                            {part.modificationTitle ? (
                                <div>
                                    <Badge text={part.modificationTitle} color="#ddd" fontColor="#555" />
                                </div>
                            ) : (
                                ''
                            )}
                            {part.carVinCode ? (
                                <div>
                                    <Badge text={'vin: ' + part.carVinCode} color="#aaa" />
                                </div>
                            ) : (
                                ''
                            )}
                        </td>
                        <td scope="row" className={styles.imageRow}>
                            <CellThumbCarousel pictures={part.images} part={part} />
                        </td>
                        <td scope="row">
                            <Form.Control
                                size="sm"
                                type="text"
                                value={formatPrice(partsData[part.id]?.price ?? part.price)}
                                onChange={e => {
                                    const rawValue = unformatPrice(e.target.value)
                                    const numericValue = Number(rawValue)
                                    updatePartData(part.id, 'price', numericValue)
                                    e.target.value = formatPrice(numericValue)
                                }}
                            />
                        </td>
                        <td scope="row" className={styles.default}>
                            <CopyButton
                                copyText={part.vendorCodePrefix + part.vendorCode}
                                overlayText={'Артикул скопирован в буфер обмена'}
                                isText
                            />
                        </td>
                        <td scope="row" className={styles.default}>
                            <CopyButton
                                copyText={part.partNumber}
                                overlayText={'ОЕМ скопирован в буфер обмена'}
                                isText
                            />
                        </td>
                        <td scope="row" className={styles.alignRight}>
                            <div>
                                <Badge text={formatDate(part.createdAt)} color="#ddd" fontColor="#555" />
                            </div>
                            {+countDaysInStock(part.createdAt) > 10 ? (
                                <div>
                                    <Badge
                                        text={
                                            countDaysInStock(part.createdAt) +
                                            ' ' +
                                            num_word(+countDaysInStock(part.createdAt), ['день', 'дня', 'дней'])
                                        }
                                        color="#eee"
                                        fontColor="#777"
                                    />
                                </div>
                            ) : (
                                ''
                            )}
                        </td>
                        <td scope="row">
                            <Form.Control
                                size="sm"
                                type="number"
                                value={partsData[part.id]?.qty || 1}
                                min={1}
                                onChange={e => {
                                    updatePartData(part.id, 'qty', Number(e.target.value))
                                }}
                            />
                        </td>
                        <td scope="row" className={styles.default}>
                            <Button
                                variant={'secondary'}
                                size="sm"
                                style={{ width: 80 }}
                                onClick={() => removePart(part)}
                            >
                                x
                            </Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        )}
    </Table>
)
