import { useEffect, useReducer, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'

import conditions from '../../../constants/conditions'
import { fontSizes, fontWeights } from '../../../constants/salesStyles'
import StickersApiService from '../../../modules/api/StickersApiService'
import getCarsSelectSearchCarTitle from '../../../modules/redux/carList/getCarsSelectSearchCarTitle'
import getAllCarMarks from '../../../modules/redux/catalogs/getAllCarMarks'
import getAllCarModels from '../../../modules/redux/catalogs/getAllCarModels'
import getAllCarModifications from '../../../modules/redux/catalogs/getAllCarModifications'
import getAllOilsBrands from '../../../modules/redux/catalogs/getAllOilsBrands'
import getModificationYears from '../../../modules/redux/catalogs/getModificationYears'
import getDocumentsSelectSearch from '../../../modules/redux/documentList/GetDocumentsSelectSearch'
import getIncomingInvoicesByPartsId from '../../../modules/redux/documentList/GetIncomingInvoicesByPartsId'
import getApplicabilityByPartId from '../../../modules/redux/partList/GetApplicabilityByPartId'
import getPartPriceRecommendedChart from '../../../modules/redux/partList/getPartPriceRecommendedChart'
import getPartTypeByTypeId from '../../../modules/redux/partList/GetPartTypeByTypeId'
import getMarketplacesSettings from '../../../modules/redux/sales/getMarketplacesSettings'
import warehousesSelectSearch from '../../../modules/redux/warehouseList/WarehousesSelectSearch'
import { useDispatch } from '../../../modules/store/customDispatch'
import { useSelector } from '../../../modules/store/customSelector'
import { partReducer } from '../../../pages/NewPart/partReducer'
import { adminRouteAlias, getAdminNavigationPath } from '../../../router/adminRouteAlias'
import { carFields } from '../../../types/car/carTypes'
import { EDocumentType } from '../../../types/document/documentTypes'
import {
    Part,
    partFields,
    partInput,
    partCreateUpdateActionTypes,
    partSpecialPropsFields,
    fieldValuePair,
    IPriceChartResponse,
    MarketplacesKeys,
} from '../../../types/part/partTypes'
import { getFrontRearLeftRight } from '../../../utils/getFrontRearLeftRight'
import Loader from '../../_atoms/Loader/Loader'
import ModalImageGroup from '../../_atoms/ModalImageGroup/ModalImageGroup'
import OverlayWithText from '../../_atoms/OverlayWithText/OverlayWithText'
import SalesText from '../../_atoms/SalesText/SalesText'
import PartCreateUpdateTable from '../PartCreateUpdateTable/PartCreateUpdateTable'
import PriceChart from '../PriceChart/PriceChart'
import SalesCard from '../SalesCard/SalesCard'

import { initialPartState } from './initialPartState'
import styles from './styles.module.scss'

interface IProps {
    part: Part
    isOpen: boolean
    closeModal: () => void
}

const ModalViewPart = ({ part, isOpen, closeModal }: IProps) => {
    const title = 'Просмотр запчасти'
    const dispatchRedux = useDispatch()
    // @ts-ignore для structedClone
    const [state, dispatch] = useReducer(partReducer, structuredClone(initialPartState))
    const [isLoading, setIsLoading] = useState(true)
    const [isFilledState, setIsFilledState] = useState(false)
    const isPartLoading = useSelector(state => state.partList.isLoading.partList)

    const document = useSelector(state => state.documentList.document)

    const warehouses = useSelector(state => state.warehouseList.warehousesSS)
    const applicabilitySelected = useSelector(state => state.partList.applicability.applicabilitySelected)
    const partType = useSelector(state => state.partList.partType)
    const cars = useSelector(state => state.carList.cars)

    const [priceChartResponse, setPriceChartResponse] = useState<IPriceChartResponse | null>(null)
    const getPriceChartRes = async () => {
        const res = await getPartPriceRecommendedChart(part.typeId, part.markId, part.modelId, part.price || 0)
        setPriceChartResponse(res)
    }

    const getField = (fieldToSearch: carFields | partFields) =>
        state.fields.find((field: partInput) => field.field === fieldToSearch)

    const getCarNameFromExistingCar = () => getField(partFields.carId)?.specialProps?.renderValue.replace(',', '')
    const getCarNameFromWithoutCar = () => {
        const carModel = getField(carFields.modelId)?.specialProps?.renderValue
        const carMark = getField(carFields.markId)?.specialProps?.renderValue
        const carYear = getField(carFields.year)?.value

        if (carModel && carMark && carYear) {
            return `${carMark} ${carModel} ${carYear}`.replace(',', '')
        }
    }

    const getCustomTitle = () => {
        const currentSwitch = getField(partFields.useExistingCar)?.value

        const carField = getField(partFields.carId)
        const car = cars.find(car => car.value === carField.value)

        const carNameFromExistingCar = car?.customTitle ?? getCarNameFromExistingCar()
        const carNameFromWithoutCar = getCarNameFromWithoutCar()
        const partName = getField(partFields.typeId)?.specialProps?.renderValue

        if (carNameFromExistingCar && partName && currentSwitch === 1) {
            return partName + ' ' + carNameFromExistingCar
        } else if (partName && currentSwitch === 2 && carNameFromWithoutCar) {
            return `${partName} ${carNameFromWithoutCar}`.replace(',', '')
        } else if (partName && currentSwitch === 0) {
            return `${partName}`
        } else {
            return ''
        }
    }

    const getVisibleAny = (isVisibleAnyFields: fieldValuePair[]) => {
        let isVisible = false
        isVisibleAnyFields.forEach((item: fieldValuePair) => {
            if (item.value && getField(item.field).value === item.value) {
                isVisible = true
                return
            }
            if (item.not && getField(item.field).value === item.not) {
                isVisible = false
                return
            }
        })
        return isVisible
    }

    const getVisibleEvery = (isVisibleAllFields: fieldValuePair[][]) => {
        let isVisible = true
        for (const array of isVisibleAllFields) {
            if (!getVisibleAny(array)) isVisible = false
        }
        return isVisible
    }

    const getVisibleFields = () =>
        state.fields.filter((field: partInput) => {
            if (field.specialProps?.isVisibleEvery) {
                return getVisibleEvery(field.specialProps?.isVisibleEvery)
            } else if (field.specialProps?.isVisibleSwitch && field.specialProps?.isVisibleAny) {
                return (
                    getVisibleAny(field.specialProps?.isVisibleSwitch) &&
                    getVisibleAny(field.specialProps?.isVisibleAny)
                )
            } else if (field.specialProps?.isVisibleSwitch && !field.specialProps?.isVisibleAny) {
                return getVisibleAny(field.specialProps?.isVisibleSwitch)
            } else if (field.specialProps?.isVisibleAny && !field.specialProps?.isVisibleSwitch) {
                return getVisibleAny(field.specialProps?.isVisibleAny)
            } else {
                return true
            }
        })

    const getVisibleColumn = (column: number) =>
        getVisibleFields().filter((field: partInput) => field.specialProps?.column === column)

    const onCloseModal = (e?: React.MouseEvent) => {
        e?.preventDefault()
        closeModal()
    }

    const printPart = () => {
        const url = StickersApiService.getEncodeUrlFromPart(part)
        if (url) window.open(url, '_blank')
    }

    const fillField = (field: partInput, value: number | string, renderValue?: string | number | null) => {
        dispatch({
            type: partCreateUpdateActionTypes.updateField,
            value: {
                field: field.field,
                value,
                specialProps: {
                    ...field.specialProps,
                    [partSpecialPropsFields.renderValue]: renderValue,
                },
            },
        })
    }

    const fillInitialState = () => {
        if (part.markId) {
            dispatchRedux(getAllCarMarks(part.markTitle))
            dispatchRedux(getAllCarModels(part.modelTitle, String(part.markId)))
            dispatchRedux(getAllCarModifications(part.modificationTitle, String(part.modelId)))
            dispatchRedux(getModificationYears(String(part.year), String(part.modificationId)))
        }

        if (part.carId) {
            dispatchRedux(getCarsSelectSearchCarTitle(`${part.markTitle} ${part.modelTitle} ${part.year}`))
        }

        state.fields.map((field: partInput) => {
            switch (field.field) {
                case partFields.useExistingCar:
                    if (part.carId || part.markId) {
                        dispatch({
                            type: partCreateUpdateActionTypes.updateField,
                            value: {
                                field: field.field,
                                value: part.carId ? 1 : 2,
                            },
                        })
                    }
                    break
                case partFields.carId:
                    if (part.carId) fillField(field, part.carId, `${part.markTitle} ${part.modelTitle} ${part.year}`)
                    break
                case partFields.markId:
                    if (part.markId) fillField(field, part.markId, part.markTitle)
                    break
                case partFields.modelId:
                    if (part.modelId) fillField(field, part.modelId, part.modelTitle)
                    break
                case partFields.modificationId:
                    if (part.modificationId) fillField(field, part.modificationId, part.modificationTitle)
                    break
                case partFields.year:
                    if (part.year) fillField(field, part.year, String(part.year))
                    break
                case partFields.bodycars:
                    part.extraData?.bodycars && fillField(field, part.extraData.bodycars, part.extraData.bodycars)
                    break
                case partFields.engine:
                    part.extraData?.engine && fillField(field, part.extraData.engine, part.extraData.engine)
                    break
                case partFields.groupId:
                    if (part.groupId) fillField(field, part.groupId, part.groupTitle)
                    break
                case partFields.typeId:
                    if (part.typeId) fillField(field, part.typeId, part.title)
                    break
                case partFields.customTitle: {
                    fillField(field, part.customTitle, part.customTitle)
                    break
                }
                case partFields.Quantity:
                    if (part.extraData?.Quantity)
                        fillField(field, part.extraData.Quantity, String(part.extraData.Quantity))
                    break
                case partFields.TireType:
                    if (part.extraData?.TireType)
                        fillField(field, part.extraData.TireType, String(part.extraData.TireType))
                    break
                case partFields.RimType:
                    if (part.extraData?.RimType)
                        fillField(field, part.extraData.RimType, String(part.extraData.RimType))
                    break
                case partFields.Brand:
                    if (part.extraData?.Brand) fillField(field, part.extraData.Brand, part.extraData.Brand)
                    break
                case partFields.TireBrand:
                    if (part.extraData?.Brand) fillField(field, part.extraData.Brand, part.extraData.Brand)
                    break
                case partFields.TireModel:
                    if (part.extraData?.TireModel) fillField(field, part.extraData.TireModel, part.extraData.TireModel)
                    break
                case partFields.RimDiameter:
                    if (part.extraData?.RimDiameter)
                        fillField(field, part.extraData.RimDiameter, String(part.extraData.RimDiameter))
                    break
                case partFields.TireSectionWidth:
                    if (part.extraData?.TireSectionWidth)
                        fillField(field, part.extraData.TireSectionWidth, String(part.extraData.TireSectionWidth))
                    break
                case partFields.TireAspectRatio:
                    if (part.extraData?.TireAspectRatio)
                        fillField(field, part.extraData.TireAspectRatio, String(part.extraData.TireAspectRatio))
                    break
                case partFields.TireRuptureQuantity:
                    if (part.extraData?.TireRuptureQuantity)
                        fillField(field, part.extraData.TireRuptureQuantity, String(part.extraData.TireRuptureQuantity))
                    break
                case partFields.TireSideRepairQuantity:
                    if (part.extraData?.TireSideRepairQuantity)
                        fillField(
                            field,
                            part.extraData.TireSideRepairQuantity,
                            String(part.extraData.TireSideRepairQuantity),
                        )
                    break
                case partFields.OtherDefects:
                    if (part.extraData?.OtherDefects)
                        fillField(field, part.extraData.OtherDefects, String(part.extraData.OtherDefects))
                    break
                case partFields.RimWidth:
                    if (part.extraData?.RimWidth)
                        fillField(field, part.extraData.RimWidth, String(part.extraData.RimWidth))
                    break
                case partFields.RimOffset:
                    if (part.extraData?.RimOffset)
                        fillField(field, part.extraData.RimOffset, String(part.extraData.RimOffset))
                    break
                case partFields.RimBolts:
                    if (part.extraData?.RimBolts)
                        fillField(field, part.extraData.RimBolts, String(part.extraData.RimBolts))
                    break
                case partFields.RimBoltsDiameter:
                    if (part.extraData?.RimBoltsDiameter)
                        fillField(field, part.extraData.RimBoltsDiameter, String(part.extraData.RimBoltsDiameter))
                    break
                case partFields.RimDIA:
                    if (part.extraData?.RimDIA) fillField(field, part.extraData.RimDIA, String(part.extraData.RimDIA))
                    break
                case partFields.OilBrand:
                    if (part.extraData?.Brand) {
                        dispatchRedux(getAllOilsBrands(part.extraData.Brand, String(part.typeId)))
                        fillField(field, part.extraData.Brand, part.extraData.Brand)
                    }
                    break
                case partFields.SAE:
                    if (part.extraData?.SAE) fillField(field, part.extraData.SAE, part.extraData.SAE)
                    break
                case partFields.Volume:
                    if (part.extraData?.Volume) fillField(field, part.extraData.Volume, part.extraData.Volume)
                    break
                case partFields.ACEA:
                    if (part.extraData?.ACEA) fillField(field, part.extraData.ACEA, part.extraData.ACEA)
                    break
                case partFields.API:
                    if (part.extraData?.API) fillField(field, part.extraData.API, part.extraData.API)
                    break
                case partFields.OEMOil:
                    if (part.extraData?.OEMOil) fillField(field, part.extraData.OEMOil, part.extraData.OEMOil)
                    break
                case partFields.Color:
                    if (part.extraData?.Color) fillField(field, part.extraData.Color, part.extraData.Color)
                    break
                case partFields.ASTM:
                    if (part.extraData?.ASTM) fillField(field, part.extraData.ASTM, part.extraData.ASTM)
                    break
                case partFields.DOT:
                    if (part.extraData?.DOT) fillField(field, part.extraData.DOT, part.extraData.DOT)
                    break
                case partFields.conditionId:
                    if (part.conditionId)
                        fillField(
                            field,
                            part.conditionId,
                            conditions.find(condition => condition.id === part.conditionId)?.title ||
                                String(part.conditionId),
                        )
                    break
                case partFields.TireYear:
                    if (part.extraData?.TireYear) fillField(field, part.extraData.TireYear, part.extraData.TireYear)
                    break
                case partFields.PartBrand:
                    if (part.extraData?.Brand) fillField(field, part.extraData.Brand, part.extraData.Brand)
                    break
                case partFields.ResidualTread:
                    if (part.extraData?.ResidualTread)
                        fillField(field, part.extraData.ResidualTread, String(part.extraData.ResidualTread))
                    break
                case partFields.ResidualTreadWheels:
                    if (part.extraData?.ResidualTread)
                        fillField(field, part.extraData.ResidualTread, String(part.extraData.ResidualTread))
                    break
                case partFields.partNumber:
                    dispatch({
                        type: partCreateUpdateActionTypes.updateField,
                        value: {
                            field: field.field,
                            value: part.partNumber,
                            specialProps: {
                                ...field.specialProps,
                                [partSpecialPropsFields.renderValue]: part.partNumber,
                                [partSpecialPropsFields.filterId]: part.id,
                            },
                        },
                    })
                    break
                case partFields.description:
                    if (part.description) fillField(field, part.description, part.description)
                    break
                case partFields.partPricePurchase:
                    if (part.extraData?.pricePurchase)
                        fillField(field, part.extraData.pricePurchase, String(part.extraData.pricePurchase))
                    break
                case partFields.partPrice:
                    if (part.price) fillField(field, part.price, String(part.price))
                    break
                case partFields.warehouseTitle:
                    if (part.extraData?.warehouse?.id)
                        fillField(
                            field,
                            part.extraData.warehouse.id,
                            warehouses.find(w => w.value === part.extraData?.warehouse?.id)?.name || '',
                        )
                    break
                case partFields.warehouseRow:
                    if (part.extraData?.warehouse?.row)
                        fillField(field, part.extraData.warehouse.row, part.extraData.warehouse.row)
                    break
                case partFields.warehouseShelf:
                    if (part.extraData?.warehouse?.shelf)
                        fillField(field, part.extraData.warehouse.shelf, part.extraData.warehouse.shelf)
                    break
                case partFields.warehousePlace:
                    if (part.extraData?.warehouse?.place)
                        fillField(field, part.extraData.warehouse.place, part.extraData.warehouse.place)
                    break
                case partFields.vendorCode:
                    dispatch({
                        type: partCreateUpdateActionTypes.updateField,
                        value: {
                            field: field.field,
                            value: part.vendorCode,
                            specialProps: {
                                ...field.specialProps,
                                [partSpecialPropsFields.placeholder]: part.vendorCode,
                                [partSpecialPropsFields.printerOnClick]: printPart,
                            },
                        },
                    })
                    break
                case partFields.marketplacesDest:
                    if (part.extraData?.marketplacesDest) {
                        const marketplacesDest = getField(field.field)
                        marketplacesDest.value.map(
                            (marketplace: { field: string; value: boolean }) => (marketplace.value = false),
                        )
                        marketplacesDest.specialProps.checkboxItems.map(
                            (marketplace: { field: string; value: boolean }) => (marketplace.value = false),
                        )

                        part.extraData.marketplacesDest.forEach(element => {
                            const el = marketplacesDest.value.find((item: partInput) => item.field === element)
                            if (el) {
                                el.value = true
                            }
                            const el2 = marketplacesDest.specialProps.checkboxItems.find(
                                (item: partInput) => item.field === element,
                            )
                            if (el2) {
                                el2.value = true
                            }
                        })
                    }
                    break
                case partFields.height:
                    if (part.extraData?.height) fillField(field, part.extraData.height, String(part.extraData.height))
                    break
                case partFields.width:
                    if (part.extraData?.width) fillField(field, part.extraData.width, String(part.extraData.width))
                    break
                case partFields.length:
                    if (part.extraData?.length) fillField(field, part.extraData.length, String(part.extraData.length))
                    break
                case partFields.weight:
                    if (part.extraData?.weight) fillField(field, part.extraData.weight, String(part.extraData.weight))
                    break
                case partFields.VideoUrl:
                    if (part.extraData?.VideoUrl) fillField(field, part.extraData.VideoUrl, part.extraData.VideoUrl)
                    break
                case partFields.partId:
                    fillField(field, part.id, part.id)
                    break
                case partFields.RadioBrand:
                    if (part.extraData?.Brand) fillField(field, part.extraData.Brand, part.extraData.Brand)
                    break
                case partFields.RadioSize:
                    if (part.extraData?.RadioSize) fillField(field, part.extraData.RadioSize, part.extraData.RadioSize)
                    break
                case partFields.AndroidOS:
                    fillField(field, part.extraData?.AndroidOS === 'Да' ? 1 : 0, null)
                    break
                case partFields.RAM:
                    if (part.extraData?.RAM) fillField(field, part.extraData.RAM, part.extraData.RAM)
                    break
                case partFields.ROM:
                    if (part.extraData?.ROM) fillField(field, part.extraData.ROM, part.extraData.ROM)
                    break
                case partFields.CPU:
                    if (part.extraData?.CPU) fillField(field, part.extraData.CPU, part.extraData.CPU)
                    break
                case partFields.AmplifierBrand:
                    if (part.extraData?.Brand) fillField(field, part.extraData.Brand, part.extraData.Brand)
                    break
                case partFields.AmplifierType:
                    fillField(field, part.extraData?.AmplifierType === 'Универсальный' ? 1 : 0, null)
                    break
                case partFields.ChannelsNumber:
                    if (part.extraData?.ChannelsNumber)
                        fillField(field, part.extraData.ChannelsNumber, part.extraData.ChannelsNumber)
                    break
                case partFields.RMSfour:
                    if (part.extraData?.RMSfour) fillField(field, part.extraData.RMSfour, part.extraData.RMSfour)
                    break
                case partFields.RMStwo:
                    if (part.extraData?.RMStwo) fillField(field, part.extraData.RMStwo, part.extraData.RMStwo)
                    break
                case partFields.AudioBrand:
                    if (part.extraData?.Brand) fillField(field, part.extraData.Brand, part.extraData.Brand)
                    break
                case partFields.AudioType:
                    if (part.extraData?.AudioType) fillField(field, part.extraData.AudioType, part.extraData.AudioType)
                    break
                case partFields.VoiceCoil:
                    if (part.extraData?.VoiceCoil) fillField(field, part.extraData.VoiceCoil, part.extraData.VoiceCoil)
                    break
                case partFields.AudioSize:
                    if (part.extraData?.AudioSize) fillField(field, part.extraData.AudioSize, part.extraData.AudioSize)
                    break
                case partFields.RMS:
                    if (part.extraData?.RMS) fillField(field, part.extraData.RMS, part.extraData.RMS)
                    break
                case partFields.Impedance:
                    if (part.extraData?.Impedance) fillField(field, part.extraData.Impedance, part.extraData.Impedance)
                    break
                case partFields.CoverType:
                    if (part.extraData?.CoverType) fillField(field, part.extraData.CoverType, part.extraData.CoverType)
                    break
                case partFields.Material:
                    if (part.extraData?.Material) fillField(field, part.extraData.Material, part.extraData.Material)
                    break
            }
        })

        if (part.extraData?.additionalPartNumbers?.length) {
            const field = getField(partFields.partNumber)
            part.extraData.additionalPartNumbers.map(partNumber => {
                dispatch({
                    type: partCreateUpdateActionTypes.addField,
                    value: {
                        field: {
                            fieldType: field.fieldType,
                            valueType: field.valueType,
                            value: '',
                            title: 'Номер запчасти',
                            specialProps: {
                                placeholder: 'введите OEM номер',
                                isAdded: true,
                                addedField: partFields.partNumber,
                                maxAddedFields: field.specialProps?.maxAddedFields,
                                renderValue: partNumber,
                                fieldParent: partFields.partNumber,
                                extraHandle: partFields.partNumber,
                            },
                        },
                        after: field.field,
                    },
                })
            })
        }

        setIsFilledState(true)
    }

    useEffect(() => {
        if (isFilledState) {
            const customTitleField = getField(partFields.customTitle)

            if (!customTitleField.value) {
                const newCustomTitle = getCustomTitle()
                fillField(customTitleField, newCustomTitle, newCustomTitle)
            }
        }
    }, [isFilledState])

    // Первоначальная загрузка данных
    useEffect(() => {
        const fetchData = async () => {
            dispatchRedux(getDocumentsSelectSearch(EDocumentType.IncomingInvoice))
            await dispatchRedux(getIncomingInvoicesByPartsId([part.id], true))
            await dispatchRedux(getMarketplacesSettings(MarketplacesKeys.Avito))
            await dispatchRedux(getApplicabilityByPartId(part.id))
            if (part.extraData?.warehouse?.id) {
                await dispatchRedux(warehousesSelectSearch(''))
            }
            setIsLoading(false)
        }

        getPriceChartRes()

        fetchData()
    }, [])

    useEffect(() => {
        if (!isLoading) {
            fillInitialState()
        }
    }, [isLoading])

    // Обновить поле с применимостями
    useEffect(() => {
        if (applicabilitySelected.length) {
            const applicabilitySelectedField = getField(partFields.applicabilitySelected)
            dispatch({
                type: partCreateUpdateActionTypes.updateField,
                value: {
                    field: applicabilitySelectedField.field,
                    value: true,
                    specialProps: {
                        ...applicabilitySelectedField.specialProps,
                        [partSpecialPropsFields.applicabilityItems]: applicabilitySelected,
                    },
                },
            })

            const applicabilityField = getField(partFields.applicability)

            // Дополнительный текст у поля 'Применимость'
            let text = ''
            if (!applicabilityField.value) {
                text = `(${applicabilitySelected.length})`
            } else {
                text = ''
            }

            dispatch({
                type: partCreateUpdateActionTypes.updateSpecialProps,
                value: {
                    field: applicabilityField.field,
                    specialProps: {
                        ...applicabilityField.specialProps,
                        [partSpecialPropsFields.additionalTitleText]: text,
                    },
                },
            })
        }
    }, [applicabilitySelected])

    // Получаем partType по typeId
    useEffect(() => {
        const typeId = getField(partFields.typeId).value
        if (typeId) dispatchRedux(getPartTypeByTypeId(typeId))
    }, [getField(partFields.typeId).value])

    const fillFieldPlaceholder = (partField: partFields, value: number) => {
        const field = getField(partField)
        dispatch({
            type: partCreateUpdateActionTypes.updateSpecialProps,
            value: {
                field: field.field,
                value,
                specialProps: {
                    ...field.specialProps,
                    [partSpecialPropsFields.placeholder]: value,
                    [partSpecialPropsFields.onClick]: () =>
                        dispatch({
                            type: partCreateUpdateActionTypes.fillDimensionsAndWeight,
                        }),
                },
            },
        })
    }

    // Заполнение placeholder у размеров, веса и расположения
    useEffect(() => {
        if (partType) {
            if (partType.height) {
                fillFieldPlaceholder(partFields.height, partType.height)
            }
            if (partType.width) {
                fillFieldPlaceholder(partFields.width, partType.width)
            }
            if (partType.length) {
                fillFieldPlaceholder(partFields.length, partType.length)
            }
            if (partType.weight) {
                fillFieldPlaceholder(partFields.weight, partType.weight)
            }
            const positionField = getField(partFields.position)
            const position = getFrontRearLeftRight(partType)
            dispatch({
                type: partCreateUpdateActionTypes.updateField,
                value: {
                    field: positionField.field,
                    specialProps: {
                        ...positionField.specialProps,
                        [partSpecialPropsFields.renderValue]: position,
                    },
                },
            })
        }
    }, [partType])

    return (
        <>
            <Modal show={isOpen} onHide={onCloseModal} size="xl" fullscreen={true}>
                {(isLoading || isPartLoading) && (
                    <OverlayWithText backgroundBootstrapColor={'bg-secondary'}>
                        <Loader diameterInPx={100} thicknessInPx={10} />
                    </OverlayWithText>
                )}
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className={styles.contentWrap}>
                        <a href="#" onClick={onCloseModal}>
                            Назад к списку запчастей
                        </a>
                        <SalesText
                            text={`${title} ${part.title}`}
                            fontSize={fontSizes.xxl}
                            fontWeight={fontWeights.bold}
                            subText={part.carId || part.markId ? part.carPartTitle : ''}
                            subHref={
                                part.carId
                                    ? getAdminNavigationPath(adminRouteAlias.parts.location) + `?carId=${part.carId}`
                                    : ''
                            }
                        />
                        <div className={'d-flex'}>
                            <SalesCard
                                widthInPixels={getVisibleColumn(2).length === 0 ? 570 : 1140}
                                minHeightInPixels={600}
                                marginTopPixels={24}
                                overflowY={'visible'}
                            >
                                <div className={styles.cardContentWrap}>
                                    <div className={styles.cardColumnWrap}>
                                        <PartCreateUpdateTable dispatch={dispatch} fields={getVisibleColumn(1)} />
                                    </div>
                                    {getVisibleColumn(2).length === 0 ? null : (
                                        <div className={styles.cardColumnWrap}>
                                            <PartCreateUpdateTable
                                                dispatch={dispatch}
                                                fields={getVisibleColumn(2)}
                                                top={
                                                    priceChartResponse && priceChartResponse.rcmd_price !== 0 ? (
                                                        <div className={styles.modalEditPart__priceChart}>
                                                            <Form.Label
                                                                className={`${styles.modalEditPart__formLabel}`}
                                                            >
                                                                Аналитика рынка по похожим запчастям
                                                            </Form.Label>
                                                            <PriceChart bars={priceChartResponse.res} />
                                                        </div>
                                                    ) : undefined
                                                }
                                                modalImageGroup={
                                                    part.images.length ? (
                                                        <td className={styles.modalImgGroup}>
                                                            <ModalImageGroup images={part.images} showMainImg={false} />
                                                        </td>
                                                    ) : undefined
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            </SalesCard>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalViewPart
