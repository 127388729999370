import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Col, Container, Row, Stack } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import Select from 'react-select'
import { ToastContainer } from 'react-toastify'

import Form from 'react-bootstrap/esm/Form'

import CustomSelect from '../../components/_atoms/CustomSelect/CustomSelect'
import Loader from '../../components/_atoms/Loader/Loader'
import OverlayWithText from '../../components/_atoms/OverlayWithText/OverlayWithText'
import SalesText from '../../components/_atoms/SalesText/SalesText'
import { ModalChoseParts } from '../../components/_molecules/ModalChoseParts/ModalChoseParts'
import { ModalSaveClient } from '../../components/_molecules/ModalSaveClient/ModalSaveClient'
import ModalViewPart from '../../components/_molecules/ModalViewPart/ModalViewPart'
import { fontSizes, fontWeights } from '../../constants/salesStyles'
import { ToastType, useToast } from '../../hooks/useToast'
import { TAX_RATES } from '../../modules/redux/documentList/PrintFiscalReceipt'
import { useLazyStatusQuery, usePrintFiscalReceiptMutation } from '../../modules/store/atolApi/atolApi'
import { useSelector } from '../../modules/store/customSelector'
import { useGetPartsAdminListMutation } from '../../modules/store/datahubApi/datahubApi'
import { useUpdateSoldPartsFromDealMutation } from '../../modules/store/datahubApi/datahubApi'
import {
    useLazyGetClientsListQuery,
    useGetDealsListMutation,
    useGetDealStatusDictQuery,
    useSaveDealMutation,
    useDownloadDealDocumentMutation,
} from '../../modules/store/dochubApi/dochubApi'
import {
    Client,
    Deal,
    DealClient,
    DealItem,
    DealStatus,
    DealStatusInfo,
    SaveDealApiArg,
} from '../../modules/store/dochubApi/dochubTypes'
import { adminRouteAlias, getAdminNavigationPath } from '../../router/adminRouteAlias'
import { Marketplaces, Part } from '../../types/part/partTypes'
import { formatPrice } from '../../utils/formatAndUnformatPrice'
import { getCurrencySymbol } from '../../utils/getCurrencySymbol'

import { PartsTable } from './PartsTable/PartsTable'
import styles from './styles.module.scss'

interface IOption {
    value: string
    label: string
}

const sources: IOption[] = Object.entries(Marketplaces).map(([key, value]) => ({ value: key, label: value }))

interface FormData {
    price: number
    client: Client | DealClient | null
    seller: string
    selectedSource: IOption | null
    comment: string
    selectedStatus: DealStatusInfo | null
}

const DealPage = () => {
    const navigate = useNavigate()
    const { dealId } = useParams<{ dealId?: string }>()
    const userPhoneNumber = useSelector(state => state.userData.smsAuth.phoneNumber)
    const { register, handleSubmit, control, watch, reset, setValue } = useForm<FormData>({
        defaultValues: {
            price: 0,
            client: null,
            seller: userPhoneNumber,
            selectedSource: null,
            comment: '',
            selectedStatus: null,
        },
    })
    const clientWatch = watch('client')

    const userId = useMemo(() => localStorage.getItem('userId')!, [])

    const [currentDeal, setCurrentDeal] = useState<Deal | null>(null)
    const [totalPrice, setTotalPrice] = useState<number>(0)
    const [selectedParts, setSelectedParts] = useState<Part[]>([])
    const [partsData, setPartsData] = useState<Record<string, { price: number; qty: number }>>({})
    const [currentPart, setCurrentPart] = useState<Part | null>(null)

    // Модалки
    const [isModalChosePartsOpen, setModalChosePartsOpen] = useState(false)
    const [isModalSaveClientOpen, setModalSaveClientOpen] = useState(false)
    const [isModalViewPartOpen, setModalViewPartOpen] = useState(false)

    const [saveDeal, { data: savedDealData, isLoading: isLoadingSaveDeal, isError: isErrorSaveDeal }] =
        useSaveDealMutation()
    const [getDealsList, { data: dealListData, isLoading: isLoadingGetDealsList, isError: isErrorGetDealsList }] =
        useGetDealsListMutation()
    const [getPartsAdminList, { data: partsListData, isLoading: isLoadingGetPartsList, isError: isErrorGetPartsList }] =
        useGetPartsAdminListMutation()

    const [updateSoldPartsFromDeal] = useUpdateSoldPartsFromDealMutation()

    const {
        data: dealStatusDictData,
        isLoading: isLoadingGetDealStatusDict,
        isError: isErrorGetDealStatusDict,
    } = useGetDealStatusDictQuery()
    const [
        printFiscalReceipt,
        { data: printFiscalReceiptData, isLoading: isLoadingPrintFiscalReceipt, isError: isErrorPrintFiscalReceipt },
    ] = usePrintFiscalReceiptMutation()
    const [getStatus, { data: statusData }] = useLazyStatusQuery()
    const [getClientsList, { data: clientsList, isFetching: isLoadingGetClientsList, isError: isErrorGetClientsList }] =
        useLazyGetClientsListQuery()
    const [downloadDealDocument, { isLoading: isLoadingDownloadDealDocument, isError: isErrorDownloadDealDocument }] =
        useDownloadDealDocumentMutation()

    const handleSearchClients = useCallback(
        (search: string) => {
            getClientsList({ params: { pagination: { page: 1, perPage: 10 }, search } })
        },
        [getClientsList],
    )

    const cashier = useSelector(state => state.userData.cashierInfo)?.cashier
    const currentCurrency = useSelector(state => state.partList.currentCurrency)

    const isPrintFiscalReceiptButtonDisabled = useMemo(
        () => !cashier || !selectedParts.length || isLoadingPrintFiscalReceipt || !statusData || !clientWatch,
        [cashier, clientWatch, isLoadingPrintFiscalReceipt, selectedParts.length, statusData],
    )

    useEffect(() => {
        cashier && getStatus()
    }, [cashier, getStatus])

    const removePart = useCallback((removedPart: Part) => {
        setSelectedParts(prev => prev.filter(part => part.id !== removedPart.id))
    }, [])

    const updatePartData = (id: string, field: 'price' | 'qty', value: number) => {
        setPartsData(prev => ({
            ...prev,
            [id]: {
                ...prev[id],
                [field]: value,
            },
        }))
    }

    const resetState = useCallback(() => {
        reset()
        setSelectedParts([])
        setTotalPrice(0)
        setPartsData({})
        setCurrentDeal(null)
    }, [reset])

    const onSubmit = useCallback(
        (data: FormData) => {
            const dealItems: DealItem[] = selectedParts.map(part => ({
                title: part.customTitle || part.title,
                price: partsData[part.id]?.price ?? part.price,
                qty: partsData[part.id]?.qty ?? 1,
                part: {
                    id: part.id,
                    vendorCode: part.vendorCode,
                    customTitle: part.customTitle,
                    title: part.title,
                    markTitle: part.markTitle,
                    modelTitle: part.modelTitle,
                    generationTitle: part.generationTitle,
                    warehouse: {
                        title: part.warehouseData?.title,
                        place: part.extraData?.warehouse?.place,
                        row: part.extraData?.warehouse?.row,
                        shelf: part.extraData?.warehouse?.shelf,
                    },
                },
            }))

            const dealData: Omit<SaveDealApiArg, 'userId'> = {
                deal: {
                    userId,
                    code: currentDeal ? currentDeal.code : 'code',
                    status: data.selectedStatus?.code || DealStatus.reserved,
                    items: dealItems,
                    ...(data.client && {
                        client: {
                            id: data.client.id,
                            name: data.client.name,
                            phone: data.client.phone || '',
                        },
                    }),
                    seller: {
                        name: data.seller,
                    },
                    meta: {
                        comment: data.comment,
                        source: data.selectedSource!.value,
                    },
                },
            }

            if (currentDeal) {
                dealData.deal.id = currentDeal.id
            }

            saveDeal(dealData)
                .unwrap()
                .then(response => {
                    // Если сделка успешно сохранена и получен dealId
                    const dealId = response.data.id
                    // Формируем payload для updateSoldPartsFromDeal
                    const updatePayload = {
                        userId,
                        dealId,
                        dealStatus: response.data.status,
                        items: selectedParts.map(part => ({
                            partId: part.id,
                            marketplace: data.selectedSource ? data.selectedSource.value : 'avito', // можно заменить на константу, если требуется
                            price: partsData[part.id]?.price ?? part.price,
                            qty: partsData[part.id]?.qty ?? 1,
                        })),
                    }
                    updateSoldPartsFromDeal(updatePayload)
                        .unwrap()
                        .then(() => {
                            // При необходимости можно добавить уведомление об успехе
                        })
                        .catch(err => {
                            console.error('Ошибка updateSoldPartsFromDeal: ', err)
                        })
                })
                .catch(err => {
                    console.error('Ошибка сохранения сделки: ', err)
                })
        },
        [currentDeal, partsData, saveDeal, selectedParts],
    )

    const handlePrintFiscalReceipt = useCallback(
        (data: FormData) => {
            if (!cashier) return
            if (!data.client) return

            const totalTax = totalPrice * (TAX_RATES[cashier.tax] ?? 0)
            const totalSubtotal = totalPrice - totalTax
            const soldParts = selectedParts.map(part => ({
                name: part.customTitle ?? part.title,
                quantity: partsData[part.id]?.qty ?? 1,
                price: partsData[part.id]?.price ?? part.price,
                tax: cashier.tax,
            }))

            printFiscalReceipt({
                cash_register_id: '',
                transaction_id: currentDeal?.code ?? null,
                operator: `${cashier.familyName} ${cashier.name} ${cashier.surname}`,
                items: soldParts,
                total: {
                    subtotal: Number(totalSubtotal.toFixed(2)),
                    tax: Number(totalTax.toFixed(2)),
                    total_amount: totalPrice,
                },
                payment: {
                    method: 'cash',
                    amount: totalPrice,
                },
                customer: {
                    name: data.client.name,
                },
                receipt_options: {
                    print_copies: 1,
                },
            })
        },
        [cashier, currentDeal?.code, partsData, printFiscalReceipt, selectedParts, totalPrice],
    )

    const handleDocumentDownload = useCallback(
        (currentDeal: Deal) => {
            downloadDealDocument({ dealId: currentDeal.id })
        },
        [downloadDealDocument],
    )

    useEffect(() => {
        setTotalPrice(
            selectedParts.reduce(
                (total, part) => total + (partsData[part.id]?.price ?? part.price) * (partsData[part.id]?.qty ?? 1),
                0,
            ),
        )
    }, [partsData, selectedParts])

    useEffect(() => {
        if (dealId) {
            getDealsList({ params: { id: dealId, pagination: { page: 1, perPage: 1 } } })
        } else {
            resetState()
        }
    }, [dealId, getDealsList, resetState])

    useEffect(() => {
        dealListData && setCurrentDeal(dealListData.data[0])
    }, [dealListData])

    useEffect(() => {
        if (currentDeal) {
            setValue('client', currentDeal.client || null)
            setValue('seller', currentDeal.seller?.name || '')
            setValue('selectedSource', sources.find(source => source.value === currentDeal.meta?.source) || null)
            setValue('comment', currentDeal.meta?.comment || '')
            setValue('selectedStatus', dealStatusDictData ? dealStatusDictData.data[currentDeal.status] : null)

            const { items } = currentDeal
            if (items) {
                const partsId: string[] = []
                items.forEach(item => {
                    partsId.push(item.part.id)
                    updatePartData(item.part.id, 'price', item.price)
                    updatePartData(item.part.id, 'qty', item.qty)
                })
                getPartsAdminList({ params: { partId: partsId, allStatuses: true } })
            }
        }
    }, [currentDeal, getPartsAdminList, resetState, setValue])

    useEffect(() => {
        partsListData && setSelectedParts(partsListData.data)
    }, [partsListData])

    useEffect(() => {
        if (savedDealData && !currentDeal) {
            navigate(getAdminNavigationPath(adminRouteAlias.deals.edit) + `/${savedDealData.data.id}`)
        }
    }, [savedDealData])

    useToast({ isTriggered: !!savedDealData, type: ToastType.SUCCESS, message: `Сделка успешно сохранена` })
    useToast({ isTriggered: isErrorSaveDeal, type: ToastType.ERROR, message: `Ошибка сохранения сделки` })
    useToast({ isTriggered: isErrorGetDealsList, type: ToastType.ERROR, message: 'Ошибка загрузки сделок' })
    useToast({ isTriggered: isErrorGetPartsList, type: ToastType.ERROR, message: 'Ошибка загрузки запчастей' })
    useToast({
        isTriggered: isErrorGetDealStatusDict,
        type: ToastType.ERROR,
        message: 'Ошибка загрузки словаря статусов',
    })
    // TODO: Надо учесть ответ при успешном создании чека
    // useToast({
    //     isTriggered: !!printFiscalReceiptData,
    //     type: ToastType.INFO,
    //     message: printFiscalReceiptData.data.error_description,
    // })
    useToast({
        isTriggered: isErrorPrintFiscalReceipt,
        type: ToastType.ERROR,
        message: 'Ошибка печатания чека',
    })
    useToast({ isTriggered: isErrorDownloadDealDocument, type: ToastType.ERROR, message: 'Ошибка загрузки документа' })

    return (
        <div className={styles.wrap}>
            <ToastContainer position="bottom-center" autoClose={5000} />
            {(isLoadingSaveDeal || isLoadingGetDealsList) && (
                <OverlayWithText backgroundBootstrapColor={'bg-secondary'}>
                    <Loader diameterInPx={100} thicknessInPx={10} />
                </OverlayWithText>
            )}
            {isModalChosePartsOpen && (
                <ModalChoseParts
                    isOpen={isModalChosePartsOpen}
                    closeModal={() => setModalChosePartsOpen(false)}
                    setSelectedParts={newSelectedParts => setSelectedParts(prev => [...prev, ...newSelectedParts])}
                    disabledParts={selectedParts}
                />
            )}
            {isModalSaveClientOpen && (
                <ModalSaveClient
                    isOpen={isModalSaveClientOpen}
                    closeModal={() => setModalSaveClientOpen(false)}
                    onSuccess={() => handleSearchClients('')}
                />
            )}
            {currentPart && isModalViewPartOpen && (
                <ModalViewPart
                    part={currentPart}
                    isOpen={isModalViewPartOpen}
                    closeModal={() => setModalViewPartOpen(false)}
                />
            )}

            <Stack direction="horizontal">
                <SalesText
                    text={currentDeal ? `Сделка № ${currentDeal.code}` : 'Новая сделка'}
                    fontSize={fontSizes.xxl}
                    fontWeight={fontWeights.bold}
                />
            </Stack>
            <Container fluid className={styles.topContainer}>
                <Row>
                    <Col>
                        <Form.Group as={Row} className="mb-4" style={{ flexWrap: 'nowrap' }}>
                            <Form.Label column>Покупатель</Form.Label>
                            <Col xs={8}>
                                <Controller
                                    name="client"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <CustomSelect
                                            {...field}
                                            onSearch={handleSearchClients}
                                            options={clientsList?.data}
                                            isLoading={isLoadingGetClientsList}
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.id}
                                            placeholder="Выберите покупателя"
                                        />
                                    )}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-4" style={{ flexWrap: 'nowrap' }}>
                            <Form.Label column>Ответственный</Form.Label>
                            <Col xs={8}>
                                <Form.Control
                                    type="text"
                                    {...register('seller', { required: true })}
                                    placeholder="Введите ответственного"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-4" style={{ flexWrap: 'nowrap' }}>
                            <Form.Label column>Источник сделки</Form.Label>
                            <Col xs={8}>
                                <Controller
                                    name="selectedSource"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={sources}
                                            placeholder="Выберите источник"
                                            isClearable
                                        />
                                    )}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} style={{ flexWrap: 'nowrap' }}>
                            <Form.Label column>Статус сделки</Form.Label>
                            <Col xs={8}>
                                <Controller
                                    name="selectedStatus"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={
                                                dealStatusDictData
                                                    ? Object.values(dealStatusDictData.data).map(
                                                          dealStatusInfo => dealStatusInfo,
                                                      )
                                                    : []
                                            }
                                            isLoading={isLoadingGetDealStatusDict}
                                            isDisabled={isErrorGetDealStatusDict}
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.code}
                                            placeholder="Выберите статус сделки"
                                            isClearable
                                        />
                                    )}
                                />
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col xs={2} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Button
                            variant="primary"
                            onClick={() => setModalSaveClientOpen(true)}
                            style={{ width: 'fit-content' }}
                        >
                            +
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleSubmit(onSubmit)}
                            disabled={!selectedParts.length || isLoadingGetPartsList}
                            style={{ width: 'fit-content' }}
                        >
                            {currentDeal ? 'Сохранить' : 'Создать сделку'}
                        </Button>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Комментарии к сделке</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={7}
                                placeholder="Введите комментарий"
                                {...register('comment')}
                                style={{ resize: 'none' }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
            <Container fluid style={{ padding: 0 }}>
                <Row>
                    <Col>
                        <Stack direction="horizontal" gap={5}>
                            <SalesText
                                text={'Запчасти в сделке'}
                                fontSize={fontSizes.l}
                                fontWeight={fontWeights.bold}
                            />
                            <Button
                                variant="primary"
                                style={{ width: '130px' }}
                                onClick={() => setModalChosePartsOpen(true)}
                            >
                                + Добавить
                            </Button>
                        </Stack>
                    </Col>
                    <Col>
                        <Stack direction="horizontal" gap={5}>
                            <SalesText
                                text={`${formatPrice(totalPrice)} ${getCurrencySymbol(currentCurrency)}`}
                                fontSize={fontSizes.xl}
                                fontWeight={fontWeights.bold}
                            />
                            <SalesText text={`Сумма сделки`} fontSize={fontSizes.l} fontWeight={fontWeights.bold} />
                        </Stack>
                    </Col>
                    {currentDeal && (
                        <>
                            <Col xs="auto">
                                <Button
                                    variant="outline-primary"
                                    onClick={() => handleDocumentDownload(currentDeal)}
                                    disabled={isLoadingDownloadDealDocument}
                                >
                                    🖨 чек/наклад
                                </Button>
                            </Col>
                            <Col xs="auto">
                                <Button
                                    variant="outline-primary"
                                    disabled={isPrintFiscalReceiptButtonDisabled}
                                    onClick={handleSubmit(handlePrintFiscalReceipt)}
                                >
                                    Пробить чек
                                </Button>
                            </Col>
                        </>
                    )}
                </Row>
            </Container>

            <PartsTable
                parts={selectedParts}
                removePart={removePart}
                updatePartData={updatePartData}
                partsData={partsData}
                isLoading={isLoadingGetPartsList}
                onPartClick={part => {
                    setCurrentPart(part)
                    setModalViewPartOpen(true)
                }}
            />
        </div>
    )
}

export default DealPage
