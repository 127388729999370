import { useEffect } from 'react'
import { toast } from 'react-toastify'

export enum ToastType {
    ERROR = 'error',
    SUCCESS = 'success',
    INFO = 'info',
}

interface UseToastProps {
    isTriggered: boolean
    type: ToastType
    message: string
}

export const useToast = ({ isTriggered, type, message }: UseToastProps) => {
    useEffect(() => {
        if (isTriggered) {
            toast[type](message)
        }
    }, [isTriggered, type, message])
}
