import getBrowserFingerprint from 'get-browser-fingerprint'
import { apiAuth } from "../../api/apiInstance"


const getAccessToken = async (refreshTokenOld: string) => {
    try {
        const { data: { status, msg, data: { accessToken, refreshToken } } } = await apiAuth.post('/getAccessToken', {
            refreshToken: refreshTokenOld,
            deviceId: String(getBrowserFingerprint())
        })
        if(status === 'ok') {
            localStorage.setItem('accessToken', accessToken)
            localStorage.setItem('refreshToken', refreshToken)
        }

    } catch (e) {
        console.error('get Access Token failed ', e)
        localStorage.clear()
    }
}

export default getAccessToken
