export default [
    { value: 13000, name: 'колёса в сборе' },
    { value: 13003, name: 'запасное колесо' },
    { value: 13001, name: 'шины' },
    { value: 13004, name: 'шина' },
    { value: 13002, name: 'диски' },
    { value: 13005, name: 'диск' },
    { value: 9072, name: 'аккумулятор' },
    { value: 32, name: '#запчасть' },
]
