import { carFields } from '../../../types/car/carTypes'
import { fieldTypes, valueTypes } from '../../../types/common/commonTypes'

export const initialCarState = {
    fields: [
        {
            field: carFields.markId,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.number,
            value: 0,
            title: 'Марка',
            specialProps: {
                isRequired: true,
                placeholder: 'выберите марку',
                column: 1,
                renderValue: '',
            },
        },
        {
            field: carFields.modelId,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.number,
            value: 0,
            title: 'Модель',
            specialProps: {
                isRequired: true,
                placeholder: 'выберите модель',
                fieldParent: carFields.markId,
                column: 1,
            },
        },
        {
            field: carFields.modificationId,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.number,
            value: 0,
            title: 'Модификация',
            specialProps: {
                isRequired: true,
                placeholder: 'выберите модификацию',
                fieldParent: carFields.modelId,
                column: 1,
            },
        },
        {
            field: carFields.bodyTypeId,
            fieldType: fieldTypes.readOnly,
            valueType: valueTypes.number,
            title: 'Тип кузова',
            specialProps: {
                placeholder: 'определяется автоматически',
                fieldParent: carFields.modificationId,
                column: 1,
            },
        },
        {
            field: carFields.year,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.number,
            value: 0,
            title: 'Год',
            specialProps: {
                isRequired: true,
                placeholder: 'выберите год',
                fieldParent: carFields.modificationId,
                column: 1,
            },
        },
        {
            field: carFields.customTitle,
            fieldType: fieldTypes.textarea,
            valueType: valueTypes.string,
            value: '',
            title: 'Название авто',
            specialProps: {
                placeholder: 'название для маркетплейсов',
                textareaMaxLength: 50,
                hoverText: `если поле останется пустым, название для публикации сформируется автоматически в формате: "Марка" + "Модель" + "Год"`,
                isTouched: false,
                column: 1,
                isClearButton: true,
                extraHandle: 'customTitle',
            },
        },
        {
            field: carFields.steeringType,
            fieldType: fieldTypes.switch,
            value: 0,
            valueType: valueTypes.number,
            title: 'Руль',
            specialProps: {
                switchRenderOptions: ['Левый', 'Правый'],
                column: 1,
                extraHandle: 'steeringType',
                // customWidth: 158
            },
        },
        {
            field: carFields.equipment,
            fieldType: fieldTypes.input,
            valueType: valueTypes.string,
            value: '',
            title: 'Комплектация',
            specialProps: {
                placeholder: 'введите комплектацию',
                column: 1,
                extraHandle: 'equipment',
            },
        },
        {
            field: carFields.vinCode,
            fieldType: fieldTypes.input,
            valueType: valueTypes.string,
            value: '',
            title: 'VIN',
            specialProps: {
                placeholder: 'введите VIN',
                column: 1,
            },
        },
        {
            field: carFields.bodycars,
            fieldType: fieldTypes.input,
            valueType: valueTypes.string,
            value: '',
            title: 'Модель кузова',
            specialProps: {
                placeholder: 'введите модель кузова',
                column: 1,
            },
        },
        {
            field: carFields.engine,
            fieldType: fieldTypes.input,
            valueType: valueTypes.string,
            value: '',
            title: 'Модель двигателя',
            specialProps: {
                placeholder: 'введите модель двигателя',
                column: 1,
            },
        },
        {
            field: carFields.colorCode,
            fieldType: fieldTypes.input,
            valueType: valueTypes.string,
            value: '',
            title: 'Код цвета',
            specialProps: {
                placeholder: 'введите код цвета',
                column: 1,
                extraHandle: 'colorCode',
            },
        },
        {
            field: carFields.mileage,
            fieldType: fieldTypes.select,
            valueType: valueTypes.number,
            value: 0,
            title: 'Пробег',
            specialProps: {
                placeholder: 'выберите пробег',
                column: 1,
            },
        },
        {
            field: carFields.descTemplate,
            fieldType: fieldTypes.textarea,
            valueType: valueTypes.string,
            value: '',
            title: '',
            specialProps: {
                placeholder: 'Введите дополнительное описание',
                textareaMinHeight: 200,
                textareaMaxLength: 4000,
                column: 1,
                isFullWidth: true,
                templateButtons: [
                    {
                        title: 'Автомобиль',
                        value: 'Запчасть с автомобиля [mark] [model] [modification] [year].',
                    },
                    {
                        title: 'Состояние',
                        value: 'Состояние запчасти [condition].',
                    },
                    {
                        title: 'Небольшой естественный износ',
                        value: 'Небольшой естественный износ.',
                    },
                    {
                        title: 'Есть недостатки, видны на фото',
                        value: 'Есть недостатки, видны на фото.',
                    },
                    {
                        title: 'Не работает',
                        value: 'Не работает.',
                    },
                    {
                        title: 'Не проверялась',
                        value: 'Не проверялась.',
                    },
                    {
                        title: 'Полный комплект',
                        value: 'Полный комплект.',
                    },
                ],
            },
        },
        {
            field: carFields.carPrice,
            fieldType: fieldTypes.input,
            value: 0,
            valueType: valueTypes.price,
            title: 'Цена закупки',
            specialProps: {
                placeholder: 'введите цену',
                column: 2,
            },
        },
        {
            field: carFields.sumAllParts,
            fieldType: fieldTypes.readOnly,
            valueType: valueTypes.number,
            title: 'Стоимость всех запчастей',
            specialProps: {
                placeholder: 'Стоимость всех запчастей',
                column: 2,
                renderValue: 0,
                borderTop: false,
            },
        },
        {
            field: carFields.roiMax,
            fieldType: fieldTypes.readOnly,
            valueType: valueTypes.number,
            title: 'ROI max',
            specialProps: {
                placeholder: 'коэфициент окупаемости ROI max',
                column: 2,
                renderValue: '',
                borderTop: false,
            },
        },
        {
            field: carFields.sumSoldParts,
            fieldType: fieldTypes.readOnly,
            valueType: valueTypes.number,
            title: 'Выручка',
            specialProps: {
                placeholder: 'выручка',
                column: 2,
                renderValue: 0,
            },
        },
        {
            field: carFields.profit,
            fieldType: fieldTypes.readOnly,
            valueType: valueTypes.number,
            title: 'Прибыль',
            specialProps: {
                placeholder: 'прибыль',
                column: 2,
                renderValue: 0,
                borderTop: false,
            },
        },
        {
            field: carFields.roi,
            fieldType: fieldTypes.readOnly,
            valueType: valueTypes.number,
            title: 'ROI',
            specialProps: {
                placeholder: 'коэфициент окупаемости ROI',
                column: 2,
                renderValue: '',
                borderTop: false,
            },
        },
        {
            field: carFields.soldPartsCount,
            fieldType: fieldTypes.readOnly,
            valueType: valueTypes.number,
            title: 'Продано запчастей',
            specialProps: {
                placeholder: 'продано запчастей',
                column: 2,
                renderValue: '0',
                borderTop: false,
            },
        },
        {
            field: carFields.partsOnSaleCount,
            fieldType: fieldTypes.readOnly,
            valueType: valueTypes.number,
            title: 'Запчастей в продаже',
            specialProps: {
                placeholder: 'Запчастей в продаже',
                column: 2,
                renderValue: '0',
            },
        },
        {
            field: carFields.partsOnSaleSum,
            fieldType: fieldTypes.readOnly,
            valueType: valueTypes.number,
            title: 'На сумму',
            specialProps: {
                placeholder: 'На сумму',
                column: 2,
                renderValue: 0,
                borderTop: false,
            },
        },

        {
            field: carFields.regionId,
            fieldType: fieldTypes.select,
            valueType: valueTypes.number,
            title: 'Страна закупки',
            specialProps: {
                placeholder: 'выберите страну закупки',
                column: 2,
                extraHandle: 'regionId',
            },
        },
        {
            field: carFields.defects,
            fieldType: fieldTypes.checkboxGroupColumn,
            valueType: valueTypes.array,
            value: [
                {
                    field: 1,
                    value: false,
                },
                {
                    field: 2,
                    value: false,
                },
                {
                    field: 3,
                    value: false,
                },
                {
                    field: 4,
                    value: false,
                },
                {
                    field: 5,
                    value: false,
                },
                {
                    field: 6,
                    value: false,
                },
            ],
            title: 'Дефекты',
            specialProps: {
                column: 2,
                extraHandle: 'defects',
                checkboxItems: [
                    {
                        field: 1,
                        fieldType: fieldTypes.checkbox,
                        valueType: valueTypes.boolean,
                        value: false,
                        title: 'Битая',
                    },
                    {
                        field: 2,
                        fieldType: fieldTypes.checkbox,
                        valueType: valueTypes.boolean,
                        value: false,
                        title: 'Утопленник',
                    },
                    {
                        field: 3,
                        fieldType: fieldTypes.checkbox,
                        valueType: valueTypes.boolean,
                        value: false,
                        title: 'Распил',
                    },
                    {
                        field: 4,
                        fieldType: fieldTypes.checkbox,
                        valueType: valueTypes.boolean,
                        value: false,
                        title: 'Небольшая коррозия',
                    },
                    {
                        field: 5,
                        fieldType: fieldTypes.checkbox,
                        valueType: valueTypes.boolean,
                        value: false,
                        title: 'Большая коррозия',
                    },
                    {
                        field: 6,
                        fieldType: fieldTypes.checkbox,
                        valueType: valueTypes.boolean,
                        value: false,
                        title: 'Не на ходу',
                    },
                ],
            },
        },
        {
            field: carFields.conditionId,
            fieldType: fieldTypes.starRating,
            value: 0,
            valueType: valueTypes.number,
            title: 'Оценка состояния',
            specialProps: {
                column: 2,
                extraHandle: 'conditionId',
            },
        },
        {
            field: carFields.carImages,
            fieldType: fieldTypes.files,
            value: [],
            valueType: valueTypes.files,
            title: '',
            specialProps: {
                renderValue: 'Добавить фотографии',
                column: 2,
                isFullWidth: true,
                extraHandle: 'partImages',
            },
        },
    ],
}
