import { useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router'

import getIsActive from '../../modules/redux/user/getIsActive'
import { useDispatch } from '../../modules/store/customDispatch'
import { useSelector } from '../../modules/store/customSelector'
import { adminRouteAlias, getAdminNavigationPath } from '../../router/adminRouteAlias'

import { useGetSmsUserId } from './useAuth'

export const usePayWall = () => {
    const dispatchRedux = useDispatch()

    const user = useGetSmsUserId()
    const isActive = useSelector(state => state.userData.isActive)
    const navigate = useNavigate()
    const location = useLocation()
    const payWallPathName = getAdminNavigationPath(adminRouteAlias.payWall.location)
    const adminPathName = getAdminNavigationPath(adminRouteAlias.parts.location)
    const intervalRef = useRef<NodeJS.Timeout | null>(null)

    useEffect(() => {
        dispatchRedux(getIsActive())
    }, [])

    useEffect(() => {
        if (user && !isActive && location.pathname.startsWith('/admin/')) {
            navigate(payWallPathName)

            intervalRef.current = setInterval(() => {
                dispatchRedux(getIsActive())
            }, 30000)
        }

        if (isActive) {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
                intervalRef.current = null
            }
            // Редирект на adminPathName только если текущий путь соответствует payWallPathName
            if (location.pathname === payWallPathName) {
                navigate(adminPathName)
            }
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
                intervalRef.current = null
            }
        }
    }, [user, isActive, location.pathname])
}
