import { useState, useReducer, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import axios from 'axios'
import { v4 as uuidV4, v5 as uuidV5 } from 'uuid'

import mileage from '../../../constants/mileage'
import regions from '../../../constants/regions'
import { colors, fontSizes, fontWeights } from '../../../constants/salesStyles'
import deleteCarImage from '../../../modules/redux/carList/DeleteCarImage'
import reorderCarImages from '../../../modules/redux/carList/ReorderCarImages'
import saveCarInCloud from '../../../modules/redux/carList/saveCarInCloud'
import getAllCarMarks from '../../../modules/redux/catalogs/getAllCarMarks'
import getAllCarModels from '../../../modules/redux/catalogs/getAllCarModels'
import getAllCarModifications from '../../../modules/redux/catalogs/getAllCarModifications'
import getModificationYears from '../../../modules/redux/catalogs/getModificationYears'
import saveImageInCloud from '../../../modules/redux/createUpdateForm/saveImageInCloud'
import { useDispatch } from '../../../modules/store/customDispatch'
import { useSelector } from '../../../modules/store/customSelector'
import { carReducer } from '../../../pages/NewCar/carReducer'
import { initialApiCarObject } from '../../../pages/NewCar/initialApiCarObject'
import { adminRouteAlias, getAdminNavigationPath } from '../../../router/adminRouteAlias'
import {
    Car,
    carFields,
    carInput,
    carSpecialPropsFields,
    carCreateUpdateActionTypes,
    ApiCarCreateUpdate,
} from '../../../types/car/carTypes'
import { CatalogModification } from '../../../types/common/commonTypes'
import { isDeepEqualSimple } from '../../../utils/misc'
import Loader from '../../_atoms/Loader/Loader'
import ModalImageGroup from '../../_atoms/ModalImageGroup/ModalImageGroup'
import OverlayWithText from '../../_atoms/OverlayWithText/OverlayWithText'
import SalesText from '../../_atoms/SalesText/SalesText'
import PartCreateUpdateTable from '../PartCreateUpdateTable/PartCreateUpdateTable'
import SalesButtonStandard from '../SalesButtonStandard/SalesButtonStandard'
import SalesCard from '../SalesCard/SalesCard'

import { initialCarState } from './initialCarState'
import styles from './styles.module.scss'

// Список полей, которые нужно скрыть, если canShowFinanceInfo = false
const financeFields: carFields[] = [
    carFields.carPrice,
    carFields.sumAllParts,
    carFields.roiMax,
    carFields.sumSoldParts,
    carFields.profit,
    carFields.roi,
    carFields.soldPartsCount,
    carFields.partsOnSaleCount,
    carFields.partsOnSaleSum,
]

interface IProps {
    car: Car
    isOpen: boolean
    closeModal: () => void
}

const ModalEditCar = ({ car, isOpen, closeModal }: IProps) => {
    const title = 'Редактирование автомобиля'
    const dispatchRedux = useDispatch()
    const navigate = useNavigate()
    const [state, dispatch] = useReducer(carReducer, structuredClone(initialCarState))
    const [bodyTypes, setBodyTypes] = useState({} as Record<string, string>)
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
    const filteredModifications = useSelector(state => state.carList.filteredModifications)
    const [isLoading, setIsLoading] = useState(false)
    const canShowFinanceInfo = useSelector(state => state.userData.perms?.canShowFinanceInfo)

    const [imagesToDelete, setImagesToDelete] = useState<string[]>([])
    const [imagesToReorder, setImagesToReorder] = useState(structuredClone(car.images))

    const markImg = (id: string) => {
        setImagesToDelete([id, ...imagesToDelete])
    }
    const unmarkImg = (id: string) => {
        setImagesToDelete(prevImages => prevImages.filter(img => img !== id))
    }

    const getField = (fieldToSearch: carFields) => state.fields.find((field: carInput) => field.field === fieldToSearch)

    const getBodyTypeId = (modificationId: number) =>
        Number(
            filteredModifications.find(
                (modificationObject: CatalogModification) => Number(modificationObject.id) === modificationId,
            )?.bodyTypeId,
        )
    const getBodyTypeRenderValue = (modificationId: number) => bodyTypes[String(getBodyTypeId(modificationId))] || ''

    const setIsDisabledFields = () =>
        state.fields.map((field: carInput) => {
            if (field.field === carFields.bodyTypeId) {
                const modificationId = getField(carFields.modificationId).value

                return {
                    ...field,
                    value: getBodyTypeId(modificationId),
                    specialProps: {
                        ...field.specialProps,
                        [carSpecialPropsFields.renderValue]: getBodyTypeRenderValue(modificationId),
                    },
                }
            }

            const selectSearchText = field.specialProps?.renderValue
            const errorMessage = field.value ? '' : field.specialProps?.errorMessage

            if (field.specialProps?.fieldParent) {
                const fieldParentValue = getField(field.specialProps.fieldParent).value
                const fieldParentValueInChild = field.specialProps?.[carSpecialPropsFields.fieldParentValue]
                const isEnabled = fieldParentValue
                const isParentChanged = fieldParentValueInChild && fieldParentValue !== fieldParentValueInChild

                return {
                    ...field,
                    value: isParentChanged ? '' : field.value,
                    specialProps: {
                        ...field.specialProps,
                        [carSpecialPropsFields.renderValue]: isParentChanged ? '' : selectSearchText,
                        [carSpecialPropsFields.isDisabled]: !isEnabled,
                        [carSpecialPropsFields.fieldParentValue]: isEnabled ? fieldParentValue : '',
                        [carSpecialPropsFields.errorMessage]: isEnabled ? errorMessage : '',
                    },
                }
            } else {
                return field
            }
        })

    const getVisibleColumn = (column: number) =>
        state.fields.filter((field: carInput) => field.specialProps?.column === column)

    const getIsSubmitDisabled = () => {
        let isDisabled = false
        state.fields.forEach((fied: carInput) => {
            if (fied?.specialProps?.errorMessage) {
                isDisabled = true
            }
        })
        setIsSubmitDisabled(isDisabled)
    }

    const goToNewPart = () => {
        navigate(`/admin/newPart/${car.id}`)
    }

    const goToCarSoldPartList = () => {
        navigate(getAdminNavigationPath(adminRouteAlias.soldParts.location) + `?carId=${car.id}`)
    }

    const goToCarDetails = () => {
        navigate(getAdminNavigationPath(adminRouteAlias.parts.location) + `?carId=${car.id}`)
    }

    const onCloseModal = () => {
        closeModal()
    }

    const fillField = (field: carInput, value: number | string | null, renderValue: string | null) => {
        if (value === null && renderValue === null) {
            return
        } else if (value === null) {
            dispatch({
                type: carCreateUpdateActionTypes.updateField,
                value: {
                    field: field.field,
                    specialProps: {
                        ...field.specialProps,
                        [carSpecialPropsFields.renderValue]: renderValue,
                    },
                },
            })
        } else if (renderValue === null) {
            dispatch({
                type: carCreateUpdateActionTypes.updateField,
                value: {
                    field: field.field,
                    value,
                },
            })
        } else {
            dispatch({
                type: carCreateUpdateActionTypes.updateField,
                value: {
                    field: field.field,
                    value,
                    specialProps: {
                        ...field.specialProps,
                        [carSpecialPropsFields.renderValue]: renderValue,
                    },
                },
            })
        }
    }

    const fillInitialState = () => {
        state.fields.map((field: carInput) => {
            switch (field.field) {
                case carFields.markId:
                    car.markId && fillField(field, car.markId, car.markTitle)
                    break
                case carFields.modelId:
                    car.modelId && fillField(field, car.modelId, car.modelTitle)
                    break
                case carFields.modificationId:
                    car.modificationId && fillField(field, car.modificationId, car.modificationTitle)
                    break
                case carFields.bodyTypeId:
                    car.bodyTypeId && fillField(field, car.bodyTypeId, car.bodyTypeTitle)
                    break
                case carFields.year:
                    car.year && fillField(field, car.year, String(car.year))
                    break
                case carFields.steeringType:
                    fillField(field, car.extraData?.steeringType === 'right' ? 1 : 0, null)
                    break
                case carFields.equipment:
                    car.extraData?.equipment && fillField(field, car.extraData.equipment, null)
                    break
                case carFields.vinCode:
                    car.vinCode && fillField(field, car.vinCode, null)
                    break
                case carFields.bodycars:
                    car.extraData?.bodycars && fillField(field, car.extraData.bodycars, null)
                    break
                case carFields.engine:
                    car.extraData?.engine && fillField(field, car.extraData.engine, null)
                    break
                case carFields.colorCode:
                    car.extraData?.colorCode && fillField(field, car.extraData.colorCode, null)
                    break
                case carFields.mileage:
                    car.mileage && fillField(field, car.mileage, mileage.find(m => m.value === car.mileage)?.name || '')
                    break
                case carFields.carPrice:
                    car.price && fillField(field, car.price, null)
                    break
                case carFields.regionId:
                    car.extraData?.regionId &&
                        fillField(
                            field,
                            car.extraData.regionId || 0,
                            regions.find(r => r.value === car.extraData?.regionId)?.name || '',
                        )
                    break
                case carFields.defects:
                    dispatch({
                        type: carCreateUpdateActionTypes.updateChecboxGroup,
                        value: {
                            field: field.field,
                            value: car.extraData?.defects,
                        },
                    })
                    break
                case carFields.conditionId:
                    car.extraData?.conditionId && fillField(field, car.extraData.conditionId, null)
                    break
                case carFields.sumAllParts:
                    car.sumAllParts && fillField(field, null, car.sumAllParts.toLocaleString('ru-Ru'))
                    break
                case carFields.roiMax:
                    car.sumAllParts &&
                        fillField(field, null, `${parseFloat(((car.sumAllParts / car.price) * 100).toFixed(2))}%`)
                    break
                case carFields.sumSoldParts:
                    car.sumSoldParts && fillField(field, null, car.sumSoldParts.toLocaleString('ru-Ru'))
                    break
                case carFields.profit:
                    car.sumSoldParts &&
                        car.price &&
                        fillField(field, null, (car.sumSoldParts - car.price).toLocaleString('ru-Ru'))
                    break
                case carFields.roi:
                    car.sumSoldParts &&
                        car.price &&
                        fillField(field, null, `${parseFloat(((car.sumSoldParts / car.price) * 100).toFixed(2))}%`)
                    break
                case carFields.soldPartsCount:
                    dispatch({
                        type: carCreateUpdateActionTypes.updateSpecialProps,
                        value: {
                            field: field.field,
                            specialProps: {
                                ...field.specialProps,
                                [carSpecialPropsFields.renderValue]: String(car.soldPartsCount),
                                [carSpecialPropsFields.href]:
                                    getAdminNavigationPath(adminRouteAlias.soldParts.location) + `?carId=${car.id}`,
                            },
                        },
                    })
                    break
                case carFields.partsOnSaleCount:
                    car.partsOnSaleCount && fillField(field, null, String(car.partsOnSaleCount))
                    break
                case carFields.partsOnSaleSum:
                    car.partsOnSaleSum && fillField(field, null, car.partsOnSaleSum.toLocaleString('ru-Ru'))
                    break
                case carFields.descTemplate:
                    car.descTemplate && fillField(field, car.descTemplate, null)
                    break
                case carFields.customTitle:
                    car.extraData?.customTitle && fillField(field, car.extraData.customTitle, car.extraData.customTitle)
                    break
            }
        })
    }

    const getApiObjectFromState = async (initialObject: ApiCarCreateUpdate, apiObjectType: 'car') => {
        let apiObject = initialObject
        let isObjectReady = true

        state.fields.map((item: carInput) => {
            if (item.value && [carFields.bodycars, carFields.engine].includes(item.field)) {
                let carApiObject = { ...apiObject } as ApiCarCreateUpdate
                carApiObject = {
                    ...carApiObject,
                    extraData: {
                        ...carApiObject.extraData,
                        [item.field]: item.value,
                    },
                }
                apiObject = { ...carApiObject }
            } else if (item.value && !item?.specialProps?.errorMessage && !item?.specialProps?.extraHandle) {
                if (item.field === carFields.carPrice) {
                    apiObject = { ...apiObject, price: Number(item.value) }
                } else {
                    apiObject = { ...apiObject, [item.field]: item.value }
                }
            } else if (item.value && item?.specialProps?.extraHandle === 'defects') {
                let carApiObject = { ...apiObject } as ApiCarCreateUpdate
                carApiObject = {
                    ...carApiObject,
                    extraData: {
                        ...carApiObject.extraData,
                        // @ts-ignore
                        defects: item.value.filter(({ value }) => Boolean(value)).map(({ field }) => field),
                    },
                }
                apiObject = { ...carApiObject }
            } else if (item.value && item?.specialProps?.extraHandle === 'steeringType') {
                let carApiObject = { ...apiObject } as ApiCarCreateUpdate
                carApiObject = {
                    ...carApiObject,
                    extraData: {
                        ...carApiObject.extraData,
                        steeringType: item.value === 0 ? 'left' : 'right',
                    },
                }
                apiObject = { ...carApiObject }
            } else if (item.value && item?.specialProps?.extraHandle === 'regionId') {
                let carApiObject = { ...apiObject } as ApiCarCreateUpdate
                carApiObject = {
                    ...carApiObject,
                    extraData: {
                        ...carApiObject.extraData,
                        regionId: Number(item.value),
                    },
                }
                apiObject = { ...carApiObject }
            } else if (item.value && item?.specialProps?.extraHandle === 'colorCode') {
                let carApiObject = { ...apiObject } as ApiCarCreateUpdate
                carApiObject = {
                    ...carApiObject,
                    extraData: {
                        ...carApiObject.extraData,
                        colorCode: String(item.value),
                    },
                }
                apiObject = { ...carApiObject }
            } else if (item.value && item?.specialProps?.extraHandle === 'equipment') {
                let carApiObject = { ...apiObject } as ApiCarCreateUpdate
                carApiObject = {
                    ...carApiObject,
                    extraData: {
                        ...carApiObject.extraData,
                        equipment: String(item.value),
                    },
                }
                apiObject = { ...carApiObject }
            } else if (item.value && item?.specialProps?.extraHandle === 'conditionId') {
                let carApiObject = { ...apiObject } as ApiCarCreateUpdate
                carApiObject = {
                    ...carApiObject,
                    extraData: {
                        ...carApiObject.extraData,
                        conditionId: Number(item.value),
                    },
                }
                apiObject = { ...carApiObject }
            } else if (item.value && item?.specialProps?.extraHandle === 'customTitle') {
                let carApiObject = { ...apiObject } as ApiCarCreateUpdate
                carApiObject = {
                    ...carApiObject,
                    extraData: {
                        ...carApiObject.extraData,
                        customTitle: String(item.value),
                    },
                }
                apiObject = { ...carApiObject }
            } else if (item?.specialProps?.isRequired && !item.value) {
                dispatch({
                    type: carCreateUpdateActionTypes.updateSpecialProps,
                    value: {
                        field: item.field,
                        specialProps: {
                            ...item.specialProps,
                            [carSpecialPropsFields.errorMessage]: 'поле обязательно к заполнению',
                        },
                    },
                })
                isObjectReady = false
            } else if (item?.specialProps?.errorMessage) {
                isObjectReady = false
            }
        })

        if (apiObjectType === 'car' && isObjectReady) {
            const carApiObject = { ...apiObject } as ApiCarCreateUpdate
            const carId = uuidV5(
                JSON.stringify({
                    markId: carApiObject.markId,
                    modelId: carApiObject.modelId,
                    modificationId: carApiObject.modificationId,
                }),
                uuidV4(),
            )
            apiObject = { ...carApiObject, id: carId }
        }

        if (isObjectReady) {
            apiObject.id = car.id
            return apiObject
        } else {
            if (apiObjectType === 'car') {
                setIsSubmitDisabled(true)
            }
        }
    }

    const confirmChange = async () => {
        const newCar = await getApiObjectFromState(initialApiCarObject, 'car')

        const deleteImages = imagesToDelete.map(image => dispatchRedux(deleteCarImage(car.id, image)))

        if (newCar) {
            setIsLoading(true)
            const images = getField(carFields.carImages).value
            const imageCount = images.length
            const carCreated = dispatchRedux(saveCarInCloud(newCar as ApiCarCreateUpdate, imageCount > 0))

            Promise.all([carCreated, [...deleteImages]]).then(() => {
                const promises: Promise<void>[] = []
                images.map((image: File, index: number) => {
                    promises.push(
                        dispatchRedux(saveImageInCloud(image, index, newCar.id, 'car', index === imageCount - 1)),
                    )
                })

                if (!isDeepEqualSimple(car.images, imagesToReorder)) {
                    promises.push(dispatchRedux(reorderCarImages(car.id, imagesToReorder)))
                }

                Promise.all(promises).then(() => {
                    // Чтобы обновился список
                    window.location.reload()
                    setIsLoading(false)
                })
            })
        }
    }

    const loadBodyTypes = async () => {
        try {
            const res = await axios.get('https://api.stock-pro.net/catalog/data/body_types.json')
            if (res.data) {
                setBodyTypes(res.data)
            }
        } catch (e) {
            console.error('loadBodyTypes error', e)
        }
    }

    useEffect(() => {
        loadBodyTypes().catch()
        dispatch({
            type: carCreateUpdateActionTypes.initializeState,
            value: { state: { fields: setIsDisabledFields() } },
        })
    }, [])

    useEffect(() => {
        if (!isLoading) {
            getIsSubmitDisabled()
            dispatch({
                type: carCreateUpdateActionTypes.initializeState,
                value: { state: { fields: setIsDisabledFields() } },
            })
        }
    }, [
        state.fields[0].value,
        state.fields[1].value,
        state.fields[2].value,
        state.fields[4].value,
        state.fields[5].value,
        state.fields[6].value,
        state.fields[7].value,
        state.fields[8].value,
        state.fields[9].value,
        state.fields[10].value,
        state.fields[11].value,
        state.fields[12].value,
        state.fields[13].value,
        isLoading,
    ])

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            await dispatchRedux(getAllCarMarks(car.markTitle))
            await dispatchRedux(getAllCarModels(car.modelTitle, String(car.markId)))
            await dispatchRedux(getAllCarModifications(car.modificationTitle, String(car.modelId)))
            await dispatchRedux(getModificationYears(String(car.year), String(car.modificationId)))
            await loadBodyTypes().catch()

            fillInitialState()

            setIsLoading(false)
        }

        fetchData()
    }, [])

    useEffect(() => {
        if (!canShowFinanceInfo) {
            dispatch({
                type: carCreateUpdateActionTypes.updateFieldTypeToVoid,
                value: financeFields,
            })
        }
    }, [canShowFinanceInfo])

    return (
        <>
            <Modal show={isOpen} onHide={onCloseModal} size="xl" fullscreen={true}>
                {isLoading && (
                    <OverlayWithText backgroundBootstrapColor={'bg-secondary'}>
                        <Loader diameterInPx={100} thicknessInPx={10} />
                    </OverlayWithText>
                )}
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className={styles.contentWrap}>
                        <a href="#" onClick={closeModal}>
                            Назад к списку авто
                        </a>
                        <SalesText
                            text={title + ' ' + car.markTitle + ' ' + car.modelTitle + ' ' + car.year}
                            fontSize={fontSizes.xxl}
                            fontWeight={fontWeights.bold}
                        />
                        {car.vinCode && <div className={styles.modalEditCar__modalSubTitle}>VIN {car.vinCode}</div>}
                        <SalesCard
                            widthInPixels={getVisibleColumn(2).length === 0 ? 570 : 1140}
                            minHeightInPixels={600}
                            marginTopPixels={24}
                            overflowY={'visible'}
                            footer={true}
                            footerJustifyContentBetween
                            footerContent={
                                <>
                                    <div className={styles.leftFooter}>
                                        <SalesButtonStandard
                                            text={'Создать запчасть'}
                                            backgroundColor={colors.white}
                                            onClick={goToNewPart}
                                        />
                                        {canShowFinanceInfo && (
                                            <SalesButtonStandard
                                                text={'Продано с этого авто'}
                                                backgroundColor={colors.white}
                                                onClick={goToCarSoldPartList}
                                            />
                                        )}
                                        <SalesButtonStandard
                                            text={'Запчасти с этого авто'}
                                            backgroundColor={colors.white}
                                            onClick={goToCarDetails}
                                        />
                                    </div>
                                    <Button variant="primary" onClick={confirmChange} disabled={isSubmitDisabled}>
                                        Сохранить изменения
                                    </Button>
                                </>
                            }
                        >
                            <div className={styles.cardContentWrap}>
                                <div className={styles.cardColumnWrap}>
                                    <PartCreateUpdateTable dispatch={dispatch} fields={getVisibleColumn(1)} />
                                </div>
                                {getVisibleColumn(2).length === 0 ? null : (
                                    <div className={styles.cardColumnWrap}>
                                        <PartCreateUpdateTable
                                            dispatch={dispatch}
                                            fields={getVisibleColumn(2)}
                                            bottom={
                                                <div className={styles.modalImgGroup}>
                                                    <ModalImageGroup
                                                        images={imagesToReorder}
                                                        setImages={setImagesToReorder}
                                                        markImg={markImg}
                                                        unmarkImg={unmarkImg}
                                                        showMainImg={false}
                                                    />
                                                </div>
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </SalesCard>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalEditCar
